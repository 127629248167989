import { Component, Input } from '@angular/core';
import { ModalService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { ClaimConfig, FieldType, Option } from '@unifii/sdk';

import { AuthProviderMappingActionType, SystemRole, UcAuthProviders, UcClaimConfig, UcUserClaims } from 'client';

import { ActionOptions, ActionsTypesDescription, MappingConfig, MappingsControlKeys } from '../models';

import { AuthProviderMappingsController } from './auth-provider-mapping.controller';

@Component({
    selector: 'uc-auth-provider-mapping-actions',
    templateUrl: './auth-provider-mapping-actions.html',
    standalone: false
})
export class AuthProviderMappingActionsComponent {

    @Input({ required: true }) actions: UfControlArray;
    @Input({ required: true }) authProviderId: string;
    @Input({ required: true }) config: MappingConfig;

    protected readonly controlKeys = MappingsControlKeys;
    protected readonly types = AuthProviderMappingActionType;
    protected readonly actionTypesDescription = ActionsTypesDescription;

    protected actionOptions = ActionOptions;
    protected filteredSourceClaims: UcClaimConfig[];
    protected filteredSystemRoles: string[];
    protected filteredRoles: string[];

    constructor(
        private modalService: ModalService,
        private mappingsController: AuthProviderMappingsController,
        private ucUserClaims: UcUserClaims,
        private ucAuthProviders: UcAuthProviders,
    ) { }

    protected addAction(option: Option) {
        const actions = this.actions.getRawValue();

        if (option.identifier === AuthProviderMappingActionType.AssignRole) {
            const hasRoleAction = actions.some((a) => a.type === AuthProviderMappingActionType.AssignRole);

            if (hasRoleAction) {
                this.modalService.openAlert({ message: `There's already an action of the Role type, please add roles to that action` });

                return;
            }
        }

        if (option.identifier === AuthProviderMappingActionType.AssignSystemRole) {
            const hasSystemRoleAction = actions.some((a) => a.type === AuthProviderMappingActionType.AssignSystemRole);

            if (hasSystemRoleAction) {
                this.modalService.openAlert({ message: `There's already an action of the System Role type, please add roles to that action` });

                return;
            }
        }

        if (option.identifier === AuthProviderMappingActionType.AssignUnit) {
            const hasUnitAction = actions.some((a) => a.type === AuthProviderMappingActionType.AssignUnit);

            if (hasUnitAction) {
                this.modalService.openAlert({ message: `There's already an action of the Unit Hierarchy type, please add units to that action` });

                return;
            }
        }

        this.actions.push(this.mappingsController.buildAction({ type: option.identifier as AuthProviderMappingActionType, identifier: '' }));
    }

    protected changeClaimValue(action: UfControlGroup, type: string | ClaimConfig) {
        // reset value when claim is changed
        if (typeof type === 'string') {
            action.get(MappingsControlKeys.Claim)?.setValue(this.createTextClaimConfig(type, 'Value'), { emitEvent: false });
        }

        action.get(MappingsControlKeys.Value)?.setValue(undefined);
    }

    protected async deleteAction(position: number) {
        const confirm = await this.modalService.openConfirm({
            cancelLabel: `Don't Delete`,
            confirmLabel: 'Delete',
            message: 'Are you sure you want to delete this action?',
        });

        if (confirm) {
            this.actions.removeAt(position);
        }
    }

    protected async findSourceClaims(query: string) {
        let sourceClaims: string[] = [];

        if (this.config.sourceClaims) {
            sourceClaims = await this.ucAuthProviders.getAuthProviderClaims(this.authProviderId, { query });
        }

        this.filteredSourceClaims = sourceClaims.map((claim) => this.createTextClaimConfig(claim));
    }

    protected async findClaims(query: string) {
        this.filteredSourceClaims = (await this.ucUserClaims.list({ params: { q: query } })).map((claim) => ({ ...claim, label: 'Value' }));
    }

    protected findSystemRoles(query: string | null) {
        this.filteredSystemRoles = Object.keys(SystemRole).filter(
            (key) => key !== SystemRole.SuperUser && (!query || key.toLowerCase().includes(query.trim().toLowerCase())),
        );
    }

    protected claimFromValueChange(position: number, type?: UcClaimConfig | string) {
        const action = (this.actions.at(position) as UfControlGroup).get(MappingsControlKeys.Claim) as UfControl;

        if (typeof type === 'string') {
            action.setValue(this.createTextClaimConfig(type), { emitEvent: false });
        }
    }

    protected claimToValueChange(position: number, type?: UcClaimConfig | string) {
        const action = (this.actions.at(position) as UfControlGroup).get(MappingsControlKeys.ClaimTo) as UfControl;

        if (typeof type === 'string') {
            action.setValue(this.createTextClaimConfig(type), { emitEvent: false });
        }
    }

    protected mapActionTypeDescription(type: AuthProviderMappingActionType) {
        return ActionsTypesDescription[type];
    }

    private createTextClaimConfig(type: string, label = ''): ClaimConfig {
        return {
            type,
            label,
            id: '',
            valueType: FieldType.Text,
        };
    }

}
