import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataDisplayListItem, UfControlGroup } from '@unifii/library/common';
import { AuthProvider } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { AuthProviderDetails } from 'client';

import { DetailsControlKeys } from '../models';

interface VisibleFields {
    clientId?: boolean;
    providerLoginLabel?: boolean;
    authorizationServerId?: boolean;
    audience?: boolean;
    sswsSecret?: boolean;
    clientSecret?: boolean;
    scimToken?: boolean;
    supportProvider?: boolean;
}

@Component({
    selector: 'uc-auth-provider-detail',
    templateUrl: './auth-provider-detail.html',
    standalone: false
})
export class AuthProviderDetailComponent implements OnInit, OnDestroy {

    @Input() form: UfControlGroup;

    @Output() deactivateClicked = new EventEmitter<void>();
    @Output() activateClicked = new EventEmitter<void>();
    @Output() deleteClicked = new EventEmitter<void>();

    protected readonly controlKeys = DetailsControlKeys;

    protected showAuthorizationServerId: boolean;
    protected showManagementApiAudience: boolean;
    protected showSswsSecret: boolean;
    protected showClientSecret: boolean;
    protected showScimToken: boolean;
    protected showProviderLoginLabel: boolean;
    protected extrasControl: UfControlGroup;
    protected isNew: boolean;
    protected edit: boolean;
    protected dataDisplayListItems: DataDisplayListItem[] | null;
    protected visible: VisibleFields;

    private type: AuthProvider;
    private previousValue: AuthProviderDetails;
    private subscriptions = new Subscription();

    ngOnInit() {
        const isActiveControl = this.form.get(DetailsControlKeys.IsActive);

        this.subscriptions.add(isActiveControl?.valueChanges.subscribe(() => this.buildDescriptionList()));

        this.setup();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected doneEditing() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.buildDescriptionList();
        this.edit = false;
    }

    protected editDetails() {
        this.previousValue = this.form.getRawValue();
        this.edit = !this.edit;
    }

    protected cancelEdit() {
        if (!this.form.pristine) {
            this.form.reset(this.previousValue, { emitEvent: false });
        }
        this.edit = false;
    }

    private setup() {
        this.type = this.form.get(DetailsControlKeys.Type)?.value;
        this.isNew = !this.form.get(DetailsControlKeys.Id)?.value;

        this.visible = this.buildVisibleFields(this.type);

        this.extrasControl = this.form.get(DetailsControlKeys.Extras) as UfControlGroup;

        this.buildDescriptionList();
    }

    private buildDescriptionList() {
        const isActive = this.form.get(DetailsControlKeys.IsActive)?.value;

        this.dataDisplayListItems = [
            { term: 'Tenant Name', data: this.form.get(DetailsControlKeys.Tenant)?.value },
        ];

        if (this.visible.clientId) {
            this.dataDisplayListItems.push({ term: 'Client ID', data: this.form.get(DetailsControlKeys.ClientId)?.value });
        }

        this.dataDisplayListItems.push(
            { term: 'Registration Type', data: `${this.getRegistrationType()} - ${this.type}` },
            { term: 'Status', data: { description: isActive ? 'Active' : 'Inactive', status: isActive ? 'success' : 'error' } },
        );

        if (this.visible.scimToken && this.form.get(DetailsControlKeys.ScimToken)?.value) {
            this.dataDisplayListItems.push({ term: 'Scim Token', data: 'Enabled' });
        }

        if (this.visible.supportProvider) {
            this.dataDisplayListItems.push({ term: 'Support Provider', data: this.form.get(DetailsControlKeys.SupportProvider)?.value ? 'Yes' : 'No' });
        }

        const extrasControl = this.form.get(DetailsControlKeys.Extras) as UfControlGroup;

        if (this.visible.audience) {
            this.dataDisplayListItems.push({ term: 'Management API Audience', data: extrasControl.get(DetailsControlKeys.Audience)?.value });

            return;
        }

        if (this.visible.providerLoginLabel) {
            this.dataDisplayListItems.push({ term: 'Provider Login Label', data: this.form.get(DetailsControlKeys.ProviderLoginLabel)?.value });
        }

        if (this.visible.authorizationServerId) {
            this.dataDisplayListItems.push({ term: 'Authorization Server ID', data: extrasControl.get(DetailsControlKeys.AuthorizationServer)?.value });
        }

    }

    private getRegistrationType(): 'Manual' | 'Automatic' {
        return this.isManual() ? 'Manual' : 'Automatic';
    }

    private isManual() {
        return !!this.form.get(DetailsControlKeys.Manual)?.value;
    }

    private buildVisibleFields(type: AuthProvider): VisibleFields {
        switch (type) {
            case AuthProvider.Auth0: {
                return {
                    clientId: true,
                    clientSecret: true,
                    audience: true,
                    supportProvider: true,
                };
            }
            case AuthProvider.Azure:
                return {
                    clientId: true,
                    providerLoginLabel: true,
                    clientSecret: this.isManual(),
                    scimToken: true,
                    supportProvider: true,
                };
            case AuthProvider.Okta:
                return {
                    clientId: true,
                    providerLoginLabel: true,
                    authorizationServerId: true,
                    sswsSecret: true,
                    clientSecret: true,
                    scimToken: true,
                    supportProvider: true,
                };
            case AuthProvider.UnifiiIdentity:
                return {
                    clientId: true,
                    providerLoginLabel: true,
                    clientSecret: true,
                    supportProvider: true,
                };
        }

        return {};
    }

}
