import { Component, EventEmitter, HostBinding, Input, Output, inject } from '@angular/core';
import { UfControlArray, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { CompoundType, StructureNodeType } from '@unifii/sdk';

import { UcStructureNode } from 'client';
import { FieldReferenceHelper } from 'helpers/field-reference-helper';
import { DialogsService } from 'services/dialogs.service';

import { StructureNodeControlKeys } from './structure-control-keys';
import { StructureEditorService } from './structure-editor.service';
import { isNodeAccessRestricted, isNodeContentPublished, isStructureNodeRoot, structureNodeHasVariations } from './structure-functions';
import { StructureEditorNodeOrRoot } from './structure-model';
import { StructureStatus } from './structure-status';

@Component({
    selector: 'uc-node-field',
    templateUrl: './node-field.html',
    styleUrls: ['./node-field.less'],
    standalone: false
})
export class NodeFieldComponent {

    @Input({ required: true }) control: UfControlGroup;
    @Input() isParentHidden = false;
    @Output() expandAll = new EventEmitter<boolean>();

    protected service = inject(StructureEditorService);
    protected status = inject(StructureStatus);

    private ufFormBuilder = inject(UfFormBuilder);
    private dialogs = inject(DialogsService);

    protected get node(): StructureEditorNodeOrRoot {
        return this.control.value as StructureEditorNodeOrRoot;
    }

    protected get children(): UfControlArray {
        return this.control.get(StructureNodeControlKeys.Children) as UfControlArray;
    }

    @HostBinding('style.display')
    protected get display(): 'none' | 'block' {
        return !this.status.showHiddenNodes && this.node.isHidden ? 'none' : 'block';
    }

    protected get isSelected(): boolean {
        return this.control === this.status.selected;
    }

    protected get isHome(): boolean {
        return isStructureNodeRoot(this.node);
    }

    protected get isHomeEmpty(): boolean {
        return this.isHome && this.node.type === StructureNodeType.Empty;
    }

    protected get hasVariations(): boolean {
        return structureNodeHasVariations(this.node);
    }

    protected get isAccessRestricted(): boolean {
        return isNodeAccessRestricted(this.node);
    }

    protected get isPublished(): boolean {
        return isNodeContentPublished(this.node);
    }

    @HostBinding('class.showAsHidden')
    protected get showAsHidden(): boolean {
        return this.isParentHidden || !!this.node.isHidden;
    }

    protected get notPublishedMessage(): string {
        return this.node.lastPublishedAt == null ?
            `This content is unpublished` :
            `The current version of this content is unpublished`;
    }

    protected get icon(): string {
        if (this.isHomeEmpty) {
            return 'info';
        }

        return FieldReferenceHelper.getFieldReference(this.node, CompoundType.Structure).icon;
    }

    protected select(event: Event) {
        event.stopPropagation();

        return this.service.selectNode(this.control);
    }

    protected expand(event: Event) {
        event.stopPropagation();
        this.expandAll.next(true);
    }

    protected collapse(event: Event) {
        event.stopPropagation();
        this.expandAll.next(false);
    }

    protected async remove(event: Event) {
        event.stopPropagation();

        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        if (!this.isHome) {
            if (this.status.selected === this.control) {
                this.status.selected = this.status.root;
            }
            this.ufFormBuilder.detach(this.control);

            return;
        }

        const emptyNode: UcStructureNode = {
            type: StructureNodeType.Empty,
            id: null as any,
            nodeId: '0' as any,
            name: null as any,
            definitionIdentifier: null as any,
            definitionLabel: null as any,
            publishState: null as any,
            url: null as any,
            roles: null as any,
            tags: this.isCustomOrView() ? [] : null as any,
        };

        (this.control.get(StructureNodeControlKeys.BucketOptions) as UfControlArray).clear();
        (this.control.get(StructureNodeControlKeys.Args) as UfControlArray).clear();

        this.control.patchValue(emptyNode, { emitEvent: true });
        this.status.selected = null;
    }

    private isCustomOrView(): boolean {
        return [StructureNodeType.Custom, StructureNodeType.View].includes(this.node.type);
    }

}
