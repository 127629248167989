import { Component, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

interface HierarchyFinderColumn {
    label: string;
}

enum ColumnKey {
    Label = 'label',
}

@Component({
    templateUrl: './hierarchy-finder-column-input.html',
    standalone: false
})
export class HierarchyFinderColumnInputComponent implements Modal<HierarchyFinderColumn, string | undefined> {

    protected readonly columnKey = ColumnKey;

    protected form: UfControlGroup;

    constructor(
        public runtime: ModalRuntime<HierarchyFinderColumn, string | undefined>,
        private ufb: UfFormBuilder,
        @Inject(ModalData) public data: HierarchyFinderColumn,
    ) {
        this.form = this.ufb.group({
            [ColumnKey.Label]: this.ufb.control(data.label, ValidatorFunctions.required('Label is required')),
        });
    }

    protected add() {
        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        const { label } = this.form.value;

        this.runtime.close(label);
    }

}
