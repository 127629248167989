import { Directive, HostBinding, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
    selector: '[ufMasterDetail]',
    standalone: false
})
export class MasterDetailDirective implements OnDestroy {

    isDetails: boolean;

    private subscriptions = new Subscription();

    @HostBinding('style')
    get hideMaster()/* : SafeStyle CLI warning not exported*/ {

        const style = this.isDetails ? `
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;` : ``;

        return this.sanitizer.bypassSecurityTrustStyle(style);
    }

    constructor(
        private sanitizer: DomSanitizer,
        private router: Router,
        private route: ActivatedRoute,
    ) {

        this.subscriptions.add(
            this.router.events.pipe(
                filter((e) => e instanceof NavigationEnd))
                .subscribe(() => (this.isDetails = !!this.route.children.length)),
        );

        this.isDetails = !!this.route.children.length;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
