import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { CommonTranslationKey, CompanyIdentifiers, DataDisplayListItem, DataDisplayService, UfControl, UfControlGroup } from '@unifii/library/common';
import { FieldWidth, PermissionAction, UfError } from '@unifii/sdk';
import { LockedConfig, UserControlService, UserFieldLabelService, UserFormContext, UserKeys, UserMfaInfoKeys, getClaimDescriptionIdentifiers, getUserDescriptionIdentifiers } from '@unifii/user-provisioning';
import { Subscription } from 'rxjs';

import { UcUserInfo } from 'client';

import { IsSuperUserControl } from '../user-utils';
@Component({
    selector: 'uc-user-details',
    templateUrl: 'user-details.html',
    standalone: false
})

export class UserDetailsComponent implements OnInit, OnDestroy {

    @Input({ required: true }) form: UfControlGroup;
    @Input({ required: true }) lockedConfig: LockedConfig | undefined;
    @Input() isMe: boolean | null;
    @Input() error: UfError | null | undefined;

    protected readonly commonTK = CommonTranslationKey;
    protected readonly controlKeys = UserKeys;
    protected readonly fieldWidth = FieldWidth;

    protected companyLabel: string;
    protected user: UcUserInfo;
    protected isNew: boolean;
    protected userDescriptionFields: string[];
    protected claimDescriptionFields: string[] = [];
    protected usernameControl?: UfControl;
    protected usernameRequired: boolean;
    protected firstNameControl?: UfControl;
    protected firstNameRequired: boolean;
    protected lastNameControl?: UfControl;
    protected lastNameRequired: boolean;
    protected emailControl?: UfControl;
    protected emailRequired: boolean;
    protected phoneControl?: UfControl;
    protected phoneRequired: boolean;
    protected passwordControl?: UfControl;
    protected isActiveControl?: UfControl;
    protected companyControl?: UfControl;
    protected companyRequired: boolean;
    protected isTesterControl?: UfControl;
    protected isTesterRequired: boolean;
    protected claimsControl?: UfControlGroup;
    protected lastActivationReasonControl?: UfControl;
    protected oldPasswordControl?: UfControl;
    protected changePasswordOnNextLoginControl?: UfControl;
    protected lastLoginAtItems: DataDisplayListItem[] = [];
    protected displayPhoneWarning = false;

    private superUserControl: UfControl;
    private dataDisplayService = inject(DataDisplayService);
    private userFieldLabelService = inject(UserFieldLabelService);
    private userControlService = inject(UserControlService);
    private userFormContext = inject(UserFormContext);
    private subscriptions = new Subscription();

    ngOnInit() {
        this.companyLabel = this.userFieldLabelService.labelDictionary[UserKeys.Company];
        this.isNew = this.userFormContext.action === PermissionAction.Add;
        this.user = this.form.getRawValue() as UcUserInfo;
        this.setControlsReferences();
        this.setupDescriptionFields();
        this.initPhoneWarning();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private setControlsReferences() {
        this.usernameControl = this.form.get(UserKeys.Username) as UfControl | undefined;
        this.usernameRequired = this.userControlService.isFieldRequired(UserKeys.Username);
        this.firstNameControl = this.form.get(UserKeys.FirstName) as UfControl | undefined;
        this.firstNameRequired = this.userControlService.isFieldRequired(UserKeys.FirstName);
        this.lastNameControl = this.form.get(UserKeys.LastName) as UfControl | undefined;
        this.lastNameRequired = this.userControlService.isFieldRequired(UserKeys.LastName);
        this.emailControl = this.form.get(UserKeys.Email) as UfControl | undefined;
        this.emailRequired = this.userControlService.isFieldRequired(UserKeys.Email);
        this.phoneControl = this.form.get(UserKeys.Phone) as UfControl | undefined;
        this.phoneRequired = this.userControlService.isFieldRequired(UserKeys.Phone);
        this.companyControl = this.form.get(UserKeys.Company) as UfControl | undefined;
        this.companyRequired = this.userControlService.isFieldRequired(UserKeys.Company);
        this.isTesterControl = this.form.get(UserKeys.IsTester) as UfControl | undefined;
        this.isTesterRequired = this.userControlService.isFieldRequired(UserKeys.IsTester);
        this.claimsControl = this.form.get(UserKeys.Claims) as UfControlGroup | undefined;
        this.superUserControl = this.form.get(IsSuperUserControl) as UfControl;
        this.passwordControl = this.form.get(UserKeys.Password) as UfControl | undefined;
        this.oldPasswordControl = this.form.get(UserKeys.OldPassword) as UfControl | undefined;
        this.changePasswordOnNextLoginControl = this.form.get(UserKeys.ChangePasswordOnNextLogin) as UfControl | undefined;
        this.lastActivationReasonControl = this.form.get(UserKeys.LastActivationReason) as UfControl | undefined;
        this.isActiveControl = this.form.get(UserKeys.IsActive) as UfControl;
    }

    private setupDescriptionFields() {

        if (this.isNew) {
            return;
        }

        const skipIdentifiers = this.superUserControl.enabled ? [UserKeys.SystemRoles] as string[] : undefined;

        this.userDescriptionFields = getUserDescriptionIdentifiers(this.form, skipIdentifiers);
        // When update we want to display the status as key/value pair even when is active
        this.userDescriptionFields.unshift(CompanyIdentifiers.Status);

        if (this.claimsControl) {
            this.claimDescriptionFields = getClaimDescriptionIdentifiers(this.claimsControl, this.lockedConfig?.claimTypes);
        }

        if (this.user.lastLoginAt) {
            this.lastLoginAtItems.push(
                { term: 'Last Login At', data: this.dataDisplayService.displayAsString(this.user.lastLoginAt) },
            );
        }
    }

    private initPhoneWarning() {

        const mfaControl = this.form.get(UserKeys.Mfa) as UfControlGroup | undefined;

        if (mfaControl && this.phoneControl) {
            this.subscriptions.add(this.phoneControl.valueChanges.subscribe(() => {
                if (mfaControl.get(UserMfaInfoKeys.IsSmsEnabled)?.value === true) {
                    this.displayPhoneWarning = true;
                }
            }));
        }
    }

}
