import { Component, HostBinding, Inject, inject } from '@angular/core';
import { FieldTypeIcon, Modal, ModalData, ModalRuntime, UfControlGroup } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';

import { MappableField } from 'models';
import { FieldMappingService } from 'pages/workflows/field-mapping.service';

export interface FieldMappingData {
    sourceFields?: MappableField[];
    targetFields?: MappableField[];
    mappingGroup: FieldMappingGroup;
    sourceFieldLabel: string;
    targetFieldLabel: string;
    sourceExpressionLabel?: string;
    targetExpressionLabel?: string;
}

export interface FieldMappingGroup {
    control: UfControlGroup;
    sourceKey: string;
    targetKey: string;
    sourceExpressionKey?: string;
    targetExpressionKey?: string;
}

/**
 * @description
 * FieldMapperComponent is a generic modal class that is responsible
 * for mapping a source field to a target field
 */
@Component({
    templateUrl: './field-mapper.html',
    selector: 'uc-field-mapper-modal',
    standalone: false
})
export class FieldMapperComponent implements Modal<FieldMappingData, UfControlGroup> {

    @HostBinding('class.uf-form-card') cardClass = true;

    protected formGroup: UfControlGroup;
    protected sourceField?: MappableField;
    protected targetField?: MappableField;
    protected sourceFields?: MappableField[] = [];
    protected targetFields?: MappableField[] = [];
    protected sourceKey: string;
    protected targetKey: string;
    protected sourceExpressionKey?: string;
    protected targetExpressionKey?: string;

    protected sourceFieldLabel: string;
    protected targetFieldLabel: string;
    protected sourceExpressionLabel?: string;
    protected targetExpressionLabel?: string;
    protected useSourceExpression: boolean;
    protected useTargetExpression: boolean;
 
    protected readonly sourceDataExpressionKey = 'sourceDataExpression';
    private fieldMappingService = inject(FieldMappingService);

    private _sourceFields?: MappableField[] = [];
    private _targetFields?: MappableField[] = [];

    constructor(
        public runtime: ModalRuntime<FieldMappingData, UfControlGroup>,
        @Inject(ModalData) public data: FieldMappingData,
    ) {
        const { sourceFields, targetFields,
            mappingGroup, sourceFieldLabel,
            targetFieldLabel, sourceExpressionLabel,
            targetExpressionLabel } = data;

        const { control, sourceKey,
            targetKey, sourceExpressionKey,
            targetExpressionKey } = mappingGroup;

        this._sourceFields = sourceFields;
        this._targetFields = targetFields;
        this.sourceKey = sourceKey;
        this.targetKey = targetKey;
        this.sourceExpressionKey = sourceExpressionKey;
        this.targetExpressionKey = targetExpressionKey;
        this.sourceFieldLabel = sourceFieldLabel;
        this.targetFieldLabel = targetFieldLabel;
        this.sourceExpressionLabel = sourceExpressionLabel;
        this.targetExpressionLabel = targetExpressionLabel;

        this.formGroup = control;

        if (this.sourceExpressionKey) {
            this.useSourceExpression = !!this.formGroup.get(this.sourceExpressionKey)?.value;
        }

        if (this.targetExpressionKey) {
            this.useTargetExpression = !!this.formGroup.get(this.targetExpressionKey)?.value;
        }

        if (!sourceFields) {
            this.sourceField = this.formGroup.get(this.sourceKey)?.value;
        }

        if (!targetFields) {
            this.targetField = this.formGroup.get(this.targetKey)?.value;
        }
    }

    close() {
        this.runtime.close();
    }

    protected submit() {
        this.formGroup.setSubmitted();
        if (this.formGroup.invalid) {
            return;
        }

        this.runtime.close(this.formGroup);
    }

    protected sourceSearch(query: string) {
        const target = this.formGroup.get(this.targetKey)?.value as MappableField | null;

        this.sourceFields = this._sourceFields?.filter((sourceField) => this.queryFilter(sourceField, query) && (target == null || this.fieldMappingService.areTransitionMappingFieldsCompatible(sourceField, target)));
    }

    protected targetSearch(query: string) {
        const source = this.formGroup.get(this.sourceKey)?.value as MappableField | null;

        if (!source) {
            this.targetFields = [];

            return;
        }

        this.targetFields = this._targetFields?.filter((targetField) => this.queryFilter(targetField, query) && this.fieldMappingService.areTransitionMappingFieldsCompatible(source, targetField));
    }

    protected targetChange(useExpression: boolean) {

        if (!this.targetExpressionKey) {
            return;
        }

        if (useExpression) {
            this.formGroup.get(this.targetKey)?.reset();

            return;
        }
        this.formGroup.get(this.targetExpressionKey)?.reset();
    }

    protected sourceChange(useExpression: boolean) {

        if (!this.sourceExpressionKey) {
            return;
        }

        if (useExpression) {
            this.formGroup.get(this.sourceKey)?.reset();

            return;
        }
        this.formGroup.get(this.sourceExpressionKey)?.reset();
    }

    // TODO - Replace with Library Pipe
    protected namePropertyFunc(field: MappableField): string {
        return `${field.label} (${field.identifier})`;
    }

    // TODO - Replace with Library Pipe
    protected getFieldTypeIcon(fieldType?: FieldType): string | undefined {
        if (!fieldType) {
            return;
        }

        return FieldTypeIcon.get(fieldType);
    }

    private queryFilter(field: MappableField, query = ''): boolean {
        let { identifier, label } = field;

        identifier = identifier.toLowerCase();
        label = label.toLowerCase();

        query = query.trim().toLowerCase();

        return identifier.includes(query) || label.includes(query);
    }

}
