import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { UfControlArray, UfControlGroup } from '@unifii/library/common';
import { Option, StructureNodeType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcStructure } from 'client';

import { StructureControlKeys, StructureNodeControlKeys, StructureNodeVariationControlKeys } from '../structure-control-keys';
import { isNodeLinkedToContent, isStructureNodeRoot, structureNodeHasVariations } from '../structure-functions';
import { StructureEditorNodeOrRoot } from '../structure-model';

@Component({
    selector: 'uc-node-details',
    templateUrl: './node-details.html',
    standalone: false
})
export class NodeDetailsComponent implements OnInit, OnDestroy {

    @Input({ required: true } ) control: UfControlGroup;

    protected readonly nodeTypes = StructureNodeType;
    protected readonly variationControlKeys = StructureNodeVariationControlKeys;
    protected variationOptions: Option[];
    protected selectedVariation: Option;
    protected variation: UfControlGroup | null;

    private subscriptions = new Subscription();
    private cdr = inject(ChangeDetectorRef);

    ngOnInit() {
        if (!this.isHome) {
            return;
        }

        // Home node
        this.subscriptions.add(this.variations.valueChanges.subscribe(() => this.updateVariationOptions()));
        this.updateVariationOptions();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    protected selectVariation(variationOption?: Option) {

        this.variation = null;
        this.cdr.detectChanges();

        if (!variationOption) {
            return;
        }

        this.variation = this.variations.at(parseInt(variationOption.identifier)) as UfControlGroup;
        this.cdr.detectChanges();
    }

    protected get node(): StructureEditorNodeOrRoot {
        return this.control.value as StructureEditorNodeOrRoot;
    }

    protected get bucketOptions(): UfControlArray {
        return this.control.get(StructureNodeControlKeys.BucketOptions) as UfControlArray;
    }

    protected get args(): UfControlArray {
        return this.control.get(StructureNodeControlKeys.Args) as UfControlArray;
    }

    protected get variations(): UfControlArray {
        return this.control.get(StructureControlKeys.Variations) as UfControlArray;
    }

    protected get isHome(): boolean {
        return isStructureNodeRoot(this.node);
    }

    protected get hasVariations(): boolean {
        return structureNodeHasVariations(this.node);
    }

    protected get hasContentLinked(): boolean {
        return isNodeLinkedToContent(this.node);
    }

    protected get hasVariationContentLinked(): boolean {
        return isNodeLinkedToContent(this.variation?.value);
    }

    private updateVariationOptions() {
        this.variationOptions = ((this.control.getRawValue() as UcStructure).variations ?? []).map((v, i) => ({
            identifier: `${i}`,
            name: v.name ?? '',
        }));
    }

}
