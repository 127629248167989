import { Component, HostBinding, Inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { AppInfo } from '@unifii/sdk/dist/client-interfaces';

@Component({
    selector: 'uc-required-update-modal',
    templateUrl: './required-update-modal.html',
    standalone: false
})
export class RequiredUpdateModalComponent implements Modal<AppInfo, null> {

    @HostBinding('class.uf-form-card') cardClass = true;

    constructor(
        public runtime: ModalRuntime<AppInfo, null>,
        @Inject(ModalData) public data: AppInfo,
    ) {

    }

}
