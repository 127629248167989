import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UfControlGroup } from '@unifii/library/common';
import { Subscription, debounceTime } from 'rxjs';

import { ConsoleDataSource, IntegrationFeature } from 'client';
import { ConsoleNameLabel } from 'constant';

import { DataSourceControlKeys } from '../../model';

@Component({
    selector: 'uc-data-feature-configuration',
    templateUrl: './data-feature-configuration.html',
    standalone: false
})
export class DataFeatureConfigurationComponent implements OnInit, OnDestroy {

    @Input({ required: true }) control: UfControlGroup;
    @Input({ required: true }) dataSource: ConsoleDataSource;
    @Input({ required: true }) feature: IntegrationFeature;
    @Input() hideDataSource: boolean;

    @Output() dataSourceChange = new EventEmitter<ConsoleDataSource>();

    protected readonly consoleNameLabel = ConsoleNameLabel;
    protected readonly controlKeys = DataSourceControlKeys;

    private subscriptions = new Subscription();

    ngOnInit() {
        this.subscriptions.add(
            this.control.get(DataSourceControlKeys.ConsoleName)?.valueChanges
                .pipe(debounceTime(300))
                .subscribe((consoleName: string) => {
                    this.dataSource.consoleName = consoleName;
                    this.dataSourceChange.emit(this.dataSource);
                },
            ),
        );

        this.subscriptions.add(
            this.control.get(DataSourceControlKeys.Description)?.valueChanges
                .pipe(debounceTime(300))
                .subscribe((description: string) => {
                    this.dataSource.description = description;
                    this.dataSourceChange.emit(this.dataSource);
                },
            ),
        );
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
