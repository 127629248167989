"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Query = exports.Q = void 0;
const client_models_1 = require("./client-models");
const constants_1 = require("./constants");
const functions_1 = require("./functions");
const models_1 = require("./models");
/// Q represents a node in Query AST
/// and contains convenience functions for constructing queries
class Q {
  constructor(type, op = null, ...args) {
    this.type = type;
    this.op = op;
    this.args = args;
  }
  static fromAst(ast) {
    if (!ast?.op) {
      return;
    }
    // UNIFII-6603 Normalize errata AstNode with type Operator and op 'or' | 'and' | 'not' to type Combinator
    if (ast.type === models_1.NodeType.Operator && constants_1.QueryCombinatorOperators.includes(ast.op)) {
      ast.type = models_1.NodeType.Combinator;
    }
    // UNIFII-6611 Normalize errata AstNode with comparison operators to type Operator
    if (ast.type === models_1.NodeType.Value && constants_1.QueryOperatorOperators.includes(ast.op)) {
      ast.type = models_1.NodeType.Operator;
    }
    // Resolve combinator node
    if (ast.args?.length && ast.type === models_1.NodeType.Combinator) {
      if (ast.op === models_1.QueryOperators.Not && ast.args.length !== 1) {
        return;
      }
      const qs = ast.args.map(a => this.fromAst(a)).filter(functions_1.isNotNull);
      return qs.length ? new Q(models_1.NodeType.Combinator, ast.op, ...qs) : undefined;
    } else if (ast.args?.length && ast.type === models_1.NodeType.Operator) {
      // Resolve operator node
      const identifierNodes = ast.args.filter(node => node.type === models_1.NodeType.Identifier && node.value);
      const valueNodes = ast.args.filter(node => node.type === models_1.NodeType.Value && node.value != null /* 0 and false are valid values */);
      const isIdentifierOnly = constants_1.QueryIdentifierArgsOnlyOperators.includes(ast.op);
      // operator with 0...n identifiers as args
      if (ast.op === models_1.QueryOperators.Include) {
        return new Q(models_1.NodeType.Operator, ast.op, ...identifierNodes.map(a => Q.identifier(a.value)));
      }
      const identifierNodeValue = identifierNodes[0]?.value;
      const valueNodeValue = valueNodes[0]?.value;
      if (identifierNodeValue == null) {
        return undefined;
      }
      // operator with only identifier args
      if (isIdentifierOnly && valueNodeValue == null) {
        return new Q(models_1.NodeType.Operator, ast.op, Q.identifier(identifierNodeValue));
      }
      // operator with identifier & value args
      if (!isIdentifierOnly && valueNodeValue != null) {
        return new Q(models_1.NodeType.Operator, ast.op, Q.identifier(identifierNodeValue), Q.value(Q.safe(valueNodeValue, ast.op)));
      }
    }
    return undefined;
  }
  static eq(identifier, value) {
    return Q.cmp(models_1.QueryOperators.Equal, identifier, value);
  }
  static ne(identifier, value) {
    return Q.cmp(models_1.QueryOperators.NotEqual, identifier, value);
  }
  static lt(identifier, value) {
    return Q.cmp(models_1.QueryOperators.LowerThan, identifier, value);
  }
  static le(identifier, value) {
    return Q.cmp(models_1.QueryOperators.LowerEqual, identifier, value);
  }
  static gt(identifier, value) {
    return Q.cmp(models_1.QueryOperators.GreaterThan, identifier, value);
  }
  static ge(identifier, value) {
    return Q.cmp(models_1.QueryOperators.GreaterEqual, identifier, value);
  }
  static in(identifier, value) {
    return Q.cmp(models_1.QueryOperators.In, identifier, value);
  }
  static contains(identifier, value) {
    return Q.cmp(models_1.QueryOperators.Contains, identifier, value);
  }
  static descs(identifier, value) {
    return Q.cmp(models_1.QueryOperators.Descendants, identifier, value);
  }
  static not(q) {
    return new Q(models_1.NodeType.Combinator, models_1.QueryOperators.Not, q);
  }
  static and(...qs) {
    if (qs.length === 1 && qs[0]) {
      return qs[0];
    }
    return new Q(models_1.NodeType.Combinator, models_1.QueryOperators.And, ...qs);
  }
  static or(...qs) {
    if (qs.length === 1 && qs[0]) {
      return qs[0];
    }
    return new Q(models_1.NodeType.Combinator, models_1.QueryOperators.Or, ...qs);
  }
  static value(value) {
    const node = new Q(models_1.NodeType.Value);
    node.value = value;
    return node;
  }
  static identifier(identifier) {
    const node = new Q(models_1.NodeType.Identifier);
    node.value = identifier;
    return node;
  }
  static safe(value, op) {
    if (op === models_1.QueryOperators.In && !Array.isArray(value)) {
      return [value];
    }
    return value;
  }
  static cmp(op, identifier, value) {
    return new Q(models_1.NodeType.Operator, op, Q.identifier(identifier), Q.value(Q.safe(value, op)));
  }
  stringify() {
    if (this.type === models_1.NodeType.Expression) {
      throw new models_1.UfError('Query cannot stringify NodeType.Expression, expression must be evaluated and converted to NodeType.Value', client_models_1.ErrorType.Validation);
    }
    if (this.type === models_1.NodeType.Identifier) {
      return this.value;
    }
    if (this.type === models_1.NodeType.Value) {
      return stringifyValue(this.value);
    }
    const countValue = this.args[0]?.value;
    const startValue = this.args[1]?.value;
    if (this.op === models_1.QueryOperators.Limit && countValue != null && startValue != null) {
      return `limit(${countValue},${startValue})`;
    }
    if (this.op === models_1.QueryOperators.Include) {
      return `include(${this.args.filter(a => a.type === models_1.NodeType.Identifier).map(a => a.value).join(',')})`;
    }
    return `${this.op}(${this.args.map(q => q.stringify()).join(',')})`;
  }
}
exports.Q = Q;
class Query extends Q {
  constructor() {
    super(models_1.NodeType.Combinator, models_1.QueryOperators.And);
  }
  // Equal
  eq(identifier, value) {
    this.args.push(Q.eq(identifier, value));
    return this;
  }
  // Not equal
  ne(identifier, value) {
    this.args.push(Q.ne(identifier, value));
    return this;
  }
  // Less than
  lt(identifier, value) {
    this.args.push(Q.lt(identifier, value));
    return this;
  }
  // Less than or equal
  le(identifier, value) {
    this.args.push(Q.le(identifier, value));
    return this;
  }
  // Greater than
  gt(identifier, value) {
    this.args.push(Q.gt(identifier, value));
    return this;
  }
  // Greater than or equal
  ge(identifier, value) {
    this.args.push(Q.ge(identifier, value));
    return this;
  }
  // Equal to any of these (is in set?)
  in(identifier, value) {
    this.args.push(Q.in(identifier, value));
    return this;
  }
  // Array contains this item
  contains(identifier, value) {
    this.args.push(Q.contains(identifier, value));
    return this;
  }
  // Same or descendant unit in the hierarchy
  descs(identifier, value) {
    this.args.push(Q.descs(identifier, value));
    return this;
  }
  // Negate
  not(q) {
    this.args.push(Q.not(q));
    return this;
  }
  // All input queries
  and(...qs) {
    this.args.push(Q.and(...qs));
    return this;
  }
  // Any of the input queries
  or(...qs) {
    this.args.push(Q.or(...qs));
    return this;
  }
  // Query 'term'
  q(term) {
    this.args.push(new Q(models_1.NodeType.Operator, models_1.QueryOperators.Search, Q.value(term)));
    return this;
  }
  limit(count, start = 0) {
    if (count == null) {
      return this;
    }
    const index = this.args.findIndex(q => q.op === models_1.QueryOperators.Limit);
    if (index >= 0) {
      this.args.splice(index, 1);
    }
    this.args.push(new Q(models_1.NodeType.Operator, models_1.QueryOperators.Limit, Q.value(count), Q.value(start)));
    return this;
  }
  sort(identifier, direction = models_1.SortDirections.Ascending) {
    const index = this.args.findIndex(q => q.op === models_1.QueryOperators.Sort);
    if (index >= 0) {
      this.args.splice(index, 1);
    }
    const prefix = direction === models_1.SortDirections.Descending ? '-' : '+';
    this.args.push(new Q(models_1.NodeType.Operator, models_1.QueryOperators.Sort, Q.identifier(prefix + identifier)));
    return this;
  }
  include(identifiers) {
    const index = this.args.findIndex(q => q.op === models_1.QueryOperators.Include);
    if (index >= 0) {
      this.args.splice(index, 1);
    }
    this.args.push(new Q(models_1.NodeType.Operator, models_1.QueryOperators.Include, ...identifiers.map(i => Q.identifier(i))));
    return this;
  }
  fromAst(ast) {
    const q = Q.fromAst(ast);
    if (q) {
      this.args.push(q);
    }
    return this;
  }
  stringify() {
    return this.args.map(q => q.stringify()).join('&');
  }
}
exports.Query = Query;
const stringifyValue = value => {
  if (Array.isArray(value)) {
    return `(${value.map(stringifyValue).join(',')})`;
  }
  if (typeof value === 'number' && isFinite(value)) {
    return `number:${value}`;
  }
  if (typeof value === 'boolean') {
    return `bool:${value}`;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return encodeURIComponent(value);
};
