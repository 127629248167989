"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Uploader = void 0;
const http_status_codes_1 = require("http-status-codes");
const constants_1 = require("./constants");
const functions_1 = require("./functions");
const models_1 = require("./models");
/**
 * Uploader needs some work copy pasted from client
 * would be good to break up upload function
 */
class Uploader {
  upload({
    file,
    url,
    id,
    onProgress,
    signal,
    accessToken,
    appId
  }) {
    // TODO break this method up into smaller
    return new Promise((resolve, reject) => {
      let aborted = false;
      const xhr = new XMLHttpRequest();
      if (signal) {
        signal.onabort = () => {
          xhr.abort();
        };
      }
      const formData = new FormData();
      const key = id == null ? 'file' : `file-${id}`;
      const blob = new Blob([file], {
        type: file.type
      });
      formData.append(key, blob, file.name);
      xhr.open('POST', url, true);
      xhr.setRequestHeader('Accept-Language', 'source');
      if (accessToken != null) {
        xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
      }
      if (appId != null) {
        xhr.setRequestHeader('X-Unifii-App', appId);
      }
      let lastProgress;
      xhr.upload.onprogress = e => {
        lastProgress = {
          done: e.loaded,
          total: e.total
        };
        if (onProgress) {
          onProgress(lastProgress);
        }
      };
      xhr.upload.onabort = () => {
        const abortException = new DOMException('Upload aborted', constants_1.DOMExceptionAbortErrorName);
        aborted = true;
        reject(abortException);
      };
      xhr.onerror = e => {
        reject(e);
      };
      xhr.onloadend = e => {
        const responseId = this.getResponseId(xhr.response);
        if (responseId) {
          lastProgress.id = responseId;
          resolve(lastProgress);
        } else if (!aborted) {
          let error = e;
          if (e.target instanceof XMLHttpRequest) {
            error = this.getError(e.target);
          }
          reject(error);
        }
      };
      xhr.responseType = 'json';
      xhr.send(formData);
    });
  }
  getResponseId(response) {
    if (typeof response === 'string') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      response = JSON.parse(response);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (response?.id) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return response.id;
      }
    }
    if (response != null && typeof response === 'object') {
      if (Array.isArray(response)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response = response[0];
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      return response.id;
    }
    return undefined;
  }
  getError(request) {
    const {
      status
    } = request;
    const type = (0, functions_1.getErrorType)(status);
    const message = (0, http_status_codes_1.getReasonPhrase)(status);
    return new models_1.UfRequestError(message, type, undefined, status);
  }
}
exports.Uploader = Uploader;
