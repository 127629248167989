import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalRuntime, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

enum ControlKeys {
    Name = 'name',
}

@Component({
    selector: 'uc-new-project-modal',
    templateUrl: './new-project-modal.html',
    standalone: false
})
export class NewProjectModalComponent implements Modal<void, string>, OnInit {

    @HostBinding('class.uc-form-card') formCardClass = true;

    runtime = inject<ModalRuntime<void, string>>(ModalRuntime);

    protected readonly controlKeys = ControlKeys;
    protected form: UfControlGroup;

    private ufb = inject(UfFormBuilder);

    ngOnInit() {
        this.form = this.ufb.group({
            [ControlKeys.Name]: [
                undefined,
                ValidatorFunctions.required('Field is required'),
            ],
        });
    }

    protected save() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.runtime.close(this.form.get(ControlKeys.Name)?.value);
    }

}
