import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

import { DataSourcesTableManager } from './data-sources-table-manager';

@Component({
    selector: 'uc-data-sources',
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()"  class="accent list-md pad-none" />
        </div>
        <router-outlet />
    `,
    providers: [
        { provide: TableContainerManager, useClass: DataSourcesTableManager },
    ],
    standalone: false
})
export class DataSourcesTableComponent {

    private router = inject(Router);
    private route = inject(ActivatedRoute);

    addItem() {
        void this.router.navigate(['./new'], { relativeTo: this.route });
    }

}
