import { Component, Inject, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ClipboardService, ModalService, ToastService } from '@unifii/library/common';

import { UcPermission, UcPermissionsClient } from 'client';

import { PermissionEditorComponent } from './editor';
import { PermissionChangeAction, PermissionsManagerService } from './permissions-manager.service';
import { PermissionsTableManager } from './permissions-table-manager';

@Component({
    selector: 'uc-permissions',
    templateUrl: './permissions.html',
    providers: [
        { provide: TableContainerManager, useClass: PermissionsTableManager },
    ],
    standalone: false
})
export class PermissionsComponent {

    protected readonly: boolean;
    protected stretchedContainer = !!inject(ActivatedRoute).snapshot.data.stretchedContainer;
    protected errorMessage?: string;

    constructor(
        private ucPermissions: UcPermissionsClient,
        private permissionsManager: PermissionsManagerService,
        private toastService: ToastService,
        private modalService: ModalService,
        private clipboard: ClipboardService,
        @Inject(TableContainerManager) private tableManager: PermissionsTableManager,

    ) {
        this.readonly = this.tableManager.readonly;
    }

    /** Used to paste from another tenant */
    protected async paste() {
        try {
            const text = await this.clipboard.getText();
            const permissions = text ? JSON.parse(text) as UcPermission[] : [];

            let copied = 0;

            for (const permission of permissions) {
                delete permission.id;
                try {
                    await this.ucPermissions.save(permission);
                    copied++;
                } catch (e) {
                    console.error(e);
                }
            }

            this.toastService.success(`Copied ${copied} out of ${permissions.length} permissions`);
            if (copied) {
                this.tableManager.reload.next();
            }
        } catch (err) {
            console.error('Failed to read clipboard contents: ', err);
            this.toastService.error('Failed to paste');
        }
    }

    protected async addPermission() {
        const newPermission: UcPermission = {
            principalType: this.ucPermissions.principalType,
            principalId: this.ucPermissions.principalId,
            actions: [],
            path: [],
        };

        const permission = await this.modalService.openLarge(
            PermissionEditorComponent,
            { service: this.ucPermissions, permission: newPermission },
        );

        if (!permission) {
            return;
        }

        this.tableManager.reload.next();

        this.permissionsManager.notify.next({
            action: PermissionChangeAction.Added,
            principalType: this.ucPermissions.principalType,
            principalId: this.ucPermissions.principalId,
        });

        this.toastService.success('Permission added');
    }

}
