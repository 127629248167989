import { Component, OnInit, TemplateRef, ViewChild, inject } from '@angular/core';
import { TabsComponent } from '@unifii/library/common';

import { SystemRole } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { TabConfig } from 'pages/page-models';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { OnGrandChildNavigationEndService } from 'services/on-grandchild-navigation-end.service';
import { TitleService } from 'services/title.service';

@Component({
    selector: 'uc-tenant-settings',
    templateUrl: './tenant-settings.html',
    providers: [OnGrandChildNavigationEndService],
    standalone: false
})
export class TenantSettingsComponent extends TabsPage implements OnInit {

    @ViewChild(TabsComponent, { static: true }) tabsComponent: TabsComponent;

    override edited: boolean;
    templateRef?: TemplateRef<any>;
    protected tabs: TabConfig[] = [];

    private grandChildrenNavigationService = inject(OnGrandChildNavigationEndService);
    private breadcrumbService = inject(BreadcrumbService);
    private context = inject(ContextService);
    private builderHeaderService = inject(BuilderHeaderService);
    private titleService = inject(TitleService);

    override ngOnInit() {
        if (this.context.checkRoles(SystemRole.SystemManager)) {
            this.tabs.push(
                { label: 'General', path: 'general' },
                { label: 'Identities', path: 'sso' },
                { label: 'Email', path: 'email' },
                { label: 'Messaging', path: 'messaging' },
                { label: 'Integrations', path: 'integrations' },
            );
        }
        if (this.context.checkRoles(SystemRole.SuperUser, SystemRole.AppManager)) {
            this.tabs.push(
                { label: 'Apps', path: 'apps' },
            );
        }
        this.buildHeaderConfig();
        this.grandChildrenNavigationService.register(this.buildHeaderConfig.bind(this));
    }

    protected updateTitle() {
        const currentTab = this.tabsComponent.selected;

        if (currentTab) {
            this.titleService.updateTitle(`Settings | ${currentTab.label}`, true);
        }
    }

    private buildHeaderConfig() {
        this.builderHeaderService.init();
        this.builderHeaderService.buildConfig({
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route),
            hideSaveButton: true,
        });
        this.templateRef = undefined;

        this.updateTitle();
    }

}
