import { Component, HostBinding, Inject, OnDestroy } from '@angular/core';
import { DataDisplayListItem, DataPropertyDescriptor, DataSourceMappingDisplayAllowedDataTypes, Modal, ModalData, ModalRuntime, UfControl, UfControlGroup } from '@unifii/library/common';
import { FieldType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { camelizeNoLowerCase } from 'helpers/field-identifier-helper';

import { DataSourceDisplayTo, getAttribute } from './data-source-editor-functions';
import { DataSourceMappingControlKeys } from './data-source-model';

export interface DataSourceMappingEditorData {
    properties: DataPropertyDescriptor[];
    mapping: UfControlGroup;
    fieldIdentifier?: string;
}

@Component({
    selector: 'uc-data-source-mapping-editor',
    templateUrl: './data-source-mapping-editor.html',
    standalone: false
})
export class DataSourceMappingEditorComponent implements Modal<DataSourceMappingEditorData, UfControlGroup>, OnDestroy {

    @HostBinding('class.uf-form-card') cardClass = true;

    guard: boolean;

    protected readonly mappingKeys = DataSourceMappingControlKeys;
    protected form: UfControlGroup;
    protected dataTypeDescriptor: DataDisplayListItem[];
    protected attributeDescriptor: DataDisplayListItem[];
    protected filteredProperties: DataPropertyDescriptor[];
    protected showItemTemplate = false;
    protected fromCtrl: UfControl;
    protected fromExpressionCtrl: UfControl;
    protected typeCtrl: UfControl;
    protected toCtrl: UfControl;
    protected labelCtrl: UfControl;
    protected isVisibleCtrl: UfControl;
    protected hideEmptyCtrl: UfControl;
    protected itemTemplateCtrl: UfControl;

    private subscription = new Subscription();

    constructor(
        public runtime: ModalRuntime<DataSourceMappingEditorData, UfControlGroup>,
        @Inject(ModalData) public data: DataSourceMappingEditorData,
    ) {
        this.form = data.mapping;

        this.fromExpressionCtrl = this.form.get(DataSourceMappingControlKeys.FromExpression) as UfControl;
        this.fromCtrl = this.form.get(DataSourceMappingControlKeys.From) as UfControl;
        this.typeCtrl = this.form.get(DataSourceMappingControlKeys.Type) as UfControl;
        this.toCtrl = this.form.get(DataSourceMappingControlKeys.To) as UfControl;
        this.labelCtrl = this.form.get(DataSourceMappingControlKeys.Label) as UfControl;
        this.isVisibleCtrl = this.form.get(DataSourceMappingControlKeys.IsVisible) as UfControl;
        this.hideEmptyCtrl = this.form.get(DataSourceMappingControlKeys.HideEmpty) as UfControl;
        this.itemTemplateCtrl = this.form.get(DataSourceMappingControlKeys.ItemTemplate) as UfControl;

        this.subscription.add(this.typeCtrl.valueChanges.subscribe((type: FieldType | null) => this.onTypeChange(type)));
        this.subscription.add(this.toCtrl.valueChanges.subscribe(() => this.updateAttributeDescriptor()));
        this.subscription.add(this.form.valueChanges.subscribe(() => { this.guard = true; }));

        this.onTypeChange(this.typeCtrl.value);
        this.updateAttributeDescriptor();
    }

    protected get allowIsExpression() {
        return this.toCtrl.value === DataSourceDisplayTo;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    close() {
        this.runtime.close();
    }

    protected filterProperties(query: string) {

        const properties = this.data.properties.filter((p) => this.toCtrl.value !== DataSourceDisplayTo || DataSourceMappingDisplayAllowedDataTypes.includes(p.type));

        if (!query.trim()) {
            this.filteredProperties = [...properties];

            return;
        }
        const term = query.trim().toLowerCase();

        this.filteredProperties = properties.filter((p) => (p.display ?? '').toLocaleLowerCase().includes(term));
    }

    protected changedIsExpression(isExpression?: boolean) {
        if (isExpression) {
            this.fromCtrl.setValue(null);
            this.typeCtrl.setValue(FieldType.Text);
        } else {
            this.fromExpressionCtrl.setValue(null);
            this.typeCtrl.setValue(this.fromCtrl.value?.type ?? null);
        }
    }

    protected changedFrom(from?: DataPropertyDescriptor) {

        if (!this.toCtrl.disabled) {
            const to = from ? camelizeNoLowerCase(from.identifier) : null;

            this.toCtrl.setValue(to);
        }

        this.typeCtrl.setValue(from?.type ?? null);

        if (this.typeCtrl.value == null) {
            this.isVisibleCtrl.setValue(null);
            this.isVisibleCtrl.disable();
        } else {
            this.isVisibleCtrl.enable();
        }

        if (!this.isVisibleCtrl.value) {
            this.itemTemplateCtrl.disable();
            this.itemTemplateCtrl.setValue(undefined, { onlySelf: true, emitEvent: false });
            this.hideEmptyCtrl.setValue(false);
            this.hideEmptyCtrl.disable();
        } else {
            if ((this.typeCtrl.value as FieldType) === FieldType.Repeat) {
                this.itemTemplateCtrl.enable();
            }
            this.hideEmptyCtrl.enable();
        }

        if (this.labelCtrl.value == null) {
            this.labelCtrl.setValue(from?.label ?? null);
        }

    }

    protected changedVisible(v?: boolean) {
        if (v) {
            this.hideEmptyCtrl.enable();
            this.hideEmptyCtrl.setValue(true);
            if ((this.typeCtrl.value as FieldType) === FieldType.Repeat) {
                this.itemTemplateCtrl.enable();
            }
        } else {
            this.hideEmptyCtrl.disable();
            this.hideEmptyCtrl.setValue(false);
            this.itemTemplateCtrl.disable();
            this.itemTemplateCtrl.setValue(undefined, { onlySelf: true, emitEvent: false });
        }
    }

    protected submit() {
        this.form.setSubmitted();
        if (this.form.invalid) {
            return;
        }

        this.runtime.close(this.form);
    }

    private onTypeChange(type: FieldType | null) {
        this.dataTypeDescriptor = [{ term: 'Data Type', data: type ?? undefined }];
        this.showItemTemplate = type === FieldType.Repeat;
    }

    private updateAttributeDescriptor() {
        this.attributeDescriptor = [{ term: 'Attribute', data: getAttribute(this.form, this.data.fieldIdentifier) }];
    }

}
