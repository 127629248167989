import { Component, HostBinding, inject } from '@angular/core';
import { ClipboardService, Modal, ModalData, ModalRuntime, ToastService } from '@unifii/library/common';
import { FormData } from '@unifii/sdk';

@Component({
    templateUrl: './form-data-display-modal.html',
    selector: 'uc-form-preview-data-modal',
    standalone: false
})
export class FormDataDisplayModal implements Modal<FormData, void> {

    @HostBinding('class.uc-form-card') formCardClass = true;

    runtime = inject<ModalRuntime<FormData, void>>(ModalRuntime);

    protected formData = JSON.stringify(inject<FormData>(ModalData), null, 4);

    private clipboard = inject(ClipboardService);
    private toastService = inject(ToastService);

    close() {
        this.runtime.close();
    }

    protected copy() {
        this.clipboard.setText(this.formData);
        this.toastService.success('Data copied');
    }

}
