import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { AuthProvider, Option } from '@unifii/sdk';

import { AuthProviderMappingConditionType, AuthProviderSourceGroup, UcAuthProviders, UcRoles } from 'client';
import { ArrayHelper } from 'helpers/array-helper';

import { ConditionOptions, ConditionTypesDescription, GroupConditionOption, MappingConfig, MappingsControlKeys, OrConditionOption,
    RoleAssignedConditionOption } from '../models';

import { AuthProviderMappingsController } from './auth-provider-mapping.controller';

@Component({
    selector: 'uc-auth-provider-mapping-unit',
    templateUrl: './auth-provider-mapping-unit.html',
    standalone: false
})
export class AuthProviderMappingUnitComponent implements OnInit {

    @Input() form: UfControlGroup;
    @Input() parent?: UfControlGroup;
    @Input() sourceClaims: string[];
    @Input() authProviderId: string;
    @Input() identifier: number;
    @Input() authProviderType: AuthProvider;
    @Input() config: MappingConfig;

    @Output() deleteClick = new EventEmitter<{ position: number; parent?: UfControlGroup }>();

    protected readonly controlKeys = MappingsControlKeys;
    protected readonly types = AuthProviderMappingConditionType;
    protected readonly typeDescription = ConditionTypesDescription;
    protected conditionOptions = ConditionOptions;
    protected id: string;
    protected type: AuthProviderMappingConditionType;
    protected childrenControls: UfControlArray;
    protected filteredSourceGroups: AuthProviderSourceGroup[];
    protected filteredSourceClaims: string[];
    protected filteredRoles: string[];
    protected allowCustomRoles: boolean;
    protected groupControl: UfControl | null;

    constructor(
        private ucAuthProviders: UcAuthProviders,
        private mappingsController: AuthProviderMappingsController,
        private ucRoles: UcRoles,
    ) { }

    ngOnInit() {
        this.id = this.form.get(MappingsControlKeys.Id)?.value;
        this.type = this.form.get(MappingsControlKeys.Type)?.value;
        this.groupControl = this.form.get(MappingsControlKeys.Group) as UfControl | null;

        if (this.authProviderType === AuthProvider.Auth0) {
            this.conditionOptions = [
                RoleAssignedConditionOption,
                ...ConditionOptions,
            ];
        } else if (this.authProviderType === AuthProvider.UnifiiIdentity) {
            this.allowCustomRoles = true;
            this.conditionOptions = [
                RoleAssignedConditionOption,
            ];
        } else {
            this.conditionOptions = [
                GroupConditionOption,
                ...ConditionOptions,
            ];
        }
        this.conditionOptions.push(OrConditionOption);

        this.childrenControls = this.form.get(MappingsControlKeys.Children) as UfControlArray;
    }

    protected async findSourceGroups(query: string) {
        if (query?.trim().length) {
            try {
                this.filteredSourceGroups = await this.ucAuthProviders.getAuthProviderGroups(this.authProviderId, query.trim());

                return;
            } catch (e) {
                console.warn('Error loading provider groups - ', e);
            }
        }
        this.filteredSourceGroups = [];
    }

    protected changeGroup(control: UfControl, group?: AuthProviderSourceGroup | string) {
        if (!group) {
            return;
        }

        if (typeof group === 'string') {
            control.setValue({ id: group, name: group });
        }
    }

    protected async findRoles(query: string) {
        let roles;

        if (![AuthProvider.Auth0, AuthProvider.UnifiiIdentity].includes(this.authProviderType)) {
            roles = await this.ucRoles.get(query);
        } else {
            if (this.authProviderType !== AuthProvider.UnifiiIdentity) {
                roles = await this.ucAuthProviders.getAuthProviderRoles(this.authProviderId, { params: { q: query } });
            }
        }
        this.filteredRoles = (roles ?? []).map((r) => r.name);
    }

    protected findSourceClaims(query: string) {
        this.filteredSourceClaims = ArrayHelper.filterList(this.sourceClaims, query);
    }

    protected addCondition(option: Option) {
        const type = option.identifier as AuthProviderMappingConditionType;
        const controlToAdd = this.mappingsController.buildCondition({ type });

        this.childrenControls.push(controlToAdd);
    }

}
