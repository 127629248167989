import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DataPropertyDescriptor, SortStatus, UfControlValueAccessor } from '@unifii/library/common';
import { SortDirections } from '@unifii/sdk';

@Component({
    selector: 'uc-sort-property-input',
    templateUrl: 'sort-property-input.html',
    providers: [{
            provide: NG_VALUE_ACCESSOR, useExisting: SortPropertyInputComponent, multi: true,
        }],
    standalone: false
})
export class SortPropertyInputComponent extends UfControlValueAccessor<string> implements OnInit {

    @Input() label: string | null | undefined;
    @Input() properties: DataPropertyDescriptor[];
    @Output() override valueChange = new EventEmitter<string>();

    protected readonly tableSortOptions = [
        { name: 'Ascending (A > Z, 0 > 10, Oldest > Newest)', value: true },
        { name: 'Descending (Z > A, 10 > 0, Newest > Oldest)', value: false },
    ];

    protected sortProperty: DataPropertyDescriptor | null;
    protected sortResults: DataPropertyDescriptor[];
    protected sortAscending: boolean;

    ngOnInit() {
        const sort = SortStatus.fromString(this.value);

        this.sortProperty = sort ? this.properties.find((c) => c.identifier === sort.name) ?? null : null;
        this.sortAscending = sort ? sort.direction === SortDirections.Ascending : false;
    }

    protected encodeSort() {
        if (this.sortProperty == null) {
            this.value = undefined;
        } else {
            const sort = new SortStatus(
                this.sortProperty.identifier,
                this.sortAscending ? SortDirections.Ascending : SortDirections.Descending,
            );

            this.value = sort.toString();
        }

        this.control.markAsTouched();
        this.valueChange.emit(this.value);
        this.control.setValue(this.value);
    }

    protected search(query: string) {
        this.sortResults = this.properties.filter((dp) => {
            if (query.trim().length) {
                return (dp.display as string).toLowerCase().includes(query.toLowerCase());
            }

            return true;
        });
    }

}
