import { Component, HostBinding, Inject } from '@angular/core';
import { DataDisplayListItem, DataDisplayService, Modal, ModalData, ModalRuntime } from '@unifii/library/common';
import { DataType, TenantSettings } from '@unifii/sdk';

import { Config } from 'app-config';

@Component({
    selector: 'uc-contact-info-modal',
    templateUrl: './contact-info-modal.html',
    standalone: false
})
export class ContactInfoModalComponent implements Modal<TenantSettings, void> {

    @HostBinding('class.uc-card') class = true;
    @HostBinding('class.grid') class1 = true;

    contactDetails: DataDisplayListItem[] = [];

    constructor(
        public runtime: ModalRuntime<TenantSettings, void>,
        @Inject(ModalData) public data: TenantSettings,
        @Inject(Config) public config: Config,
        @Inject(DataDisplayService) dataDisplayService: DataDisplayService,
    ) {
        if (data.contactName != null) {
            this.contactDetails.push({ term: 'Name', data: data.contactName });
        }

        if (data.contactEmail != null) {
            this.contactDetails.push({ term: 'Email', data: dataDisplayService.displayAsDataDisplayValue(data.contactEmail, { type: DataType.Email }) });
        }

        if (data.contactPhone != null) {
            this.contactDetails.push({ term: 'Phone', data: dataDisplayService.displayAsDataDisplayValue(data.contactPhone, { type: DataType.Phone }) });
        }
    }

    close() {
        this.runtime.close();
    }

}
