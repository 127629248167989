import { ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ExpandersService, ModalService, RuntimeDefinition, RuntimeDefinitionAdapter, ToastService, UfControl, UfControlGroup, ValidatorFunctions, fieldIterator } from '@unifii/library/common';
import { FormConfiguration, FormSettings } from '@unifii/library/smart-forms';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { FormComponentRegistry, InputFormSettings, UfFormComponent } from '@unifii/library/smart-forms/input';
import { CompoundType, Definition, FieldType, UfError, ensureUfRequestError, isDictionary, isString } from '@unifii/sdk';
import { debounceTime } from 'rxjs/operators';

import { CompoundInfo, SystemRole, UcCompound, UcDefinition } from 'client';
import { ContentSettings, EditMode, SaveOption, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { MarkdownEditorRegistry } from 'components/markdown-editor-registry';
import { ConsoleNameLabel } from 'constant';
import { CollectionService } from 'pages/content/collections/collection-service';
import { appendSuffixCopy, cleanDefinitionToBeDuplicated } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { UcTableManager } from 'services/table/models';
import { TitleService } from 'services/title.service';

enum CollectionItemControlKeys {
    ConsoleName = 'consoleName',
    Title = '_title',
}

@Component({
    selector: 'uc-collection-item-builder',
    templateUrl: './collection-item-builder.html',
    styleUrls: ['./collection-item-builder.less'],
    providers: [
        BuilderService,
        ExpandersService,
        { provide: ContentSettings, useValue: {} },
        { provide: FormSettings, useClass: InputFormSettings },
        { provide: FormComponentRegistry, useClass: MarkdownEditorRegistry },
    ],
    standalone: false
})
export class CollectionItemBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

    @ViewChild('form', { static: true }) form: ElementRef;

    type = CompoundType.Collection;
    subject = BuilderCompoundSubjects.CONTENT;

    protected readonly consoleNameLabel = ConsoleNameLabel;
    protected error?: UfError;
    protected config: FormConfiguration = { hideLabel: true };
    protected ready: boolean;
    protected rootControl?: UfControlGroup;
    protected definition: UcDefinition;
    protected displayDefinition: RuntimeDefinition;
    protected displayCompound?: UcCompound;
    protected compound: UcCompound;

    private consoleNameControl = new UfControl(ValidatorFunctions.required('This field is mandatory'));
    private titleControl = new UfControl(ValidatorFunctions.required('This field is mandatory'));
    private title: string;

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected override router: Router,
        protected override route: ActivatedRoute,
        private service: CollectionService,
        private toastService: ToastService,
        private displayService: DisplayService,
        private cdr: ChangeDetectorRef,
        private breadcrumbService: BreadcrumbService,
        public expandersService: ExpandersService,
        private builderHeaderService: BuilderHeaderService,
        private runtimeDefinitionAdapter: RuntimeDefinitionAdapter,
        @Optional() @Inject(TableContainerManager) tableManager: UcTableManager<CompoundInfo> | null,
        private titleService: TitleService,
    ) {
        super(builderService, modalService, route, tableManager);
    }

    async ngOnInit() {
        this.subscriptions.add(this.approveClicked.subscribe((id) => void this.approve(id)));

        // wait for collection service to finish loading
        await this.service.definitionLoadPromise;

        this.definition = this.service.definition;
        this.definition.settings = Object.assign(this.definition.settings ? this.definition.settings : {}, { inputStyle: 'small' });
        this.displayDefinition = await this.runtimeDefinitionAdapter.transform(this.definition as Definition);

        // Load data
        const compound = await this.load();

        if (!compound) {
            return;
        }

        this.compound = compound;

        this.titleService.updateTitle(this.mapContentItemName(compound.consoleName));
        this.addSubscribers();

        // Init builder service
        this.builderService.init(this, this.definition, this.compound);

        await this.updatePreview();

        if (this.editMode === EditMode.New) {
            this.subscriptions.add(this.titleControl.valueChanges
                .subscribe((v) => {
                    if (!this.consoleNameControl.dirty) {
                        this.consoleNameControl.setValue(v, { onlySelf: false, emitEvent: true });
                    }
                }));
        }

        this.builderHeaderService.init();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
        this.buildHeaderConfig(this.compound);
    }

    @ViewChild(UfFormComponent, { static: false })
    set ufForm(form: UfFormComponent | undefined) {

        /** Because of ready bool form will be undefined until ready changes */
        if (!form?.rootControl || this.rootControl) {
            return;
        }

        form.rootControl.setControl(CollectionItemControlKeys.ConsoleName, this.consoleNameControl);
        form.rootControl.setControl(CollectionItemControlKeys.Title, this.titleControl);
        this.rootControl = form.rootControl;

        this.subscriptions.add(this.rootControl.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            if (form.rootControl?.dirty) {
                this.saveStatus();
            }
            void this.updatePreview();
        }));

        this.cdr.detectChanges();
    }

    private addSubscribers() {
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe((i) => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));
    }

    private async save(saveOption?: SaveOption) {

        // Notify the other components of a builder save/submit action
        // this.builderService.submit.next(null); // TODO Why different from the other builders

        if (!this.rootControl) {
            return;
        }

        this.rootControl.updateValueAndValidity();

        if (this.rootControl.invalid) {
            this.rootControl.setSubmitted();
            this.toastService.error('Unable to save. There are errors in your Collection.');

            return;
        }

        if ([EditMode.New, EditMode.Duplicate].includes(this.editMode)) {
            /**
             * Form component creates id on init
             * this needs to be removed for new entries
             */
            delete this.compound.id;
        }

        this.rootControl.markAsPristine();

        // Trim Website fields value
        if (this.definition.fields) {
            for (const entry of fieldIterator(this.definition.fields)) {
                if (entry.field.type === FieldType.Website && entry.field.identifier && this.compound[entry.field.identifier]) {
                    this.compound[entry.field.identifier] = (this.compound[entry.field.identifier] as string).trim();
                }
            }
        }

        // Save the compound
        try {
            this.builderService.busy.next(true);
            this.compound = await this.service.ucCollection.saveItem(this.compound);

            // Notify user of the save success
            this.toastService.success('Collection saved');

            this.saved(this.compound, saveOption);
            this.titleService.updateTitle(this.compound.consoleName);
            this.builderService.init(this, this.definition, this.compound);
            this.buildHeaderConfig(this.compound);
        } catch (e) {
            const error = ensureUfRequestError(e, 'Oops... something went wrong with saving your form');
            const message = isDictionary(error.data) && isString(error.data.message) ? error.data.message : error.message;

            this.toastService.error(message);
        } finally {
            this.builderService.busy.next(false);
        }
    }

    private async updatePreview() {

        /** Definition without LinkList fields doesn't need DisplayService */
        if (this.displayDefinition.fields.find((field) => field.type === FieldType.LinkList) == null) {
            this.displayCompound = this.compound;

            return;
        }

        /** LinkList fields need to be converted to Compounds before rendered */
        const displayContent = await this.displayService.renderCompound(this.displayDefinition, this.compound);

        if (!displayContent.definition || !displayContent.compound) {
            this.displayCompound = undefined;

            return;
        }

        this.displayDefinition = displayContent.definition;
        this.displayCompound = displayContent.compound;
    }

    private async load(): Promise<UcCompound | undefined> {

        if (this.editMode === EditMode.New) {
            return {} as UcCompound;
        }

        let compound;

        try {
            compound = await this.service.ucCollection.getItem(+this.route.snapshot.params.id);
        } catch (err) {
            this.error = useDefaultErrorMessage(err);

            return;
        }

        if (this.editMode === EditMode.Existing) {
            return compound;
        }

        const duplicatedCompound = cleanDefinitionToBeDuplicated(compound) as UcCompound;

        duplicatedCompound._title = appendSuffixCopy({ label: duplicatedCompound._title });
        duplicatedCompound.consoleName = appendSuffixCopy({ label: duplicatedCompound.consoleName });

        return duplicatedCompound;
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.builderService.compound, i.atomic);
        this.builderService.memento.edited = true;
        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }
    }

    private buildHeaderConfig(definition: UcCompound) {
        this.builderHeaderService.buildConfig({
            ...definition,
            title: this.title,
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.builderService.definition?.label || '', this.mapContentItemName(this.compound.consoleName)]),
            publishState: definition.publishState,
            saveOptions: this.saveOptions,
            restrictSave: SystemRole.ContentEditor,
            cancelRoute: ['../'],
        });
    }

    private async approve(id?: number) {
        if (!id) {
            return;
        }
        try {
            const compound = await this.service.ucCollection.approveCollectionItem(id);

            this.buildHeaderConfig(compound);
            this.tableManager?.updateItem.next(compound);
            this.back();
        } catch (e) {
            this.toastService.error((e as UfError).message);
        }
    }

}
