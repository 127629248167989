import { Component, Input, OnInit, inject } from '@angular/core';
import { AngularRouterLink, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { StructureNodeBucketOptions, TableSourceType } from '@unifii/sdk';

import { TableInfo, UcProject, UcRoles } from 'client';
import { DialogsService } from 'services/dialogs.service';

import { StructureNodeBucketOptionsControlKeys } from '../structure-control-keys';
import { StructureEditorCache } from '../structure-editor-cache';
import { StructureFormCtrl } from '../structure-form-ctrl';

type TableDescription = {
    identifier: string;
    title: string;
    routerLink: AngularRouterLink | undefined;
};

@Component({
    selector: 'uc-node-dashboard-tables',
    templateUrl: './node-dashboard-tables.html',
    standalone: false
})
export class NodeDashboardTablesComponent implements OnInit {

    @Input({ required: true }) control: UfControlArray;

    protected readonly formKeys = StructureNodeBucketOptionsControlKeys;
    protected tables: TableInfo[];
    protected searchResults: TableInfo[] = [];
    protected rolesResults: string[];
    protected tableDescriptions: Record<string, TableDescription> = {};
    protected ready = false;

    private cache = inject(StructureEditorCache);
    private ucRoles = inject(UcRoles);
    private project = inject(UcProject);
    private sfb = inject(StructureFormCtrl);
    private dialogs = inject(DialogsService);

    async ngOnInit() {
        for (const bucketOption of this.bucketOptions) {
            if (!bucketOption.identifier) {
                continue;
            }

            const table = await this.cache.getTable(bucketOption.identifier);

            if (!table) {
                continue;
            }

            this.tableDescriptions[bucketOption.identifier] = this.getTableDescription(table);
        }

        this.ready = true;
    }

    private get bucketOptions(): StructureNodeBucketOptions[] {
        return this.control.getRawValue() as StructureNodeBucketOptions[];
    }

    protected getIdentifier(control: UfControlGroup): string | undefined {
        return control.get(StructureNodeBucketOptionsControlKeys.Identifier)?.value as string | undefined;
    }

    protected async findRoles(query: string | null) {
        this.rolesResults = (await this.ucRoles.get(query ?? undefined)).map((r) => r.name);
    }

    protected async search(q?: string) {
        if (q && q.trim().length > 0) {
            q = q.toLowerCase().trim();
        }

        const results = await this.project.getTables({ params: { sort: 'title', type: TableSourceType.Bucket, q } });

        this.searchResults = results.filter((t) => this.bucketOptions.find((bo) => bo.identifier === t.identifier) == null);
    }

    protected add(table?: TableInfo) {

        if (!table) {
            return;
        }

        this.tableDescriptions[table.identifier] = this.getTableDescription(table);

        this.control.push(this.sfb.buildBucketOptionControl({
            identifier: table.identifier,
            pageSize: 5,
        }));
    }

    protected async remove(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.control.removeAt(index);
    }

    protected mapDescription(table: TableInfo): string {
        return `${table.title} (${table.identifier})`;
    }

    private getTableDescription(info: {title: string; id?: string; identifier: string}): TableDescription {
        return {
            identifier: info.identifier,
            title: info.title,
            routerLink: info.id ? ['..', 'tables', info.id] : undefined,
        };
    }

}
