import { Injectable, OnDestroy, inject } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { ModalService, Repository, WindowWrapper } from '@unifii/library/common';
import { compare, validate } from 'compare-versions';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Config } from 'app-config';
import { ContextService } from 'services/context.service';
import { TenantSettingsService } from 'services/tenant-settings.service';

import { RequiredUpdateModalComponent } from './required-update-modal.component';

const LastUpdateRequestKey = 'UfLastUpdateRequest';
const ForcedUpdateRequestKey = 'UfForcedUpdateRequest';

@Injectable({ providedIn: 'root' })
export class AppUpdateService implements OnDestroy {

    private config = inject(Config);
    private window = inject<Window>(WindowWrapper);
    private repo = inject(Repository);
    private modalService = inject(ModalService);
    private router = inject(Router);
    private tenantSettings = inject(TenantSettingsService);
    private context = inject(ContextService);
    private subscriptions = new Subscription();

    init() {

        this.checkAppInfo().catch(() => { /**/ });

        this.subscriptions.add(this.router.events
            .pipe(filter((event) => !!this.context.account && event instanceof NavigationStart))
            .subscribe(() => {
                this.checkAppInfo().catch(() => { /**/ });
            }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private async checkAppInfo() {

        const appInfo = (await this.tenantSettings.sync()).appInfo;

        // guard no appInfo or no/invalid config.version
        if (!appInfo || !validate(this.config.version)) {
            return;
        }

        // current version below minVersion
        if (compare(this.config.version, appInfo.minVersion, '<')) {

            const previousForcedUpdateVersion = this.repo.loadString(ForcedUpdateRequestKey);

            // never forced update before or forced a previous minVersion
            if (!previousForcedUpdateVersion || compare(previousForcedUpdateVersion, appInfo.minVersion, '<')) {
                this.repo.storeString(ForcedUpdateRequestKey, appInfo.minVersion);
                this.window.location.reload();

                return;
            }

            void this.modalService.openFullScreen(RequiredUpdateModalComponent, appInfo, { guard: true });

            return;
        }

        // current version below latestVersion
        if (compare(this.config.version, appInfo.latestVersion, '<')) {

            const previousPopupVersion = this.repo.loadString(LastUpdateRequestKey);

            //  previous version is up to date with latest version
            if (previousPopupVersion && compare(previousPopupVersion, appInfo.latestVersion, '>=')) {
                return;
            }

            this.repo.storeString(LastUpdateRequestKey, appInfo.latestVersion);
            this.window.location.reload();
        }
    }

}
