import { Component } from '@angular/core';

import { SystemRole } from 'client';
import { TabConfig } from 'pages/page-models';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

@Component({
    selector: 'uc-assets',
    templateUrl: './assets.html',
    standalone: false
})
export class AssetsComponent extends TabsPage {

    override edited: boolean;
    breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);
    tabs: TabConfig[] = [];

    constructor(
        private breadcrumbService: BreadcrumbService,
        private context: ContextService,
    ) {
        super();
        if (this.context.checkRoles(SystemRole.AssetManager, SystemRole.ContentEditor, SystemRole.Publisher, SystemRole.ProjectManager)) {
            this.tabs.push({
                label: 'Media',
                path: 'media',
            });
        }
    }

}
