import { Component, Input, OnInit, inject } from '@angular/core';
import { UfControlArray, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

import { IntegrationFeature, IntegrationFeatureArgRoot } from 'client';
import { DialogsService } from 'services/dialogs.service';

import { DataSourceArgControlKeys } from '../../model';

@Component({
    selector: 'uc-data-feature-data-mapping',
    templateUrl: './data-feature-data-mapping.html',
    standalone: false
})
export class DataFeatureDataMappingComponent implements OnInit {

    @Input({ required: true }) dataMappingControl: UfControlArray;
    @Input({ required: true }) feature: IntegrationFeature;

    protected readonly argControlKeys = DataSourceArgControlKeys;
    protected outputOptions: string[] = [];

    private dialogs = inject(DialogsService);
    private ufb = inject(UfFormBuilder);

    ngOnInit() {
        this.outputOptions = this.buildOutputOptions(this.feature.output);
    }

    protected async deleteOutput(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }

        this.dataMappingControl.removeAt(index);
    }

    protected add() {
        this.dataMappingControl.push(this.ufb.group({
            [DataSourceArgControlKeys.Key]: this.ufb.control(undefined, ValidatorFunctions.required('A value is required.')),
            [DataSourceArgControlKeys.Value]: this.ufb.control(undefined, ValidatorFunctions.required('A value is required.')),
        }));
    }

    private buildOutputOptions(output?: IntegrationFeatureArgRoot): string[] {
        if (!output?.attributes) {
            return [];
        }

        return Object.keys(output.attributes);
    }

}
