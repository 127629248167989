import { Component, Input } from '@angular/core';

import { FormSummaryInfoData } from './form-summary-info-modal.component';

@Component({
    selector: 'uc-form-summary-field-info',
    templateUrl: './form-summary-field-info.html',
    standalone: false
})
export class FormSummaryFieldInfoComponent {

    @Input({ required: true }) title: string;
    @Input({ required: true }) items: FormSummaryInfoData[] = [];

}
