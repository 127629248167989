import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ContextProvider, DataLoaderFactory, FilterEntries } from '@unifii/library/common';

import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BucketDataTableManager } from 'pages/form-data/bucket-data-table-manager';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { BucketDataFilterFactory } from './bucket-filter-factory';
import { FormDataContextProvider } from './form-data.component';

const filterEntryFactory = (factory: BucketDataFilterFactory) => factory.create();

@Component({
    template: `
        <div ufMasterDetail>
            <uf-table-container ufSyncRoute class="accent pad-none list-md" />
        </div>
        <router-outlet />
    `,
    providers: [
        DataLoaderFactory,
        BucketDataFilterFactory,
        { provide: FilterEntries, useFactory: filterEntryFactory, deps: [BucketDataFilterFactory] },
        { provide: TableContainerManager, useClass: BucketDataTableManager },
        { provide: ContextProvider, useClass: FormDataContextProvider },
    ],
    standalone: false
})
export class BucketDataComponent implements OnInit {

    @HostBinding('class.stretch-component') class = true;

    private builderHeaderService = inject(BuilderHeaderService);
    private breadcrumbService = inject(BreadcrumbService);
    private route = inject(ActivatedRoute);

    ngOnInit() {
        this.builderHeaderService.init();
        this.builderHeaderService.updateConfig({
            hideSaveButton: true,
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route),
        });
    }

}
