import { Component, HostBinding, Inject } from '@angular/core';
import { ModalData, ModalRuntime } from '@unifii/library/common';

import { Media, MediaQueryParams, UcMedia } from 'client';
import { ModalSearch, ModalSearchData } from 'components/content/modals/modal-search';

@Component({
    templateUrl: './media-search.html',
    styleUrls: ['./media-list.less'],
    standalone: false
})
export class MediaSearchComponent extends ModalSearch {

    @HostBinding('class.uc-form-card') classes = true;

    constructor(
        private ucMedia: UcMedia,
        runtime: ModalRuntime<ModalSearchData, Media[]>,
        @Inject(ModalData) data: ModalSearchData,
    ) {
        super(data, runtime);
    }

    search(q: string | undefined = undefined, offset: number): Promise<Media[]> {
        const params: MediaQueryParams = {
            q: q ?? undefined,
            types: this.data.type,
            limit: this.limit,
            offset,
        };

        return this.ucMedia.get(params);
    }

}
