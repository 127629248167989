import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';

import { UcRole } from 'client';

import { RoleModalConfig } from './roles.component';

type NewRole = Pick<UcRole, 'name' | 'label' | 'description'>;

enum ControlKeys {
    Name = 'name',
    Label = 'label',
    Description = 'description',
}

@Component({
    selector: 'uc-role-modal',
    templateUrl: './role-modal.html',
    standalone: false
})
export class RoleModalComponent implements Modal<RoleModalConfig, NewRole>, OnInit {

    @HostBinding('class.uc-form-card') formCardClass = true;

    runtime = inject<ModalRuntime<RoleModalConfig, NewRole>>(ModalRuntime);
    data = inject<RoleModalConfig>(ModalData);

    protected readonly controlKeys = ControlKeys;
    protected form: UfControlGroup;

    private ufb = inject(UfFormBuilder);

    ngOnInit() {
        this.form = this.ufb.group({
            [ControlKeys.Name]: [
                this.data.role?.name,
                ValidatorFunctions.compose([
                    ValidatorFunctions.required('Field is required'),
                    ValidatorFunctions.pattern(`^[A-Za-z][A-Za-z0-9_ ]*$`, 'Name contains an invalid character'),
                ]),
            ],
            [ControlKeys.Label]: this.data.role?.label,
            [ControlKeys.Description]: this.data.role?.description,
        });
    }

    protected save() {
        this.form.setSubmitted();

        if (this.form.invalid) {
            return;
        }

        this.runtime.close(this.form.getRawValue());
    }

}
