import { Component, HostBinding, Inject, OnDestroy, Optional } from '@angular/core';
import { ModalData, ModalRuntime } from '@unifii/library/common';
import { CompoundType } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { UcProject } from 'client';
import { BuilderService } from 'components/compound-builder/builder.service';
import { ModalSearch, ModalSearchData } from 'components/content/modals/modal-search';

export enum LinkSearchType {
    Audio = 'Audio',
    Video = 'Video',
    Image = 'Image',
    Form = 'Form',
    Collection = 'Collection',
    Compound = 'Compound'
}

export interface LinkSearchConfig extends ModalSearchData {
    type: LinkSearchType;
    ucProject?: UcProject;
    compoundType?: CompoundType; // For Compounds ('View', 'Page', 'Collection')
    types?: string[]; // For Collection/CollectionItem (['<definitionIdentifier>', ...])
    ignore?: string[]; // Used this list to filter out entries already added to list
}

@Component({
    selector: 'uc-link-search',
    templateUrl: './link-search.html',
    styleUrls: ['./media-list.less'],
    standalone: false
})
export class LinkSearchComponent extends ModalSearch implements OnDestroy {

    @HostBinding('class.uc-form-card') classes = true;

    ready: boolean;
    linkSearchTypes: typeof LinkSearchType = LinkSearchType;

    private collections: any = [];
    private subscriptions = new Subscription();

    constructor(
        @Inject(ModalData) data: LinkSearchConfig,
        runtime: ModalRuntime<LinkSearchConfig, any[]>,
        @Optional() builderService?: BuilderService,
    ) {
        super(data, runtime);

        this.ignoreList = data.ignore ?? [];

        if (this.data.type !== this.linkSearchTypes.Collection) {
            this.ready = true;

            return;
        }

        if (builderService) {
            this.subscriptions.add(builderService.ready.subscribe(() => {
                this.collections = builderService.collections;
                this.ready = true;
                this.filter();
            }));
        } else {
            (this.searchConfig.ucProject as UcProject).getCollections().then((results) => {
                this.collections = results;
                this.ready = true;
                this.filter();
            });
        }
    }

    get searchConfig(): LinkSearchConfig {
        return this.data as LinkSearchConfig;
    }

    search(query: string | null = null, offset: number): Promise<any[]> {
        // This has to handle all type of link search for the CMS
        if (this.searchConfig.type === LinkSearchType.Form) {
            return (this.searchConfig.ucProject as UcProject).getForms({ params: { q: query, offset, limit: this.limit } });
        }

        if (this.searchConfig.type === LinkSearchType.Collection) {
            return this.collections.filter(
                (collection: any) => !query ||
                    (collection.name.toLowerCase().includes(query.toLowerCase())
                    || collection.consoleName.toLowerCase().includes(query.toLowerCase())
            ));
        }

        // 'Compound'
        const types = this.searchConfig.types ?? [];

        return (this.searchConfig.ucProject as UcProject).getCompounds({
            params: {
                qx: query,
                compoundType: this.searchConfig.compoundType,
                types,
                offset,
                limit: this.limit,
            },
        });

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

}
