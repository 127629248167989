import { Pipe, PipeTransform } from '@angular/core';
import { TableSourceType } from '@unifii/sdk';

@Pipe({
    name: 'tableSourceTypeLabel',
    standalone: false
})
export class TableSourceTypeLabelPipe implements PipeTransform {

    transform(value: TableSourceType): string {

        if (!value) {
            return '';
        }

        switch (value) {
            case TableSourceType.Bucket: return 'Form Data';
            default: return value;
        }

    }

}
