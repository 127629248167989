import { Component, Input, inject } from '@angular/core';
import { UfControlArray } from '@unifii/library/common';

import { DialogsService } from 'services/dialogs.service';

import { StructureNodeArgControlKeys } from '../structure-control-keys';
import { StructureFormCtrl } from '../structure-form-ctrl';

@Component({
    selector: 'uc-node-args',
    templateUrl: './node-args.html',
    standalone: false
})
export class NodeArgsComponent {

    @Input({ required: true }) control: UfControlArray;

    protected readonly formKeys = StructureNodeArgControlKeys;

    private sfb = inject(StructureFormCtrl);
    private dialogsService = inject(DialogsService);

    protected addArg() {
        this.control.push(this.sfb.buildArgControl({
            key: '',
            value: { value: null, isExpression: false },
        }));
    }

    protected async removeArg(index: number) {
        if (!await this.dialogsService.confirmDelete()) {
            return;
        }

        this.control.removeAt(index);
    }

}
