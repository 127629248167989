import { Component, NgZone, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { DataPropertyDescriptor, ExpandersService, FilterEntry, FilterValue, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup, UfFormBuilder, ValidatorFunctions } from '@unifii/library/common';
import { Dictionary, FieldType, TableDetail, TableDetailModule, TableDetailModuleType, TableDetailTemplate, TableFieldDescriptor, TableSourceType, isNumber } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { TableInfo, UcFormBucketClient, UcProject, UcTable } from 'client';
import { EditData, SaveOption, SaveOptionType, TypeSelectComponent, TypeSelectConfig } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { ContentSelectComponent, ContentSelectType, getContentSelectConfig } from 'components/content/modals/content-select.component';
import { PageViewEmptyTablesMessage } from 'constant';
import { TablesContainerTemplateOptions } from 'pages/structure/structure-constants';
import { DialogsService } from 'services/dialogs.service';

import { TableComponent } from '../table.component';

interface FieldInfo {
    descriptor: TableFieldDescriptor;
    dataProperty?: DataPropertyDescriptor;
    _label?: string;
    _icon?: string;
    _exists?: boolean;
    _itemTemplate?: boolean;
}

export enum ControlKeys {
    Type = 'type',
    Title = 'title',
    Fields = 'fields',
    Modules = 'modules',
    DetailTemplate = 'detailTemplate',
    EmptyMessage = 'emptyMessage',
}

@Component({
    selector: 'uc-table-detail',
    templateUrl: 'table-detail.html',
    providers: [ExpandersService],
    standalone: false
})
export class TableDetailComponent implements OnInit, OnDestroy, EditData {

    ready: boolean;

    protected readonly sourceTypes = TableSourceType;
    protected readonly moduleTypes = TableDetailModuleType;
    protected readonly templateOptions = TablesContainerTemplateOptions;
    protected readonly templateEnumVales = TableDetailTemplate;

    protected table: UcTable;
    protected fields: FieldInfo[];
    protected tableDetail: TableDetail | undefined;
    protected tableDetailType: 'basic' | 'details';
    protected visibleFields: DataPropertyDescriptor[];
    protected tableDetailTypeOptions: Dictionary<string>[];
    protected availableVisibleFields: DataPropertyDescriptor[];
    protected availableHiddenFilters: DataPropertyDescriptor[];

    // Controls
    protected cRoot: UfControlGroup;
    protected cModules: UfControlArray;

    private _edited: boolean;
    private subscriptions = new Subscription();

    private zone = inject(NgZone);
    private router = inject(Router);
    private ufb = inject(UfFormBuilder);
    private ucProject = inject(UcProject);
    private route = inject(ActivatedRoute);
    private dialogs = inject(DialogsService);
    private modalService = inject(ModalService);
    private toastService = inject(ToastService);
    private tableComponent = inject(TableComponent);
    private ucFormBucketClient = inject(UcFormBucketClient);
    private builderHeaderService = inject(BuilderHeaderService);
    private tableManager = inject<TableContainerManager<TableInfo, FilterValue, FilterEntry>>(TableContainerManager);

    get edited() {
        return this._edited;
    }

    set edited(v: boolean) {
        this._edited = v;
        this.tableComponent.edited = v;
    }

    ngOnInit() {
        void this.setup();
    }

    ngOnDestroy() {
        this.tableComponent.edited = false;
        this.subscriptions.unsubscribe();
    }

    protected toggleTableDetailType() {
        if (this.tableDetailType === 'basic') {
            this.tableDetail = undefined;
        } else {
            this.tableDetail = { title: '', fields: [], modules: [], detailTemplate: TableDetailTemplate.PageView };
            this.fields = [];
        }

        this.edited = true;
        this.updateControls();
    }

    protected filterFields(q?: string) {
        this.visibleFields = this.availableVisibleFields.filter((item) => {
            let accepted = true;

            // Apply search
            if (q?.trim().length) {
                accepted =
                    item.identifier.toLowerCase().includes(q.toLowerCase()) ||
                    item.label.toLowerCase().includes(q.toLowerCase());
            }
            // Apply excluded
            if (accepted) {
                accepted = !this.tableDetail?.fields.find((field) => field.type === 'Field' && field.identifier === item.identifier);
            }

            return accepted;
        });
    }

    protected addField(identifier?: string) {

        const fieldDescriptor: TableFieldDescriptor = identifier ?
            { type: 'Field', identifier } :
            { type: 'Heading', value: '' };

        this.zone.run(() => {
            this.tableDetail?.fields.push(fieldDescriptor);
            this.fields.push(this.getFieldInfo(fieldDescriptor));
            this.edited = true;
        });
    }

    protected removeField(index: number) {
        this.zone.run(() => {
            this.tableDetail?.fields.splice(index, 1);
            this.fields.splice(index, 1);
            this.edited = true;
        });
    }

    protected async addModule() {

        const config: TypeSelectConfig = {
            title: 'Select module type',
            types: [{
                type: TableDetailModuleType.Table,
                label: TableDetailModuleType.Table,
                icon: 'table',
            }],
        };

        const choice = await this.modalService.openMedium(TypeSelectComponent, config);

        if (!choice) {
            return;
        }

        let module: TableDetailModule | null = null;

        if (choice.type === TableDetailModuleType.Table as string) {

            const table = await this.modalService.openMedium(
                ContentSelectComponent,
                getContentSelectConfig(ContentSelectType.Table, this.ucProject, this.ucFormBucketClient),
            ) as TableInfo | undefined;

            if (!table) {
                return;
            }

            module = { type: TableDetailModuleType.Table, identifier: table.identifier };
        }

        if (module) {
            this.tableDetail?.modules.push(module);
            this.cModules.push(new UfControlGroup({ expanded: this.ufb.control(true) }));
            this.edited = true;
        }
    }

    protected moveModule(event: any) {
        this.edited = true;
        const moduleControl = this.cModules.at(event.from);

        this.cModules.removeAt(event.from);
        this.cModules.insert(event.to, moduleControl);
    }

    protected async removeModule(index: number) {
        if (!await this.dialogs.confirmDelete()) {
            return;
        }
        this.tableDetail?.modules.splice(index, 1);
        this.cModules.removeAt(index);
        this.edited = true;
    }

    protected syncFieldsLabel() {
        for (const field of this.fields) {
            field._label = this.getDisplayLabelValue(field.descriptor, field.dataProperty);
        }
    }

    protected onFieldChange() {
        this.syncFieldsLabel();
        this.edited = true;
    }

    private async setup() {

        this.table = this.tableComponent.info.table;
        this.availableVisibleFields = this.tableComponent.info.dataDescriptor.propertyDescriptors.filter((propertyDescriptor) => propertyDescriptor.asDisplay);
        this.availableHiddenFilters = this.tableComponent.info.dataDescriptor.propertyDescriptors.filter((propertyDescriptor) => propertyDescriptor.asStaticFilter);

        this.tableDetailTypeOptions = [
            { value: 'basic', name: this.table.sourceType === TableSourceType.Bucket ? 'Form' : this.table.sourceType === TableSourceType.Company ? 'Company' : 'User' },
            { value: 'details', name: 'Details Page' },
        ];

        try {
            if (this.table.id) {
                this.tableDetail = await this.ucProject.getTableDetail(this.table.id);
            }
        } catch (e) {
            // 404 undefined tableDetail
        } finally {
            this.fields = this.tableDetail?.fields.map((field) => this.getFieldInfo(field)) ?? [];
            this.tableDetailType = this.tableDetail == null ? 'basic' : 'details';
        }

        this.cRoot = this.ufb.group({ [ControlKeys.Type]: this.ufb.control(undefined) });

        this.subscriptions.add(this.cRoot.valueChanges.subscribe(() => {
            if (!this.cRoot.pristine) {
                this.edited = true;
            }
        }));

        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));

        this.updateControls();

        this.ready = true;
    }

    private updateControls() {

        if (this.tableDetail) {
            this.cRoot.setControl('label', new UfControl(ValidatorFunctions.required('A label is required')));
            this.cModules = new UfControlArray(this.tableDetail.modules.map(() => new UfControlGroup({ expanded: this.ufb.control(false) })));
            this.cRoot.setControl(ControlKeys.Modules, this.cModules);
            const templateValue = this.tableDetail.detailTemplate ?? TableDetailTemplate.PageView;
            const detailTemplateControl = this.ufb.control(templateValue);
            const emptyMessageControl = this.ufb.control({ value: this.tableDetail.emptyMessage, disabled: templateValue !== TableDetailTemplate.PageViewHideEmptyTables }, ValidatorFunctions.required('Empty message is required'));

            this.subscriptions.add(detailTemplateControl.valueChanges.subscribe((value: TableDetailTemplate) => {
                const emptyMessageValue = value === TableDetailTemplate.PageViewHideEmptyTables ? PageViewEmptyTablesMessage : undefined;

                if (this.tableDetail) {
                    this.tableDetail.emptyMessage = emptyMessageValue;
                }

                if (value === TableDetailTemplate.PageViewHideEmptyTables) {
                    emptyMessageControl.enable();
                } else {
                    emptyMessageControl.disable();
                }
            }));

            this.cRoot.setControl(ControlKeys.DetailTemplate, detailTemplateControl);
            this.cRoot.setControl(ControlKeys.EmptyMessage, emptyMessageControl);
        } else {
            this.cRoot.removeControl('label');
            this.cRoot.removeControl('modules');
        }
    }

    private getFieldInfo(field: TableFieldDescriptor): FieldInfo {

        if (field.type === 'Heading') {
            return {
                descriptor: field,
                _icon: 'content',
                _label: this.getDisplayLabelValue(field),
                _exists: true,
            };
        }

        const dataProperty = this.availableVisibleFields.find((item) => item.identifier === field.identifier);

        const showItemTemplate = dataProperty?.type === FieldType.Repeat;

        return {
            descriptor: field,
            dataProperty,
            _exists: dataProperty != null,
            _icon: dataProperty?.icon ?? 'query',
            _label: this.getDisplayLabelValue(field, dataProperty),
            _itemTemplate: showItemTemplate,
        };
    }

    private getDisplayLabelValue(descriptor: TableFieldDescriptor, propertyDescriptor?: DataPropertyDescriptor): string {
        if (descriptor.type === 'Heading') {
            return `Heading  ${descriptor.value as string | null ?? ''}`;
        }

        if (descriptor.label) {
            return `${descriptor.label} (${descriptor.identifier})`;
        }

        return propertyDescriptor?.display ?? descriptor.identifier;
    }

    private async save(saveOption?: SaveOption) {

        try {

            this.cRoot.updateValueAndValidity();
            this.cRoot.setSubmitted(true);

            if (this.cRoot.invalid) {
                return;
            }

            const tableId = this.table.id;

            if (!tableId) {
                throw new Error('Table has no id');
            }

            await this.saveOrRemoveTableDetail();

            if (saveOption?.id === SaveOptionType.Approve && isNumber(tableId)) {
                this.table = await this.ucProject.approveTable(tableId);
            }

            const updatedTable = await this.ucProject.getTable(tableId);

            this.tableManager.updateItem?.next(updatedTable as TableInfo);
            this.builderHeaderService.updateConfig(updatedTable);

            this.edited = false;
            this.toastService.success('Detail saved!');

            if (saveOption?.id === SaveOptionType.Close || saveOption?.id === SaveOptionType.Approve) {
                void this.router.navigate(['../../'], { relativeTo: this.route });
            }

        } catch (e) {
            this.toastService.error(`Error save: ${(e as Error).message}`);
        }
    }

    private async saveOrRemoveTableDetail() {
        if (!this.table.id) {
            return;
        }

        if (this.tableDetail != null) {
            const detailTemplate: `${TableDetailTemplate}` = this.cRoot.get(ControlKeys.DetailTemplate)?.value as TableDetailTemplate | undefined ?? TableDetailTemplate.PageView;

            this.tableDetail.detailTemplate = detailTemplate;
            this.tableDetail.fields = this.fields.map((field) => field.descriptor);
            this.tableDetail.modules = this.tableDetail.modules.map((module) => ({
                filter: module.filter,
                canAdd: module.canAdd,
                limit: detailTemplate === 'MenuView' ? undefined : module.limit,
                identifier: module.identifier,
                type: module.type,
                roles: module.roles,
                title: module.title,
            }));

            await this.ucProject.saveTableDetail(this.table.id, this.tableDetail);
        } else {
            await this.ucProject.deleteTableDetail(this.table.id);
        }
    }

}
