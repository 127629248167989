import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UfControl } from '@unifii/library/common';

@Component({
    selector: 'uc-step',
    templateUrl: './step.html',
    standalone: false
})
export class StepComponent implements OnChanges {

    @Input() value: number | undefined;
    @Input() control: UfControl;
    @Output() valueChange = new EventEmitter<number>();

    internalValue: number;
    class: string;

    constructor(el: ElementRef) {
        if (el.nativeElement.classList.contains('small')) {
            this.class = 'small';
        }

        if (el.nativeElement.classList.contains('large')) {
            this.class = 'large';
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.value) {
            if (!changes.value.currentValue) {
                this.internalValue = 1;
                this.valueChange.emit(60);

                return;
            }
            this.internalValue = changes.value.currentValue / 60;
        }
    }

    internalUpdate(value: number) {
        value = (value !== 0) ? value * 60 : 60;
        this.valueChange.emit(value);
    }

}
