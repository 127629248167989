import { Component, Input, OnInit } from '@angular/core';
import { UfControl, UfControlArray, UfControlGroup, UfFormControl } from '@unifii/library/common';
import { FieldType, isNotNull } from '@unifii/sdk';

import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys, VariationControlKeys } from '../form-editor-control-keys';
import { FormFieldMetadata } from '../form-editor-model';

@Component({
    selector: 'uc-form-field-variation',
    templateUrl: './form-field-variation.html',
    standalone: false
})
export class FormFieldVariationComponent implements OnInit {

    @Input({ required: true }) control: UfControlGroup;
    @Input({ required: true }) type: FieldType;
    @Input({ required: true }) id: UfControl;
    @Input({ required: true }) identifier: UfControl;
    @Input({ required: true }) template: UfControl;
    @Input({ required: true }) meta: FormFieldMetadata;

    protected readonly fieldTypes = FieldType;
    protected readonly variationKeys = VariationControlKeys;
    protected readonly identifierWarningLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH;
    protected readonly currencies = FORM_EDITOR_CONSTANTS.CURRENCIES;
    protected readonly controlsKeys = [
        FieldControlKeys.Label,
        FieldControlKeys.Help,
        FieldControlKeys.Placeholder,
    ];

    protected ready: boolean;

    private controls: UfFormControl[];

    get isInvalid(): boolean {
        return this.controls.find((c) => c.invalid) != null;
    }

    get options(): UfControlArray {
        return this.control.get(VariationControlKeys.Options) as UfControlArray;
    }

    get dataSourceConfig(): UfControl {
        return this.control.get(VariationControlKeys.DataSourceConfig) as UfControl;
    }

    get dataCaptures(): UfControl {
        return this.control.parent?.parent?.get(FieldControlKeys.DataCaptures) as UfControl;
    }

    get validators(): UfControlArray {
        return this.control.get(VariationControlKeys.Validators) as UfControlArray;
    }

    ngOnInit() {
        this.controls = this.controlsKeys.map((k) => this.control.get(k) as UfFormControl | null).filter(isNotNull);
        this.ready = true;
    }

}
