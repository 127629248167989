import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerComponent, TableContainerManager } from '@unifii/components';
import { FilterEntry, FilterValue, ModalService, ToastService } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { Integration, IntegrationFeature } from 'client';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { TitleService } from 'services/title.service';

import { IntegrationFeatureModalComponent } from './integration-feature-modal.component';
import { IntegrationFeaturesTableManager } from './integration-features-table-manager';
import { IntegrationComponent } from './integration.component';

@Component({
    selector: 'uc-integration-features-table',
    template: `
        <uf-table-container (addItem)="addFeature()" ufSyncRoute ufMasterDetail class="container accent" />
        <router-outlet />
    `,
    providers: [
        { provide: TableContainerManager, useClass: IntegrationFeaturesTableManager },
    ],
    standalone: false
})
export class IntegrationFeaturesComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild(TableContainerComponent, { static: true })
    tableContainerComponent: TableContainerComponent<IntegrationFeature, FilterValue, FilterEntry>;

    protected integration: Integration;

    private subscriptions = new Subscription();

    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);
    private manager = inject(TableContainerManager) as IntegrationFeaturesTableManager;
    private modalService = inject(ModalService);
    private parent = inject(IntegrationComponent);
    private toast = inject(ToastService);
    private titleService = inject(TitleService);

    ngOnInit() {
        this.integration = this.parent.integration;
        this.setup();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.parent.buildHeaderConfig();
    }

    ngAfterViewInit() {
        this.tableContainerComponent?.reload();
    }

    buildHeaderConfig() {
        const title = this.integration?.name ?? 'New';

        this.builderHeaderService.buildConfig({
            title,
            hideSaveButton: true,
            lastModifiedAt: this.integration.lastModifiedAt,
            lastModifiedBy: this.integration.lastModifiedBy,
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [title]),
        });

        this.titleService.updateTitle(`Integrations | ${this.integration.name} | Features`, true);
    }

    getFeature(id: string) {
        const integrationFeature = this.manager.integrationFeatures?.find((feature) => feature.id === id);

        return { integration: this.integration, integrationFeature };
    }

    protected async addFeature() {
        const integrationFeature = await this.modalService.openFit(IntegrationFeatureModalComponent);

        if (!integrationFeature) {
            return;
        }

        if (!this.integration.integrationFeatures) {
            this.integration.integrationFeatures = [];
        } else {
            if (this.integration.integrationFeatures.some((feature) => feature.id === integrationFeature.id)) {
                this.toast.error(`There's already a feature with this identifier`);

                return;
            }
        }

        void this.router.navigate([integrationFeature.id], { relativeTo: this.route, state: { integrationFeature } });
    }

    private setup() {
        this.buildHeaderConfig();
        if (this.integration.id) {
            const isHttpConnector = this.integration.provider.id === 'http';

            this.manager.integrationId = this.integration.id;
            this.manager.integrationFeatures = isHttpConnector ? this.integration.integrationFeatures ?? [] : this.integration.provider.features;
            this.manager.addActionConfig = isHttpConnector;
            if (!isHttpConnector) {
                this.manager.tableConfig.actions = undefined;
            }
        }

    }

}
