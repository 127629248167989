import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { FilterEntries } from '@unifii/library/common';

import { ActivityType, UcProject } from 'client';

import { ActivityTypeToken, WorkflowActivityTableManager } from './workflow-activity-table-manager';
import { activityFilterFactory } from './workflows-filters';

@Component({
    selector: 'uc-workflow-timers',
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="add()" ufSyncRoute class="accent pad-none list-md" />
        </div>
        <router-outlet />
    `,
    providers: [
        { provide: ActivityTypeToken, useValue: ActivityType.Timer },
        { provide: FilterEntries, useFactory: activityFilterFactory, deps: [UcProject] },
        { provide: TableContainerManager, useClass: WorkflowActivityTableManager },
    ],
    standalone: false
})
export class WorkflowTimersComponent {

    constructor(
        private router: Router,
        private route: ActivatedRoute,
    ) { }

    add() {
        this.router.navigate(['new'], { relativeTo: this.route });
    }

}
