import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setup, track } from 'observable-profiler';
import { Observable } from 'rxjs';

import { Config, ConsoleOptions } from 'app-config';
import { SubscriptionsTrackerService } from 'services/subscription-tracker.service';

import { AppModule } from './app/app.module';

import 'hammerjs';

void (async() => {
    let config: Config;
    const configValue = window.document.getElementById('env')?.innerHTML ?? '';

    try {
        config = JSON.parse(configValue);
    } catch (e) {
        const response = await fetch('environment.json');

        config = await response.json();
        // fix goLang variable for localhost
        window.document.title = config.env;
    }

    const isProduction = !['localhost', 'dev'].includes(config.env);

    // Retrieve it from origin if not specified in the config
    config.baseUrl = (config.baseUrl as string | undefined) ?? window.location.origin;

    // 8021 server connect to localhost unifii-api server
    if (config.env === 'localhost' && window.location.port === '8021') {
        config.baseUrl = 'http://localhost:5171';
    }

    if (isProduction) {
        enableProdMode();
    } else {
        setup(Observable);
    }

    platformBrowserDynamic([
        { provide: Config, useValue: new ConsoleOptions(config) },
    ]).bootstrapModule(AppModule)
    .then((module) => {
        if (!isProduction) {

            console.info(`You can use "_trackSubs()" to track unhandled subscriptions`);

            const subscriptionsTrackerService = module.injector.get(SubscriptionsTrackerService);

            // store a reference of AppModule for later use, see AppComponent
            subscriptionsTrackerService.appModuleRef = module;

            // Start tracking observables
            track();

            (window as any)._trackSubs = () => subscriptionsTrackerService.destroyAppModuleAndTrackObservableUnhandledSubscriptions();
        }
      })
      .catch((err) => console.error(err));
})();
