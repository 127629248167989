import { Component, Input, inject } from '@angular/core';
import { ExpandersService } from '@unifii/library/common';

@Component({
    selector: 'uc-expander-controls',
    templateUrl: './expander-controls.html',
    styleUrls: ['./expander-controls.less'],
    standalone: false
})
export class ExpanderControlsComponent {

    @Input() container: HTMLElement;
    @Input() className: string | string[];

    private expanderService = inject(ExpandersService, { optional: true });

    expand() {
        this.expanderService?.expandAll(this.container);
    }

    collapse() {
        this.expanderService?.collapseAll(this.container);
    }

}
