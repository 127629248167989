import { Component, Input, OnInit } from '@angular/core';
import { UfControlGroup, UfFormControl } from '@unifii/library/common';
import { LinkContentType, Option } from '@unifii/sdk';

import { ArrayHelper } from 'helpers/array-helper';
import { ContextService } from 'services/context.service';

import { FORM_EDITOR_CONSTANTS } from '../form-editor-constants';
import { FieldControlKeys } from '../form-editor-control-keys';
import { FormEditorFunctions } from '../form-editor-functions';
import { FormFieldMetadata } from '../form-editor-model';
import { FormEditorStatus } from '../form-editor-status';

enum DataCaptureType {
    Barcode = 'barcode',
    // NFC = 'nfc' // Adding soon
}

@Component({
    selector: 'uc-form-field-advanced',
    templateUrl: './form-field-advanced.html',
    standalone: false
})
export class FormFieldAdvancedComponent implements OnInit {

    @Input() control: UfControlGroup;

    protected readonly fieldKeys = FieldControlKeys;
    protected readonly dataCaptureTypes: Option[] = [
        { identifier: DataCaptureType.Barcode, name: 'Barcode' },
        // { identifier: DataCaptureType.NFC, name: 'NFC' } // TODO Adding Later
    ];
    protected readonly linkAllowedTypes: Option[] = [
        { identifier: LinkContentType.Page, name: 'Page' },
        // { identifier: LinkContentType.Asset, name: 'Asset' }, // TODO Add Later
        { identifier: LinkContentType.Attachment, name: 'Attachment' },
        { identifier: LinkContentType.Url, name: 'URL' },
        { identifier: LinkContentType.Form, name: 'Form' },
    ];

    protected meta: FormFieldMetadata;
    protected ready: boolean;
    protected tagsResult: string[];

    private controls: UfFormControl[];

    protected get isInvalid(): boolean {
        return this.controls.find((c) => c.invalid) != null;
    }

    constructor(
        private status: FormEditorStatus,
        private context: ContextService,
    ) { }

    ngOnInit() {
        this.meta = FormEditorFunctions.controlMetadata(this.control, this.context);
        this.controls = FORM_EDITOR_CONSTANTS.SECTION_ADVANCED_FIELDS.map((k) => this.control.get(k) as UfFormControl).filter((c) => c != null);
        this.ready = true;
    }

    protected searchTags(query: string | null) {
        this.tagsResult = ArrayHelper.filterList([...this.status.tags], query ?? undefined);
    }

}
