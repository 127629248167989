import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { Modal, ModalData, ModalRuntime, UfControl, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { CompoundType, Schema } from '@unifii/sdk';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { UcDefinition, UcFormBucketClient } from 'client';

import { FormEditorCache, FormEditorCacheService } from './form-editor-cache';
import { FORM_EDITOR_CONSTANTS } from './form-editor-constants';
import { DefinitionControlKeys } from './form-editor-control-keys';
import { FormEditorFieldScopeManager } from './form-editor-field-scope-manager';
import { FormEditorFormCtrl } from './form-editor-form-ctrl';
import { FormEditorFunctions } from './form-editor-functions';
import { FormEditorDefinition } from './form-editor-model';
import { FormEditorStatus } from './form-editor-status';
import { ConsoleNameLabel } from 'constant';

export interface FormMetadataModalData {
    definition?: UcDefinition;
}

@Component({
    templateUrl: './form-metadata-modal.html',
    selector: 'uc-form-metadata-modal',
    providers: [
        FormEditorStatus, FormEditorFieldScopeManager, FormEditorFormCtrl,
        { provide: FormEditorCache, useClass: FormEditorCacheService },
    ],
    standalone: false
})
export class FormMetadataModalComponent implements Modal<FormMetadataModalData, UcDefinition>, OnInit, OnDestroy {

    @HostBinding('class.uf-form-card') formDataClass = true;

    data = inject<FormMetadataModalData>(ModalData);
    runtime = inject<ModalRuntime<FormMetadataModalData, UcDefinition>>(ModalRuntime);

    protected readonly definitionKeys = DefinitionControlKeys;
    protected readonly identifierWarningLength = FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH;
    protected readonly consoleNameLabel = ConsoleNameLabel;
    protected ready: boolean;
    protected isFormSettingsMode: boolean;
    protected formGroup: UfControlGroup;
    protected bucketsResults: string[];
    protected subs = new Subscription();
    protected showIdentifierWarning: boolean;
    protected showBucketWarning: boolean;
    protected showRollingVersion = false;

    private fb = inject(UfFormBuilder);
    private formEditorCtrl = inject(FormEditorFormCtrl);
    private status = inject(FormEditorStatus);
    private ucFormBucketClient = inject(UcFormBucketClient);

    get identifierControl() {
        return this.formGroup.get(DefinitionControlKeys.Identifier) as UfControl;
    }

    get bucketControl() {
        return this.formGroup.get(DefinitionControlKeys.Bucket) as UfControl;
    }

    get hasRollingVersionControl() {
        return this.formGroup.get(DefinitionControlKeys.HasRollingVersion) as UfControl;
    }

    ngOnInit() {
        if (this.data.definition) {
            this.status.identifiersMaxLength = FormEditorFunctions.detectIdentifiersMaxLength(this.data.definition as unknown as FormEditorDefinition);
            this.configureFormDataRepository(this.data.definition);
            this.checkBucketWarning(this.bucketControl.value);
            void this.onBucketChanged(this.bucketControl.value, this.hasRollingVersionControl.value);
        } else {
            this.configureFormSettings();
        }

        this.subs.add(this.bucketControl.valueChanges.pipe(
            debounceTime(FORM_EDITOR_CONSTANTS.INPUT_DEBOUNCE_NETWORK_CALL)).subscribe((v) => {
                this.checkBucketWarning(v);
                void this.onBucketChanged(v);
            }));

        this.ready = true;
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    close() {
        this.runtime.close();
    }

    protected submit() {

        this.formGroup.setSubmitted();

        if (this.formGroup.invalid) {
            return;
        }

        const definition = Object.assign(this.emptyDefinition, this.formGroup.getRawValue());

        this.runtime.close(definition);
    }

    protected async searchBuckets(q: string) {
        this.bucketsResults = (await this.ucFormBucketClient.list({ params: { q } })).map((bucket) => bucket.id);
    }

    private async onBucketChanged(bucket?: string, nonBucketVersionRollingValue = false) {

        this.showRollingVersion = false;

        if (!bucket) {
            this.hasRollingVersionControl.setValue(false, { onlySelf: true, emitEvent: false });
            this.hasRollingVersionControl.disable();
            this.showRollingVersion = false;

            return;
        }

        let schema: Schema | null = null;

        try {
            schema = await this.ucFormBucketClient.get(bucket);
        } catch { /* */ }

        if (!schema) {
            this.hasRollingVersionControl.setValue(nonBucketVersionRollingValue, { onlySelf: true, emitEvent: false });
            this.hasRollingVersionControl.enable();
            this.showRollingVersion = true;
        } else {
            this.hasRollingVersionControl.setValue(schema.hasRollingVersion, { onlySelf: true, emitEvent: false });
            this.hasRollingVersionControl.disable();
            this.showRollingVersion = true;
        }
    }

    private configureFormSettings() {

        this.isFormSettingsMode = true;

        const lastPublishedAtControl = this.formEditorCtrl.buildDefinitionLastPublishedControl();
        const identifierControl = this.formEditorCtrl.buildDefinitionIdentifierControl(lastPublishedAtControl);
        const consoleNameControl = this.formEditorCtrl.buildDefinitionConsoleNameControl();
        const bucketControl = this.formEditorCtrl.buildDefinitionBucketControl(lastPublishedAtControl);

        this.formGroup = this.fb.group({
            [DefinitionControlKeys.Identifier]: identifierControl,
            [DefinitionControlKeys.ConsoleName]: consoleNameControl,
            [DefinitionControlKeys.SequenceNumberFormat]: null,
            [DefinitionControlKeys.Bucket]: bucketControl,
            [DefinitionControlKeys.HasRollingVersion]: this.formEditorCtrl.buildDefinitionHasRollingVersionControl(false),
            [DefinitionControlKeys.Label]: this.formEditorCtrl.buildDefinitionLabelControl(lastPublishedAtControl, identifierControl, bucketControl, consoleNameControl, undefined, true),
        });

        this.checkIdentifierWarning(identifierControl.value);
        this.subs.add(identifierControl.valueChanges.subscribe((v) => this.checkIdentifierWarning(v)));
    }

    private configureFormDataRepository(definition: UcDefinition) {

        const lastPublishedAtControl = this.formEditorCtrl.buildDefinitionLastPublishedControl(definition.lastPublishedAt);
        const bucketControl = this.formEditorCtrl.buildDefinitionBucketControl(lastPublishedAtControl, definition.bucket);
        const hasRollingVersionControl = this.formEditorCtrl.buildDefinitionHasRollingVersionControl(definition.hasRollingVersion);

        this.formGroup = this.fb.group({
            [DefinitionControlKeys.Bucket]: bucketControl,
            [DefinitionControlKeys.HasRollingVersion]: hasRollingVersionControl,
        });
    }

    private checkIdentifierWarning(identifier?: string) {
        this.showIdentifierWarning = (identifier ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH &&
            !this.identifierControl.showError;
    }

    private checkBucketWarning(bucket?: string) {
        this.showBucketWarning = (bucket ?? '').length > FORM_EDITOR_CONSTANTS.FIELD_IDENTIFIER_WARNING_LENGTH &&
            !this.bucketControl.showError;
    }

    private get emptyDefinition(): UcDefinition {
        return {
            compoundType: CompoundType.Form,
            label: '',
            identifier: '',
            bucket: '',
            fields: [],
            settings: {
                requiredSuffix: '*',
            },
            hasRollingVersion: false,
        };
    }

}
