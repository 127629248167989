"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.detectDataType = exports.castDataByDataType = exports.verifyDataType = exports.coerceDataToTarget = exports.fieldTypeToDataType = void 0;
const date_fns_1 = require("date-fns");
const date_fns_tz_1 = require("date-fns-tz");
const constants_1 = require("../constants");
const content_types_1 = require("../content-types");
const models_1 = require("../models");
const date_fns_functions_1 = require("./date-fns-functions");
const model_type_guard_1 = require("./model-type-guard");
const type_guard_functions_1 = require("./type-guard-functions");
const users_functions_1 = require("./users-functions");
/**
 * Return the DataType associated with the provided FieldType.
 * Null for those FieldType that are not associated with data output (Section, Group etc).
 */
// eslint-disable-next-line complexity
exports.fieldTypeToDataType = type => {
  switch (type) {
    case content_types_1.FieldType.Bool:
      return models_1.DataType.Boolean;
    case content_types_1.FieldType.Number:
      return models_1.DataType.Number;
    case content_types_1.FieldType.Text:
      return models_1.DataType.String;
    case content_types_1.FieldType.MultiText:
      return models_1.DataType.String;
    case content_types_1.FieldType.Date:
      return models_1.DataType.Date;
    case content_types_1.FieldType.Time:
      return models_1.DataType.Time;
    case content_types_1.FieldType.DateTime:
      return models_1.DataType.DateTime;
    case content_types_1.FieldType.ZonedDateTime:
      return models_1.DataType.ZonedDateTime;
    case content_types_1.FieldType.Phone:
      return models_1.DataType.Phone;
    case content_types_1.FieldType.Email:
      return models_1.DataType.Email;
    case content_types_1.FieldType.Website:
      return models_1.DataType.Website;
    case content_types_1.FieldType.Signature:
      return models_1.DataType.Signature;
    case content_types_1.FieldType.TextArray:
      return models_1.DataType.StringArray;
    case content_types_1.FieldType.Choice:
      return models_1.DataType.Choice;
    case content_types_1.FieldType.MultiChoice:
      return models_1.DataType.MultiChoice;
    case content_types_1.FieldType.Cost:
      return models_1.DataType.Cost;
    case content_types_1.FieldType.GeoLocation:
      return models_1.DataType.GeoLocation;
    case content_types_1.FieldType.Address:
      return models_1.DataType.Address;
    case content_types_1.FieldType.Lookup:
      return models_1.DataType.DataSeed;
    case content_types_1.FieldType.Link:
      return models_1.DataType.Link;
    case content_types_1.FieldType.Repeat:
      return models_1.DataType.Repeat;
    case content_types_1.FieldType.Hierarchy:
      return models_1.DataType.HierarchyUnit;
    case content_types_1.FieldType.FileList:
      return models_1.DataType.FileList;
    case content_types_1.FieldType.ImageList:
      return models_1.DataType.ImageList;
    case content_types_1.FieldType.SoundList:
      return models_1.DataType.SoundList;
    case content_types_1.FieldType.VideoList:
      return models_1.DataType.VideoList;
    default:
      return undefined;
  }
};
/**
 * Coerce a data to the target corresponding model value if compatible.
 * Return null for incompatible data/target scenario.
 */
// eslint-disable-next-line complexity
exports.coerceDataToTarget = (sourceData, targetDescriptor) => {
  if (sourceData == null) {
    return null;
  }
  const singleValue = extractFirstPrimitive(sourceData);
  const arrayValues = (!Array.isArray(sourceData) ? [sourceData] : sourceData).map(value => extractFirstPrimitive(value));
  switch (targetDescriptor.type) {
    case models_1.DataType.Number:
      return coerceDataToNumber(singleValue, targetDescriptor.precision);
    case models_1.DataType.Boolean:
      return coerceDataToBoolean(singleValue);
    case models_1.DataType.String:
      return coerceDataToString(singleValue);
    case models_1.DataType.Phone:
      return coerceDataToPhone(singleValue);
    case models_1.DataType.Email:
      return coerceDataToEmail(singleValue);
    case models_1.DataType.Website:
      return coerceDataToWebsite(singleValue);
    case models_1.DataType.Signature:
      return coerceDataToSignature(singleValue);
    case models_1.DataType.Date:
      return coerceDataToDate(singleValue);
    case models_1.DataType.Time:
      return coerceDataToTime(singleValue);
    case models_1.DataType.DateTime:
      return coerceDataToDateTime(singleValue);
    case models_1.DataType.OffsetDateTime:
      return coerceDataToOffsetDateTime(singleValue);
    case models_1.DataType.ZonedDateTime:
      return coerceDataToZonedDateTime(singleValue);
    case models_1.DataType.Cost:
      return coerceDataToCost(singleValue);
    case models_1.DataType.GeoLocation:
      return coerceDataToGeoLocation(singleValue);
    case models_1.DataType.Address:
      return coerceDataToAddress(singleValue);
    case models_1.DataType.Link:
      return coerceDataToLink(singleValue);
    case models_1.DataType.Claim:
      return coerceDataToClaim(singleValue);
    case models_1.DataType.ClaimsRecord:
      return coerceDataToClaimsRecord(singleValue, arrayValues);
    case models_1.DataType.HierarchyUnit:
      return coerceDataToHierarchyUnit(singleValue);
    case models_1.DataType.MultiChoice:
      return coerceDataToStringArray(arrayValues);
    case models_1.DataType.StringArray:
      return coerceDataToStringArray(arrayValues);
    case models_1.DataType.FileList:
    case models_1.DataType.ImageList:
    case models_1.DataType.SoundList:
    case models_1.DataType.VideoList:
      return coerceDataToFileDataArray(arrayValues);
    case models_1.DataType.HierarchyStep:
      return coerceDataToHierarchyStep(singleValue);
    case models_1.DataType.HierarchyPath:
      return coerceDataToHierarchyPath(arrayValues);
    case models_1.DataType.HierarchyPaths:
      return coerceDataToHierarchyPaths(arrayValues);
    case models_1.DataType.Repeat:
      return coerceDataToRepeat(arrayValues, targetDescriptor.children);
    case models_1.DataType.DataSeed:
      return coerceDataToDataSeed(singleValue, targetDescriptor.children);
    case models_1.DataType.Choice:
      return coerceDataToChoice(singleValue, targetDescriptor.children);
  }
};
/**
 * Verify if the data match the type
 * @param data value to check against the type
 * @param type expected type of the data
 * @returns true if data match the type
 */
// eslint-disable-next-line complexity
const verifyDataType = (data, type) => {
  switch (type) {
    case models_1.DataType.Number:
      return (0, type_guard_functions_1.isNumber)(data);
    case models_1.DataType.Boolean:
      return (0, type_guard_functions_1.isBoolean)(data);
    case models_1.DataType.String:
      return (0, type_guard_functions_1.isString)(data);
    case models_1.DataType.Phone:
      return (0, model_type_guard_1.isPhone)(data);
    case models_1.DataType.Email:
      return (0, model_type_guard_1.isEmail)(data);
    case models_1.DataType.Website:
      return (0, model_type_guard_1.isWebsite)(data);
    case models_1.DataType.Signature:
      return (0, model_type_guard_1.isSignature)(data);
    case models_1.DataType.Date:
      return (0, model_type_guard_1.isDate)(data);
    case models_1.DataType.Time:
      return (0, model_type_guard_1.isTime)(data);
    case models_1.DataType.DateTime:
      return (0, model_type_guard_1.isDateTime)(data);
    case models_1.DataType.OffsetDateTime:
      return (0, model_type_guard_1.isOffsetDateTime)(data);
    case models_1.DataType.ZonedDateTime:
      return (0, model_type_guard_1.isZonedDateTime)(data);
    case models_1.DataType.Choice:
      return (0, model_type_guard_1.isChoice)(data);
    case models_1.DataType.MultiChoice:
      return (0, type_guard_functions_1.isArrayOfType)(data, type_guard_functions_1.isStringNotEmpty);
    case models_1.DataType.Cost:
      return (0, model_type_guard_1.isCostModelFormat)(data);
    case models_1.DataType.GeoLocation:
      return (0, model_type_guard_1.isGeoLocation)(data);
    case models_1.DataType.Address:
      return (0, model_type_guard_1.isAddress)(data);
    case models_1.DataType.Link:
      return (0, model_type_guard_1.isContentLinkFormData)(data);
    case models_1.DataType.CompanyInfo:
      return (0, model_type_guard_1.isCompanyInfo)(data);
    case models_1.DataType.Manager:
      return (0, model_type_guard_1.isManager)(data);
    case models_1.DataType.HierarchyStep:
      return (0, model_type_guard_1.isHierarchyStep)(data);
    case models_1.DataType.HierarchyUnit:
      return (0, model_type_guard_1.isHierarchyUnitFormData)(data);
    case models_1.DataType.HierarchyPath:
      return (0, model_type_guard_1.isHierarchyPath)(data);
    case models_1.DataType.HierarchyPaths:
      return (0, model_type_guard_1.isHierarchyUnitsPath)(data);
    case models_1.DataType.DataSeed:
      return (0, model_type_guard_1.isDataSeed)(data);
    case models_1.DataType.StringArray:
      return (0, model_type_guard_1.isStringArray)(data);
    case models_1.DataType.Repeat:
      return (0, model_type_guard_1.isRepeat)(data);
    case models_1.DataType.FileList:
    case models_1.DataType.ImageList:
    case models_1.DataType.SoundList:
    case models_1.DataType.VideoList:
      return (0, type_guard_functions_1.isArrayOfType)(data, model_type_guard_1.isFileData);
    case models_1.DataType.Claim:
      return (0, model_type_guard_1.isClaim)(data);
    case models_1.DataType.ClaimsRecord:
      return (0, model_type_guard_1.isClaimsRecord)(data);
  }
};
exports.verifyDataType = verifyDataType;
/**
 * !!WARNING!! - Type unsafe cast utility to be used with precaution.
 * Return the data casted to the associate dataType's model type
 * @deprecated
 * @param data value to be casted
 * @param dataType determines the result model type
 */
// eslint-disable-next-line complexity
exports.castDataByDataType = (data, dataType) => {
  switch (dataType) {
    case models_1.DataType.Number:
      return data;
    case models_1.DataType.Boolean:
      return data;
    case models_1.DataType.String:
    case models_1.DataType.Phone:
    case models_1.DataType.Email:
    case models_1.DataType.Website:
    case models_1.DataType.Date:
    case models_1.DataType.Time:
    case models_1.DataType.DateTime:
    case models_1.DataType.OffsetDateTime:
    case models_1.DataType.Signature:
      return data;
    case models_1.DataType.ZonedDateTime:
      return data;
    case models_1.DataType.Choice:
      return data;
    case models_1.DataType.StringArray:
    case models_1.DataType.MultiChoice:
      return data;
    case models_1.DataType.Cost:
      return data;
    case models_1.DataType.GeoLocation:
      return data;
    case models_1.DataType.Address:
      return data;
    case models_1.DataType.Link:
      return data;
    case models_1.DataType.CompanyInfo:
      return data;
    case models_1.DataType.Manager:
      return data;
    case models_1.DataType.HierarchyStep:
      return data;
    case models_1.DataType.HierarchyUnit:
      return data;
    case models_1.DataType.HierarchyPath:
      return data;
    case models_1.DataType.HierarchyPaths:
      return data;
    case models_1.DataType.DataSeed:
      return data;
    case models_1.DataType.Repeat:
      return data;
    case models_1.DataType.FileList:
    case models_1.DataType.ImageList:
    case models_1.DataType.SoundList:
    case models_1.DataType.VideoList:
      return data;
    case models_1.DataType.Claim:
      return data;
    case models_1.DataType.ClaimsRecord:
      return data;
  }
};
/**
 * Detect the DataType for the provided data.
 * When multiple DataTypes are compatible then the picked one is the more generic match, no bias applied. (ex: StringArray over MultiChoice)
 * @param data - value to analyze
 * @returns 'data' as casted type and associated 'type' as DataType
 */
const detectDataType = data => {
  if (data == null) {
    return {
      type: undefined,
      data
    };
  }
  if (Array.isArray(data)) {
    return detectArrayDataType(data);
  }
  if ((0, type_guard_functions_1.isDictionary)(data)) {
    return detectObjectDataType(data);
  }
  if ((0, type_guard_functions_1.isString)(data)) {
    return detectStringDataType(data);
  }
  if ((0, type_guard_functions_1.isBoolean)(data)) {
    return {
      type: models_1.DataType.Boolean,
      data
    };
  }
  if ((0, type_guard_functions_1.isNumber)(data)) {
    return {
      type: models_1.DataType.Number,
      data
    };
  }
  return {
    type: undefined,
    data
  };
};
exports.detectDataType = detectDataType;
const detectArrayDataType = data => {
  if ((0, type_guard_functions_1.isArrayOfType)(data, type_guard_functions_1.isString)) {
    return {
      type: models_1.DataType.StringArray,
      data
    };
  }
  if ((0, model_type_guard_1.isHierarchyPath)(data)) {
    return {
      type: models_1.DataType.HierarchyPath,
      data
    };
  }
  if ((0, model_type_guard_1.isHierarchyUnitsPath)(data)) {
    return {
      type: models_1.DataType.HierarchyPaths,
      data
    };
  }
  if ((0, type_guard_functions_1.isArrayOfType)(data, model_type_guard_1.isFileData)) {
    return {
      type: models_1.DataType.FileList,
      data
    };
  }
  if ((0, type_guard_functions_1.isArrayOfType)(data, type_guard_functions_1.isDictionary)) {
    return {
      type: models_1.DataType.Repeat,
      data
    };
  }
  return {
    type: undefined,
    data
  };
};
const detectObjectDataType = data => {
  if ((0, model_type_guard_1.isZonedDateTime)(data)) {
    return {
      type: models_1.DataType.ZonedDateTime,
      data
    };
  }
  if ((0, model_type_guard_1.isCostModelFormat)(data)) {
    return {
      type: models_1.DataType.Cost,
      data
    };
  }
  if ((0, model_type_guard_1.isContentLinkFormData)(data)) {
    return {
      type: models_1.DataType.Link,
      data
    };
  }
  if ((0, model_type_guard_1.isHierarchyUnitFormData)(data)) {
    return {
      type: models_1.DataType.HierarchyUnit,
      data
    };
  }
  if ((0, model_type_guard_1.isHierarchyStep)(data)) {
    return {
      type: models_1.DataType.HierarchyStep,
      data
    };
  }
  if ((0, model_type_guard_1.isDataSeed)(data)) {
    return {
      type: models_1.DataType.DataSeed,
      data
    };
  }
  if ((0, model_type_guard_1.isGeoLocation)(data)) {
    return {
      type: models_1.DataType.GeoLocation,
      data
    };
  }
  if ((0, model_type_guard_1.isAddress)(data)) {
    return {
      type: models_1.DataType.Address,
      data
    };
  }
  if ((0, model_type_guard_1.isManager)(data)) {
    return {
      type: models_1.DataType.Manager,
      data
    };
  }
  if ((0, model_type_guard_1.isCompanyInfo)(data)) {
    return {
      type: models_1.DataType.CompanyInfo,
      data
    };
  }
  return {
    type: undefined,
    data
  };
};
const detectStringDataType = data => {
  if ((0, model_type_guard_1.isOffsetDateTime)(data)) {
    return {
      type: models_1.DataType.OffsetDateTime,
      data
    };
  }
  if ((0, model_type_guard_1.isDateTime)(data)) {
    return {
      type: models_1.DataType.DateTime,
      data
    };
  }
  if ((0, model_type_guard_1.isTime)(data)) {
    return {
      type: models_1.DataType.Time,
      data
    };
  }
  if ((0, model_type_guard_1.isDate)(data)) {
    return {
      type: models_1.DataType.Date,
      data
    };
  }
  if ((0, model_type_guard_1.isSignature)(data)) {
    return {
      type: models_1.DataType.Signature,
      data
    };
  }
  if ((0, model_type_guard_1.isPhone)(data)) {
    return {
      type: models_1.DataType.Phone,
      data
    };
  }
  if ((0, model_type_guard_1.isEmail)(data)) {
    return {
      type: models_1.DataType.Email,
      data
    };
  }
  if ((0, model_type_guard_1.isWebsite)(data)) {
    return {
      type: models_1.DataType.Website,
      data
    };
  }
  return {
    type: models_1.DataType.String,
    data
  };
};
const extractFirstPrimitive = data => {
  // Recursive using first value of the Array
  if (Array.isArray(data)) {
    return extractFirstPrimitive(data[0]);
  }
  // Primitive value
  return (0, type_guard_functions_1.isString)(data) || (0, type_guard_functions_1.isNumber)(data) || (0, type_guard_functions_1.isBoolean)(data) || (0, type_guard_functions_1.isDictionary)(data) ? data : null;
};
const coerceDataToNumber = (data, precision) => {
  if (!(0, type_guard_functions_1.isString)(data) && !(0, type_guard_functions_1.isNumber)(data)) {
    return null;
  }
  const numericValue = (0, type_guard_functions_1.isString)(data) ? parseFloat(data) : data;
  if (isNaN(numericValue)) {
    return null;
  }
  return precision != null ? +numericValue.toFixed(precision) : numericValue;
};
const coerceDataToBoolean = data => {
  if ((0, type_guard_functions_1.isBoolean)(data)) {
    return data;
  }
  if (!(0, type_guard_functions_1.isString)(data)) {
    return null;
  }
  const cleanData = data.trim().toLowerCase();
  return ['true', 'false'].includes(cleanData) ? cleanData === 'true' : null;
};
const coerceDataToString = data => {
  if ((0, type_guard_functions_1.isString)(data) || (0, type_guard_functions_1.isNumber)(data)) {
    return `${data}`;
  }
  return null;
};
const coerceDataToPhone = data => {
  const phone = coerceDataToString(data);
  // skip phone validation to allow for non compliant string values 
  return (0, type_guard_functions_1.isString)(phone) /* isPhone(phone) */ ? phone : null;
};
const coerceDataToEmail = data => {
  const email = coerceDataToString(data);
  // skip email validation to allow for non compliant string values 
  return (0, type_guard_functions_1.isString)(email) /* isEmail(email) */ ? email : null;
};
const coerceDataToWebsite = data => {
  const website = coerceDataToString(data);
  // skip website validation to allow for non compliant string values 
  return (0, type_guard_functions_1.isString)(website) /* isWebsite(website) */ ? website : null;
};
const coerceDataToSignature = data => {
  const value = coerceDataToString(data);
  return (0, model_type_guard_1.isSignature)(value) ? value : null;
};
const coerceDataToDate = data => {
  if (!(0, type_guard_functions_1.isStringTrimmedNotEmpty)(data)) {
    return null;
  }
  const date = (0, date_fns_functions_1.parseFallbackISO)(data.trim(), constants_1.DATE_DATA_FORMAT);
  return date ? (0, date_fns_1.format)(date, constants_1.DATE_DATA_FORMAT) : null;
};
const coerceDataToTime = data => {
  if (!(0, type_guard_functions_1.isStringTrimmedNotEmpty)(data)) {
    return null;
  }
  const date = (0, date_fns_functions_1.parseFallbackISO)(data.trim(), constants_1.TIME_DATA_FORMAT);
  return date ? (0, date_fns_1.format)(date, constants_1.TIME_DATA_FORMAT) : null;
};
const coerceDataToDateTime = data => {
  if (!(0, type_guard_functions_1.isStringTrimmedNotEmpty)(data)) {
    return null;
  }
  const date = (0, date_fns_functions_1.parseFallbackISO)(data.trim(), constants_1.DATE_TIME_DATA_FORMAT);
  return date ? (0, date_fns_1.format)(date, constants_1.DATE_TIME_DATA_FORMAT) : null;
};
const coerceDataToOffsetDateTime = data => {
  if (!(0, type_guard_functions_1.isStringTrimmedNotEmpty)(data)) {
    return null;
  }
  // Use ISO format to parse to allow milliseconds flexibility
  const date = (0, date_fns_functions_1.parseFallbackISO)(data.trim(), undefined);
  return date ? (0, date_fns_1.format)(date, constants_1.OFFSET_DATE_TIME_DATA_DISPLAY_FORMAT) : null;
};
const coerceDataToZonedDateTime = data => {
  // Retrieve `dateTimeValue` and `tz` based on expected valid range of input
  const dateTimeValue = (0, type_guard_functions_1.isDictionary)(data) ? data.value : data;
  const tz = (0, type_guard_functions_1.isDictionary)(data) ? data.tz : (0, date_fns_functions_1.getLocalTimeZone)();
  // Guard from invalid values of `tz` and `dateString`
  if (!(0, type_guard_functions_1.isStringNotEmpty)(tz) || !(0, type_guard_functions_1.isStringNotEmpty)(dateTimeValue) || (0, date_fns_tz_1.fromZonedTime)(dateTimeValue, tz).toString() === 'Invalid Date') {
    return null;
  }
  // Type of parse for `dateString` depends of input been with a tz or a direct string value
  const date = (0, type_guard_functions_1.isString)(data) ? new Date(dateTimeValue) : (0, date_fns_tz_1.fromZonedTime)(dateTimeValue, tz);
  return {
    // TODO replace string.replace and find better conversion function
    value: date.toISOString().replace('Z', '+00:00'),
    tz
  };
};
const coerceDataToCost = data => (0, model_type_guard_1.isCostModelFormat)(data) ? data : null;
const coerceDataToGeoLocation = data => (0, model_type_guard_1.isGeoLocation)(data) ? data : null;
const coerceDataToAddress = data => (0, model_type_guard_1.isAddress)(data) ? data : null;
const coerceDataToLink = data => (0, model_type_guard_1.isContentLinkFormData)(data) ? data : null;
const coerceDataToClaim = data => (0, model_type_guard_1.isClaim)(data) ? data : null;
const coerceDataToClaimsRecord = (single, array) => {
  if ((0, model_type_guard_1.isClaimsRecord)(single)) {
    return single;
  }
  if ((0, type_guard_functions_1.isArrayOfType)(array, model_type_guard_1.isClaim)) {
    return (0, users_functions_1.mapClaimsToClaimsRecord)(array);
  }
  return null;
};
const coerceDataToHierarchyUnit = data => (0, model_type_guard_1.isHierarchyUnitFormData)(data) ? data : null;
const coerceDataToHierarchyStep = data => (0, model_type_guard_1.isHierarchyStep)(data) ? data : null;
const coerceDataToHierarchyPath = data => (0, model_type_guard_1.isHierarchyPath)(data) ? data : null;
const coerceDataToHierarchyPaths = data => (0, model_type_guard_1.isHierarchyUnitsPath)(data) ? data : null;
const coerceDataToStringArray = data => (0, type_guard_functions_1.isArrayOfType)(data, type_guard_functions_1.isString) && (0, type_guard_functions_1.hasLengthAtLeast)(data, 1) ? data : null;
const coerceDataToFileDataArray = data => (0, type_guard_functions_1.isArrayOfType)(data, model_type_guard_1.isFileData) && (0, type_guard_functions_1.hasLengthAtLeast)(data, 1) ? data : null;
const coerceDataToRecord = (data, children) => {
  if (!(0, type_guard_functions_1.isDictionary)(data)) {
    return null;
  }
  const record = {};
  for (const [key, value] of Object.entries(data)) {
    const childDescriptor = children[key];
    if (value == null || !childDescriptor) {
      continue;
    }
    const coercedValue = (0, exports.coerceDataToTarget)(value, childDescriptor);
    if (coercedValue != null) {
      record[key] = coercedValue;
    }
  }
  return record;
};
const coerceDataToRepeat = (data, children) => data.map(d => coerceDataToRecord(d, children)).filter(type_guard_functions_1.isNotNull);
const coerceDataToDataSeed = (data, children) => {
  const record = coerceDataToRecord(data, children);
  return (0, model_type_guard_1.isDataSeed)(record) ? record : null;
};
const coerceDataToChoice = (data, children) => children ? coerceDataToDataSeed(data, children) : coerceDataToString(data);
