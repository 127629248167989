import { Component, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';
import { Device, ErrorType, UfError, isString } from '@unifii/sdk';

import { UcApps } from 'client';
import { useDefaultErrorMessage } from 'components';
import { BreadcrumbService } from 'services/breadcrumb.service';

import { AppDetailsComponent } from './app-details.component';

@Component({
    selector: 'uc-device-detail',
    templateUrl: './device-detail.html',
    standalone: false
})
export class DeviceDetailComponent implements OnInit {

    protected error: UfError | undefined;
    protected device: Device | undefined;
    protected loading = true;
    protected breadcrumbs: Breadcrumb[] = [];

    private route = inject(ActivatedRoute);
    private ucApps = inject(UcApps);
    private appDetails = inject(AppDetailsComponent);
    private breadcrumbService = inject(BreadcrumbService);

    async ngOnInit() {
        const deviceId = this.route.snapshot.params.id;

        if (!isString(deviceId)) {
            throw new UfError('Device not found', ErrorType.NotFound);
        }

        this.loading = true;
        try {
            this.device = (await this.ucApps.getDevices(this.appDetails.id)).find((device) => device.id === deviceId);
            if (!this.device) {
                throw new UfError('Device not found', ErrorType.NotFound);
            }
        } catch (e) {
            this.error = useDefaultErrorMessage(e);

            return;
        } finally {
            this.loading = false;
        }

        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.appDetails.name, this.device.name ?? '']);
    }

}
