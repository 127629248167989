import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ClipboardService } from '@unifii/library/common';
import { Subscription } from 'rxjs';

import { IconsConsoleComponent } from './icons-console';
import { IconsLibraryComponent } from './icons-library.component';

interface Icon {
    name: string;
    node: SVGSymbolElement;
    duplicated?: boolean;
    overridden?: boolean;
}

interface IconsInfo {
    expected: number;
    icons: Icon[];
}

@Component({
    templateUrl: './icons.html',
    styleUrls: ['./icons.less'],
    standalone: false
})
export class IconsComponent implements AfterViewInit, OnDestroy {

    @HostBinding('class.stretch-component') class = true;

    @ViewChild(IconsLibraryComponent, { read: ElementRef, static: true }) libIconsRaw: ElementRef;
    @ViewChildren('libs') libWrappers: QueryList<ElementRef>;

    @ViewChild(IconsConsoleComponent, { read: ElementRef, static: true }) consoleIconsRaw: ElementRef;
    @ViewChildren('consoles') consoleWrappers: QueryList<ElementRef>;

    libraryInfo: IconsInfo;
    libraryIcons: Icon[];
    consoleInfo: IconsInfo;
    consoleIcons: Icon[];

    selected: string | null;

    private subscriptions = new Subscription();

    constructor(
        private clipboard: ClipboardService,
    ) { }

    ngAfterViewInit() {

        this.libraryInfo = this.parseIcons(this.libIconsRaw.nativeElement.innerHTML);
        this.consoleInfo = this.parseIcons(this.consoleIconsRaw.nativeElement.innerHTML);

        this.libraryInfo.icons.forEach((icon) => {
            icon.overridden = this.consoleInfo.icons.find((i) => i.name === icon.name) != null;
        });

        // const doc = this.loadXMLDoc(this.libIconsRaw.nativeElement.innerHTML);
        // console.log(doc);

        this.subscriptions.add(this.libWrappers.changes.subscribe(() => {
            this.libWrappers.forEach((e, i) => {
                const svg = `<svg viewBox="0 0 28 28">${this.libraryIcons[i]?.node.innerHTML}</svg>`;

                e.nativeElement.innerHTML = svg;
            });
        }));

        this.subscriptions.add(this.consoleWrappers.changes.subscribe(() => {
            this.consoleWrappers.forEach((e, i) => {
                const svg = `<svg viewBox="0 0 28 28">${this.consoleIcons[i]?.node.innerHTML}</svg>`;

                e.nativeElement.innerHTML = svg;
            });
        }));

        setTimeout(() => {
            this.search();
        }, 100);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    search(q?: string | null) {
        this.selected = null;
        this.libraryIcons = q === '' ? this.libraryInfo.icons : this.libraryInfo.icons.filter((i) => i.name.includes(q ?? ''));
        this.consoleIcons = q === '' ? this.consoleInfo.icons : this.consoleInfo.icons.filter((i) => i.name.includes(q ?? ''));
    }

    selectIcon(name: string) {
        this.selected = name;
        this.clipboard.setText(name);
    }

    private parseIcons(raw: any): IconsInfo {

        const result: IconsInfo = { expected: 0, icons: [] };
        const doc = this.loadXMLDoc(raw);

        result.expected = ((raw.match(/symbol/g) || []).length / 2);

        if (!doc) {
            return result;
        }

        const icons = doc.getElementsByTagName('symbol');

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < icons.length; i++) {
            const node = icons[i];
            
            if (!node) {
                continue;
            }

            const name = node.id as string;

            // Add icon
            result.icons.push({ name, node });

            // Flag duplicates
            if (result.icons.find((icon) => icon.name === name) != null) {
                const similars = result.icons.filter((icon) => icon.name === name);

                if (similars.length > 1) {
                    similars.forEach((icon) => { icon.duplicated = true; });
                }
            }

        }
        // Sort icons
        result.icons = result.icons.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

        return result;
    }

    private loadXMLDoc(xml: string) {

        if ((window as any).DOMParser) {
            const parser = new DOMParser();

            return parser.parseFromString(xml, 'text/xml');
        } else {
            console.error('IconsComponent not supported in IE');

            return;
        }
    }

}
