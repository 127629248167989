import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService, UfControlGroup } from '@unifii/library/common';
import { AuthProvider, UfError, isUfError } from '@unifii/sdk';
import { Subscription } from 'rxjs';

import { AuthProviderDetails, NewAuthProviderDetails, UcAuthProviders } from 'client';
import { EditData, SaveAndClose, SaveOption, SaveOptionType } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { DialogsService } from 'services/dialogs.service';
import { TenantSettingsService } from 'services/tenant-settings.service';
import { TitleService } from 'services/title.service';

import { AuthProvidersComponent } from './auth-providers.component';
import { AuthProviderDetailController } from './detail/auth-provider-detail.controller';
import { AuthProviderMappingsController } from './mappings/auth-provider-mapping.controller';
import { AuthProviderMappingsComponent } from './mappings/auth-provider-mappings.component';
import { DetailsControlKeys, MappingConfig } from './models';
import { manualAuthProviderResolver } from './resolvers/manual-auth-provider-resolver';

@Component({
    templateUrl: './auth-provider.html',
    styleUrls: ['./auth-provider.less'],
    standalone: false
})
export class AuthProviderComponent implements OnInit, OnDestroy, EditData {

    @ViewChild(AuthProviderMappingsComponent, { static: false }) mappingsComponent: AuthProviderMappingsComponent;

    protected readonly controlKeys = DetailsControlKeys;

    protected error?: UfError;
    protected authProvider: AuthProviderDetails | NewAuthProviderDetails;
    protected form: UfControlGroup;
    protected mappingsControl: UfControlGroup;
    // configuration used for mappings
    protected config: MappingConfig;

    private subscriptions = new Subscription();
    private router = inject(Router);
    private route = inject(ActivatedRoute);
    private ucAuthProviders = inject(UcAuthProviders);
    private builderHeaderService = inject(BuilderHeaderService);
    private breadcrumbService = inject(BreadcrumbService);
    private authProviderController = inject(AuthProviderDetailController);
    private mappingsController = inject(AuthProviderMappingsController);
    private dialogs = inject(DialogsService);
    private parent = inject(AuthProvidersComponent);
    private toastService = inject(ToastService);
    private tenantSettings = inject(TenantSettingsService);
    private titleService = inject(TitleService);

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    get edited() {
        return !!this.builderHeaderService.config.edited;
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    async ngOnInit() {
        const authProviderData = this.route.snapshot.data.authProviderData as Awaited<ReturnType<typeof manualAuthProviderResolver>>;

        if (isUfError(authProviderData)) {
            this.error = authProviderData;

            return;
        }

        if (!authProviderData.authProvider) {
            this.builderHeaderService.config.hideSaveButton = true;

            return;
        }

        this.authProvider = authProviderData.authProvider;
        this.config = await this.buildMappingConfig();
        this.titleService.updateTitle(`Identity | ${this.authProvider.providerLoginLabel ?? this.authProvider.tenant}`, true);

        await this.setupMappings();

        this.form = this.authProviderController.buildRoot(this.authProvider);

        this.setupHeader();

        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => { void this.save(saveOption); }));
        this.subscriptions.add(this.form.statusChanges.subscribe(() => (this.edited = true)));

        if (this.mappingsControl) {
            this.subscriptions.add(this.mappingsControl.valueChanges.subscribe(() => (this.edited = true)));
        }
    }

    protected async delete() {
        if (!await this.dialogs.confirmSSODisconnect() || !this.providerId) {
            return;
        }

        await this.ucAuthProviders.delete(this.providerId);

        this.markAsPristine();

        this.toastService.success('Disconnected');

        this.parent.refresh();

        void this.router.navigate(['../../'], { relativeTo: this.route });
    }

    protected activate() {
        void this.changeActivationStatus(true);
    }

    protected deactivate() {
        void this.changeActivationStatus(false);
    }

    private async changeActivationStatus(active: boolean) {
        if (this.providerId == null) {
            return;
        }

        try {
            await this.ucAuthProviders.changeActivationStatus(this.providerId, active);

            this.form.get(DetailsControlKeys.IsActive)?.setValue(active);

            this.toastService.success(`Provider ${active ? 'activated' : 'deactivated'} successfully`);
        } catch (e) {
            this.toastService.error(`Error ${active ? 'activating' : 'deactivating'} provider`);
        }
    }

    private async save(saveOption?: SaveOption) {
        this.form.setSubmitted();
        this.mappingsControl?.setSubmitted();

        if (this.form.invalid || this.mappingsControl?.invalid === true) {
            return;
        }

        try {
            const identity = this.form.getRawValue();

            // needs to be updated with mappings controller
            if (this.providerId != null) {
                const { mappings, userFieldsMapping } = this.mappingsController.toFormData(this.mappingsControl.getRawValue());

                identity.mappings = mappings;
                identity.userFieldsMapping = userFieldsMapping;
            }

            this.authProvider = await this.ucAuthProviders.save(identity);

            this.toastService.success('Provider saved successfully');

            this.markAsPristine();

            this.parent.refresh();

            if (saveOption?.id === SaveOptionType.Close) {
                this.router.navigate(['../../'], { relativeTo: this.route });

                return;
            }

            this.router.navigate(['../', this.providerId], { relativeTo: this.route });

            this.setupHeader();
        } catch (e) {
            this.toastService.error('Error saving provider');
        }
    }

    private async setupMappings() {
        if (this.providerId == null) {
            return;
        }

        const mapping = await this.mappingsController.toFormModel(this.authProvider as AuthProviderDetails, this.config);

        this.mappingsControl = this.mappingsController.buildRoot(mapping, this.config);
    }

    private setupHeader(disableSave?: boolean) {

        this.builderHeaderService.init();
        this.builderHeaderService.buildConfig({
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [this.authProvider?.providerLoginLabel ?? this.authProvider.type]),
            cancelRoute: ['../..'],
            saveOptions: [SaveAndClose],
            disabledSave: disableSave,
            lastModifiedAt: this.authProvider.lastModifiedAt,
            lastModifiedBy: this.authProvider.lastModifiedBy,
        });
    }

    private async buildMappingConfig(): Promise<MappingConfig> {
        const { isUserEmailRequired } = await this.tenantSettings.sync();

        const config = {
            hasManager: true,
            sourceGroups: true,
            sourceRoles: false,
            sourceClaims: false,
            isUserEmailRequired,
        };

        if ((this.authProvider.type === AuthProvider.Azure && this.authProvider?.manual === false) || this.authProvider.type === AuthProvider.UnifiiIdentity) {
            config.hasManager = false;
        }

        if (this.authProvider.type === AuthProvider.Auth0) {
            config.sourceGroups = false;
            config.sourceRoles = true;
        }

        if (this.authProvider.type === AuthProvider.UnifiiIdentity) {
            config.sourceGroups = false;
        }

        if (this.authProvider.type === AuthProvider.Azure) {
            config.sourceClaims = true;
        }

        return config;
    }

    private markAsPristine() {
        this.form.markAsPristine();
        this.mappingsControl?.markAsPristine();

        this.edited = false;
        this.builderHeaderService.config.edited = false;
    }

    private get providerId(): string | null {
        return (this.authProvider as AuthProviderDetails)?.id ?? null;
    }

}
