import { Component, Input } from '@angular/core';
import { UfControlArray } from '@unifii/library/common';

import { DataSourceInputValueSource, IntegrationFeatureArgType } from 'client';

import { DataSourceArgControlKeys } from '../../model';

type SourceTypeInfo = DataSourceInputValueSource.Form | DataSourceInputValueSource.Unset;

@Component({
    selector: 'uc-data-feature-parameters',
    templateUrl: './data-feature-parameters.html',
    standalone: false
})
export class DataFeatureParametersComponent {

    @Input({ required: true }) inputArgsControl: UfControlArray;

    protected readonly argControlKeys = DataSourceArgControlKeys;
    protected readonly sourceTypes = [
        DataSourceInputValueSource.Form,
        DataSourceInputValueSource.Constant,
        DataSourceInputValueSource.Default,
        DataSourceInputValueSource.Unset,
    ];
    protected readonly filteredSourceTypes = this.sourceTypes.filter((type) => type !== DataSourceInputValueSource.Unset);
    protected readonly integrationFeatureTypes = IntegrationFeatureArgType;
    protected readonly sourceTypesInfo: Record<SourceTypeInfo, string> = {
        [DataSourceInputValueSource.Form]: 'Configured in Form',
        [DataSourceInputValueSource.Unset]: 'No value needed',
    };

    protected asSourceTypeInfo(source: string): source is SourceTypeInfo {
        return [DataSourceInputValueSource.Form, DataSourceInputValueSource.Unset].includes(source as DataSourceInputValueSource);
    }

}
