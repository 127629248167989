import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';
import { ExpandersService, MessageLevel, ModalService, RuntimePage, RuntimePageAdapter, ToastService } from '@unifii/library/common';
import { DisplayService } from '@unifii/library/smart-forms/display';
import { CompoundType, FieldType, PageField, UfError, ensureUfRequestError } from '@unifii/sdk';
import { Subject, debounceTime } from 'rxjs';

import { CompoundInfo, UcPage, UcProject } from 'client';
import { ContentSettings, EditMode, LinkSearchComponent, LinkSearchConfig, LinkSearchType, SaveOption, SaveOptionType, useDefaultErrorMessage } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { BuilderBasic } from 'components/compound-builder/builder-basic';
import { BuilderCompoundSubjects } from 'components/compound-builder/builder-models';
import { BuilderEventInfo, BuilderService } from 'components/compound-builder/builder.service';
import { FieldDetailHelper } from 'helpers/helpers';
import { PagesComponent } from 'pages/content/pages/pages.component';
import { appendSuffixCopy, cleanDefinitionToBeDuplicated } from 'pages/utils';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { LimitService } from 'services/limit.service';
import { UcTableManager } from 'services/table/models';
import { TitleService } from 'services/title.service';

@Component({
    templateUrl: './page-builder.html',
    styleUrls: ['./../../../styles/pages/builder.less'],
    providers: [
        BuilderService,
        ExpandersService,
        { provide: ContentSettings, useValue: { canEditDefinition: true, canEditContent: true } },
    ],
    standalone: false
})
export class PageBuilderComponent extends BuilderBasic implements OnInit, OnDestroy {

    type = CompoundType.Page;
    subject = BuilderCompoundSubjects.DEFINITION;
    ready: boolean;
    selectedField: PageField;

    protected displayPage?: RuntimePage;
    protected error?: UfError;

    private lastInserted: PageField | null;
    private pageChanged = new Subject<UcPage>();
    private _page: UcPage;

    constructor(
        builderService: BuilderService,
        modalService: ModalService,
        protected parent: PagesComponent,
        protected override router: Router,
        protected override route: ActivatedRoute,
        private ucProject: UcProject,
        private toastService: ToastService,
        private displayService: DisplayService,
        private limitService: LimitService,
        private breadcrumbService: BreadcrumbService,
        private builderHeaderService: BuilderHeaderService,
        private runtimePageAdapter: RuntimePageAdapter,
        @Inject(ContentSettings) public contentSettings: ContentSettings,
        @Optional() @Inject(TableContainerManager) protected override tableManager: UcTableManager<CompoundInfo> | null,
        private titleService: TitleService,
    ) {
        super(builderService, modalService, route, tableManager);
    }

    protected get page(): UcPage {
        return this._page;
    }

    protected set page(v: UcPage) {
        if (v === this.page) {
            return;
        }

        this._page = v;
        this.pageChanged.next(this.page);
    }

    async ngOnInit() {
        try {
            this.subscriptions.add(this.approveClicked.subscribe((id) => void this.approve(id)));
            // Load page
            this.page = await this.load();

        } catch (err) {
            this.error = useDefaultErrorMessage(err);

            return;
        }

        // Register event handlers
        this.addSubscribers();

        // Init builder service
        this.builderService.init(this, this.page);

        this.builderHeaderService.init();
        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe((saveOption) => void this.save(saveOption)));
        this.buildHeaderConfig(this.page);
    }

    override selectField(i: BuilderEventInfo = { subject: null, atomic: false }) {

        const next = !i ? null : i.subject;

        if (this.selectedField !== next) {
            this.selectedField = next;
            this.builderService.fieldSelected.next(i);
        }
    }

    override removeField(i: BuilderEventInfo) {
        const position = this.getFieldPosition(i.subject);

        if (position) {
            position.parent[this.builderService.childrenProperty].splice(position.index, 1);
            this.builderService.fieldRemoved.next(i);
        }
    }

    protected canDrop = async(element: any) => {

        if (!element.type || (element.type && FieldType[element.type as FieldType] == null)) {
            // Not a field (element from right panel like Validator, Option....)
            return false;
        }

        if (!element.reference) {
            // An actual field and not it's reference from the left tools list
            return true;
        }

        if (element.type === FieldType.LinkList) {

            const data: LinkSearchConfig = {
                title: 'Select Collection',
                multiSelect: false,
                minQuantitySelected: 1,
                ucProject: this.ucProject,
                type: LinkSearchType.Collection,
            };
            // LinkList (select a single collection)
            const result = await this.modalService.openMedium(LinkSearchComponent, data);

            if (!result?.length) {
                return false;
            }

            element.field = {
                type: element.type,
                compoundType: CompoundType.Collection,
                definitionIdentifier: result[0].identifier,
            };

            return true;
        }

        if (element.type === FieldType.Link && element.compoundType === CompoundType.Collection) {
            element.field = {
                type: element.type,
                compoundType: CompoundType.Collection,
            };

            return true;
        }

        if ([FieldType.Text, FieldType.MultiText].includes(element.type)) {
            element.field = {
                type: element.type,
                isTranslatable: true,
            };

            return true;
        }

        // No dialog needed
        return true;
    };

    protected convertFieldRef(ref: any) {
        if (ref.field) {
            return ref.field;
        }

        const res: any = { type: ref.type };

        if (ref.compoundType) {
            res.compoundType = ref.compoundType;
        }

        return res;
    }

    protected inserted(field: PageField) {
        delete field.value;
        (field as any).isNew = true;
        this.lastInserted = field;

        const fm = FieldDetailHelper.getMetadata(field, this.builderService.builder.type);

        if (fm.hasArrayValues === true) {
            field.value = [];
        }
        this.builderService.fieldAdded.next({ subject: field, atomic: true });
    }

    protected moved(field: PageField) {
        this.builderService.fieldMoved.next({ subject: field, atomic: true });
    }

    protected restore(step: number) {
        const value = step < 0 ? this.builderService.memento.undo() : this.builderService.memento.redo();

        this.builderService.definition = value;
        this.builderService.fieldSelect.next(null);
    }

    private addSubscribers() {

        this.subscriptions.add(this.pageChanged.pipe(debounceTime(500)).subscribe(() => {
            void this.updatePreview();
        }));

        // React to builderService ready
        this.subscriptions.add(this.builderService.ready.subscribe(() => {
            this.saveStatus();
            this.builderService.memento.edited = false;
            this.ready = true;
            this.builderService.fieldSelect.next(null);
        }));

        this.subscriptions.add(this.builderService.fieldSelected.subscribe(() => {
            this.pageChanged.next(this.page);
            this.builderService.memento.setLastAtomic();
        }));

        this.subscriptions.add(this.builderService.fieldAdded.subscribe((i) => {
            this.selectField(i);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldMoved.subscribe((i) => {
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldRemoved.subscribe((i) => {
            this.builderService.fieldSelect.next(null);
            this.saveStatus(i);
        }));

        this.subscriptions.add(this.builderService.fieldEdit.subscribe((i) => {
            this.saveStatus(i);
            this.builderService.fieldEdited.next(i);
        }));

        this.subscriptions.add(this.builderService.fieldReady.subscribe((i) => {
            // Auto-select last inserted field
            if (this.lastInserted === i.subject) {
                this.selectField(i);
                this.lastInserted = null;
            }
        }));
    }

    private async load(): Promise<UcPage> {

        if (this.editMode === EditMode.New) {
            return { _consoleName: '', _title: '', title: '', identifier: '', fields: [] };
        }

        const page = await this.ucProject.getPage(this.route.snapshot.params.id);

        if (this.editMode === EditMode.Existing) {
            return page;
        }

        const duplicatedPage = cleanDefinitionToBeDuplicated(page) as UcPage;

        // TODO: this will be removed in the future, _title and consoleName are the ones being kept
        duplicatedPage.title = appendSuffixCopy({ label: duplicatedPage.title });
        duplicatedPage._title = appendSuffixCopy({ label: duplicatedPage._title });
        duplicatedPage.identifier = appendSuffixCopy({ label: duplicatedPage.identifier, noWhiteSpace: true });
        duplicatedPage._consoleName = appendSuffixCopy({ label: duplicatedPage._consoleName });

        return duplicatedPage;

    }

    private async save(saveOption?: SaveOption) {
        // Notify the other components of a builder save/submit action
        this.builderService.submit.next(null);

        // Validate builder status
        if (!this.builderService.isValid()) {
            this.builderService.markFieldsSubmitted();
            this.builderHeaderService.notify.next({ level: MessageLevel.Error, title: 'Error', message: 'Unable to save. There are errors in your Page.' });

            return;
        }

        const cleanPage = this.builderService.cleanPage(this.page);

        // Save the page
        try {
            this.builderService.busy.next(true);
            this.page = await this.ucProject.savePage(cleanPage);

            this.saved(this.page, saveOption);
            this.builderService.init(this, this.page);

            // Notify user of the save success
            this.toastService.success('Page saved!');
            this.buildHeaderConfig(this.page);
            if (saveOption?.id === SaveOptionType.New &&
                !(await this.limitService.canAddPages())) {
                saveOption = undefined;
            }
            this.titleService.updateTitle(this.page._consoleName);
        } catch (error) {
            const message =
                (error as any).message ??
                (error as any).data?.message ??
                'Oops... something went wrong with saving your form';

            this.builderService.notify.next({ level: MessageLevel.Error, title: 'Error', message });
        } finally {
            this.builderService.busy.next(false);
        }
    }

    private async updatePreview() {

        /**
         * No need for an async call if definition doesn't contain a LinkList
         * note - Links need to be converted to Compounds before rendered
         */
        if (this.page.fields.find((field) => field.type === FieldType.LinkList) == null) {
            this.displayPage = this.runtimePageAdapter.transform(this.page);

            return;
        }

        try {
            const displayContent = await this.displayService.renderPage(this.page);

            this.displayPage = displayContent.page;
        } catch (e) {
            console.error(e);
        }
    }

    private saveStatus(i: BuilderEventInfo = { subject: null, atomic: true }) {
        this.builderService.memento.save(this.builderService.definition, i.atomic);
        this.builderService.memento.edited = true;

        if (this.ready) {
            this.builderHeaderService.config.edited = true;
        }

        this.pageChanged.next(this.page);
    }

    private buildHeaderConfig(page: UcPage) {
        const title = this.mapContentItemName(page._consoleName);

        this.builderHeaderService.buildConfig({
            ...page,
            title,
            cancelRoute: ['../'],
            saveOptions: this.saveOptions,
            publishState: page.publishState,
            restrictSave: 'ContentEditor',
            breadcrumbs: this.breadcrumbService.getBreadcrumbs(this.route, [title]),
        });

        this.titleService.updateTitle(title);
    }

    private async approve(id?: number) {
        if (!id) {
            return;
        }
        try {
            const page = await this.ucProject.approvePage(id);

            this.buildHeaderConfig(page);
            // it's using any here, because it's the same type,
            // the method save in builder-basic uses. otherwise we would have to create a mapping function
            this.tableManager?.updateItem.next(page as any);
            this.back();
        } catch (e) {
            const ufError = ensureUfRequestError(e, 'Error approving page');

            this.toastService.error(ufError.message);
        }
    }

}
