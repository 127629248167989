import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Breadcrumb, TabComponent, TabsComponent } from '@unifii/library/common';

import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { TabsPage } from 'pages/tabs-page';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { TitleService } from 'services/title.service';

import { TabLabels } from './constants';

@Component({
    selector: 'uc-settings',
    templateUrl: './settings.html',
    standalone: false
})
export class SettingsComponent extends TabsPage implements OnInit {

    @ViewChild(TabsComponent, { static: true }) tabsComponent: TabsComponent;

    override get edited(): boolean {
        return !!this.builderHeaderService.config.edited;
    }

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    breadcrumbs: Breadcrumb[];

    protected tabLabels = TabLabels;

    private breadcrumbService = inject(BreadcrumbService);
    private builderHeaderService = inject(BuilderHeaderService);
    private context = inject(ContextService);
    private titleService = inject(TitleService);

    override ngOnInit() {
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);
        super.ngOnInit();
        this.buildHeaderConfig();
    }

    buildHeaderConfig() {
        this.edited = false;
        this.builderHeaderService.buildConfig({
            hideSaveButton: true,
            breadcrumbs: this.breadcrumbs,
        });

        this.updateTitle();
    }

    protected updateTitle() {
        const currentTab = this.tabsComponent.tabs.find((tab: TabComponent) => tab.active);

        if (currentTab) {
            this.titleService.updateTitle(`${this.context.project?.name} | ${currentTab.label}`, true);
        }
    }

}
