import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UfControlArray, UfControlGroup, UfFormBuilder } from '@unifii/library/common';
import { Subscription } from 'rxjs';

interface Item {
    title: string;
    children?: Item[];
}

@Component({
    selector: 'uc-lab-drag-list',
    templateUrl: './lab-drag-list.html',
    standalone: false
})
export class LabDragListComponent implements OnInit, OnDestroy {

    @HostBinding('class.stretch-component') class = true;

    protected flatItems: Item[] | null;
    protected nestedItems: Item[] | null;
    protected flatControl: UfControlArray | null;
    protected flatEdited: boolean;
    protected nestedControl: UfControlArray | null;
    protected nestedEdited: boolean;
    protected count: number;

    private subscriptions = new Subscription();
    private fb = inject(UfFormBuilder);
    private route = inject(ActivatedRoute);

    ngOnInit() {

        const count = parseInt(this.route.snapshot.queryParams.count);

        this.count = !isNaN(count) ? count : 50;

        this.flatItems = new Array(this.count).fill(null).map((_, index) => ({ title: `${index}` }));

        // this.flatControl = this.fb.array(this.flatItems.map((i) => this.buildItemControl(i)));
        // this.subscriptions.add(this.flatControl.valueChanges.subscribe(() => { this.flatEdited = true; }));

        // this.nestedItems = [
        //     { title: 'A' },
        //     { title: 'B', children: [{ title: 'B.1' }, { title: 'B.2' }] },
        //     { title: 'C' },
        // ];

        // this.nestedControl = this.fb.array(this.nestedItems.map((i) => this.buildItemControl(i)));
        // this.subscriptions.add(this.nestedControl.valueChanges.subscribe(() => { this.nestedEdited = true; }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    buildItemControl(item: Item): UfControlGroup {
        return this.fb.group({
            title: item.title + ' - control',
            children: this.fb.array((item.children?.map((c) => this.buildItemControl(c)) ?? [])),
        });
    }

}
