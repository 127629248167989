import { Component, HostBinding, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularRouterLink, Breadcrumb, DataDisplayService, ModalService, SortStatus, TableComponent, TableConfig } from '@unifii/library/common';
import { Client, ContentClient, DataType, DefinitionPublishState, ProjectContentOptions, ProjectContentOptionsInterface, TenantClient } from '@unifii/sdk';
import { Subscription, delay } from 'rxjs';

import { ConsoleNameLabel, ConsoleNameProperty } from 'constant';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';
import { ProjectPublisher, PublishInfo, PublishItem, PublishableType } from 'services/project-publisher';
import { TitleService } from 'services/title.service';

import { PublishItemsDatasource } from './publish-items-datasource';

export const publishedContentFactory = (client: Client, tenantClient: TenantClient, projectOptions: ProjectContentOptionsInterface) => {
    const options: ProjectContentOptionsInterface = {
        projectId: `${projectOptions.projectId}`,
        preview: false,
    };

    return new ContentClient(client, tenantClient, options);
};

interface Artifact {
    name: string;
    filename: string;
    createdAt: string;
}

@Component({
    templateUrl: './publish.html',
    styleUrls: ['./publish.less'],
    providers: [
        { provide: ContentClient, useFactory: publishedContentFactory, deps: [Client, TenantClient, ProjectContentOptions] },
        ProjectPublisher,
    ],
    standalone: false
})
export class PublishComponent implements OnDestroy, OnInit {

    @HostBinding('class.stretch-component') hostClass = true;
    @ViewChild('publishTable') private publishTable: TableComponent<PublishItem> | undefined;
    @ViewChild('archiveTable') private archiveTable: TableComponent<PublishItem> | undefined;

    protected readonly publishTableId = 'publish';
    protected readonly archiveTableId = 'archive';
    protected status: PublishInfo;
    protected artifact: Artifact | undefined;
    protected downloadInProgress = false;
    protected downloadError: Error | null;
    protected breadcrumbs: Breadcrumb[];
    protected publishDataSource: PublishItemsDatasource | undefined;
    protected publishTableConfig: TableConfig<PublishItem>;
    protected archiveDataSource: PublishItemsDatasource | undefined;
    protected archiveTableConfig: TableConfig<PublishItem>;
    protected selectedItems: PublishItem[] = [];

    private client = inject(Client);
    private context = inject(ContextService);
    private modalService = inject(ModalService);
    private contentClient = inject(ContentClient);
    private publisher = inject(ProjectPublisher);
    private projectOptions = inject(ProjectContentOptions) as ProjectContentOptionsInterface;
    private breadcrumbService = inject(BreadcrumbService);
    private route = inject(ActivatedRoute);
    private titleService = inject(TitleService);
    private dataDisplayService = inject(DataDisplayService);
    private publishEvent: Subscription;
    private publishItems: PublishItem[] | undefined;
    private archiveItems: PublishItem[] | undefined;

    ngOnInit() {
        this.publishEvent = this.publisher.event.subscribe((event) => {
            this.updateStatus(event);
            void this.updateArtifact();
        });

        this.publishTableConfig = this.getTableConfig();
        this.archiveTableConfig = this.getTableConfig();

        void this.publisher.update();
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route);
        this.titleService.updateTitle(`${this.context.project?.name} | Publish`, true);
    }

    ngOnDestroy() {
        this.publishEvent.unsubscribe();
    }

    protected async publish(preview?: boolean) {

        const consent = await this.userConsent(preview);

        if (!consent) {
            return;
        }

        void this.publisher.publish(preview, this.selectedItems);
    }

    protected async getAppPackage(version: number) {
        if (!this.artifact) {
            return;
        }

        const url = this.client.buildUrl('projects', this.projectOptions.projectId, 'versions', `${version}`, 'artifacts', this.artifact.name);
        const fetchRequest = {
            method: 'GET',
            headers: new Headers({
                Authorization: 'Bearer ' + this.client.token,
            }),
        };

        this.downloadError = null;
        this.downloadInProgress = true;

        try {
            const response = await fetch(url, fetchRequest);
            const data = await response.blob();
            const fileUrl = window.URL.createObjectURL(data);
            const a = document.createElement('a');

            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = fileUrl;
            a.download = `app-package-${this.projectOptions.projectId}-${version}-stable.zip`;
            a.click();
            window.URL.revokeObjectURL(fileUrl);
            a.remove();
        } catch (e) {
            this.downloadError = new Error('Download error');
        } finally {
            this.downloadInProgress = false;
        }
    }

    protected onTableSortChange(tableId: string, sort?: SortStatus | undefined, selectAll = false) {
        const items = tableId === this.publishTableId ? this.publishItems : this.archiveItems;
        const datasource = items?.length ? new PublishItemsDatasource(items, sort) : undefined;
        const selected = (tableId === this.publishTableId ? this.publishTable : this.archiveTable)?.select?.selected;

        if (tableId === this.publishTableId) {
            this.publishDataSource = datasource;
        } else {
            this.archiveDataSource = datasource;
        }

        const subscription = datasource?.loaded.pipe(delay(50)).subscribe(() => {
            const table = tableId === this.publishTableId ? this.publishTable : this.archiveTable;

            if (selectAll) {
                table?.select?.selectAll();
            } else {
                table?.select?.select(selected ?? []);
            }

            subscription?.unsubscribe();
        });
    }

    protected onSelectChange() {
        this.selectedItems = [...(this.publishTable?.select?.selected ?? []), ...(this.archiveTable?.select?.selected ?? [])];
    }

    protected get inProgress(): boolean {
        return this.status.pending != null || this.downloadInProgress;
    }

    protected get stablePublishInProgress(): boolean {
        return this.status.pending != null && this.status.pending.preview == null;
    }

    protected get stablePublishError(): Error | undefined {
        if (this.status.failure?.version && this.status.failure.version.preview == null) {
            return this.status.failure.error;
        }

        return undefined;
    }

    protected get previewPublishError(): Error | undefined {
        if (this.status.failure?.version?.preview != null) {
            return this.status.failure.error;
        }

        return undefined;
    }

    protected get statusError(): Error | undefined {
        if (this.status.failure && this.status.preview == null && this.status.stable == null) {
            return this.status.failure.error;
        }

        return undefined;
    }

    private updateStatus(status: PublishInfo) {
        this.publishItems = status.items?.filter((item) => item.publishState === DefinitionPublishState.Approved);
        this.onTableSortChange(this.publishTableId, undefined, true);
        this.archiveItems = status.items?.filter((item) => item.publishState === DefinitionPublishState.ArchivePending);
        this.onTableSortChange(this.archiveTableId, undefined, true);
        this.status = status;
    }

    private async updateArtifact() {
        if (!this.context.project?.offline) {
            return;
        }

        try {
            const version = await this.contentClient.getLatestVersion();

            if (version.artifacts.length) {
                this.artifact = version.artifacts[0];
            }
        } catch (error) {
            console.error('Failed to update the Artifact', error);
        }
    }

    private userConsent(preview = false): Promise<boolean | undefined> {
        return this.modalService.openConfirm({
            title: 'Publish',
            message: this.getConsentMessage(preview),
            confirmLabel: 'Publish',
            cancelLabel: `Don't Publish`,
        });
    }

    private getConsentMessage(preview?: boolean): string {
        const nextVersion = this.publisher.getNextVersion(preview);

        if (nextVersion.preview == null) {
            const version = nextVersion.version > 1 ? nextVersion.version : 'Stable';

            return `Publish ${version}?`;

        }

        if (!this.status.preview) {
            return `Publish Preview?`;
        }

        return `Publish ${nextVersion.version}-preview.${nextVersion.preview}`;
    }

    private getTableConfig(): TableConfig<PublishItem> {
        return {
            columns: [{
                name: 'publishableType',
                label: 'Content Type',
                sortable: true,
            }, {
                name: ConsoleNameProperty,
                label: ConsoleNameLabel,
                value: (item) => item.consoleName ?? item.id,
                sortable: true,
            }, {
                name: 'lastModifiedAt',
                label: 'Last Modified',
                sortable: true,
                value: (item) =>
                    this.dataDisplayService.displayAsString(item.lastModifiedAt, { type: DataType.OffsetDateTime, asDistanceFromNow: true }),
            }, {
                name: 'lastModifiedBy',
                label: 'Modified By',
                sortable: true,
                value: (item) => item.lastModifiedBy?.username,
            }],
            selectable: true,
            row: {
                link: this.getItemLink.bind(this),
                linkTarget: '_blank',
            },
        };
    }

    private getItemLink(item: PublishItem): AngularRouterLink {
        switch (item.publishableType) {
            case PublishableType.Collection:
                return ['..', 'content', 'collections', item.identifier];
            case PublishableType.CollectionItem:
                return ['..', 'content', 'collections', item.identifier, item.id];
            case PublishableType.Structure:
                return ['..', 'structure'];
            case PublishableType.Form:
                return ['..', 'forms', item.id];
            case PublishableType.Table:
                return ['..', 'tables', item.id];
            case PublishableType.Page:
                return ['..', 'content', 'pages', item.id];
            case PublishableType.View:
            // case PublishableType.ViewDefinition:
                return ['..', 'content', 'views', item.id];
            case PublishableType.FormBucket:
                return ['..', 'form-data', item.id];
            default:
                return '';
        }
    }

}
