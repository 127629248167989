import { Component, HostBinding, inject } from '@angular/core';
import { TableContainerManager } from '@unifii/components';
import { Breadcrumb, FilterEntries } from '@unifii/library/common';

import { UcProject } from 'client';
import { CollectionsInfoLoader } from 'services/table/info-loaders';
import { InfoTableManager } from 'services/table/info-table-manager';
import { InfoLoader } from 'services/table/models';

const createLoader = (ucProject: UcProject) => new CollectionsInfoLoader(ucProject);

@Component({
    providers: [
        { provide: FilterEntries, useValue: [] },
        { provide: InfoLoader, useFactory: createLoader, deps: [UcProject] },
        { provide: TableContainerManager, useClass: InfoTableManager },
    ],
    template: `
    <div ufMasterDetail>
        <uf-table-container (addItem)="addItem()" ufSyncRoute class="accent list-md pad-none" />
    </div>
    <router-outlet/>
`,
    standalone: false
})
export class CollectionsComponent {

    @HostBinding('class.stretch-component') protected hostClass = true;

    protected breadcrumbs: Breadcrumb[] = [];

    private manager = inject<InfoTableManager>(TableContainerManager);

    protected addItem() {
        void this.manager.addActionCallback();
    }

}
