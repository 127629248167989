import { Component, EventEmitter, Input, NgZone, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { DataPropertyDescriptor, ExpandersService, UfControlGroup, isIdentifiersPathExpression } from '@unifii/library/common';
import { ColumnDescriptor, SchemaField, generateUUID } from '@unifii/sdk';

import { DialogsService } from 'services/dialogs.service';

interface ColumnInfo {
    descriptor: ColumnDescriptor;
    dataPropertyDescriptor?: DataPropertyDescriptor;
    label?: string;
    icon?: string;
    exists?: boolean;
}

@Component({
    selector: 'uc-table-columns',
    templateUrl: 'table-columns.html',
    providers: [ExpandersService],
    standalone: false
})
export class TableColumnsComponent implements OnChanges {

    @Input() parentControl: UfControlGroup;
    @Input() availableColumns: DataPropertyDescriptor[];
    @Input() columns: ColumnDescriptor[] | undefined;
    @Output() columnsChange = new EventEmitter<ColumnDescriptor[] | undefined>();

    protected columnResults: SchemaField[];
    protected cols: ColumnInfo[];

    private _columns: ColumnDescriptor[] = [];
    private zone = inject(NgZone);
    private dialogs = inject(DialogsService);

    ngOnChanges(changes: SimpleChanges) {
        // guard against list re-rendering otherwise expanders will collapse
        if (changes.columns?.currentValue === this._columns) {
            return;
        }
        this.cols = (this.columns ?? []).map((c) => this.getColumInfo(c));
    }

    protected addColumn(column: ColumnDescriptor) {
        this.zone.run(() => {
            this.cols.push(this.getColumInfo(column));
            this.emit();
        });
    }

    protected removeColumn(index: number) {
        // eslint-disable-next-line space-before-function-paren
        this.zone.run(async () => {
            if (!await this.dialogs.confirmDelete()) {
                return;
            }
            this.cols.splice(index, 1);
            this.emit();
        });
    }

    protected addCustom() {
        const column: ColumnDescriptor = {
            identifier: generateUUID(),
            heading: 'Custom',
        };

        this.addColumn(column);
    }

    protected emit() {
        this.syncLabels();
        this._columns = this.cols.map((c) => c.descriptor);
        this.columnsChange.emit(this._columns);
    }

    protected hasVariations(column: ColumnInfo): boolean {
        return !!column.descriptor.variations?.length;
    }

    protected filterColumns(q?: string) {

        this.columnResults = this.availableColumns.filter((item) => {
            let accepted = true;

            // Apply search
            if (q?.trim().length) {
                accepted = (item.display as string).toLowerCase().includes(q.toLowerCase());
            }
            // Apply excluded
            if (accepted) {
                accepted = !this.cols.find(({ descriptor }) => descriptor.identifier === item.identifier);
            }

            return accepted;
        });
    }

    private getColumInfo(column: ColumnDescriptor): ColumnInfo {

        const propertyDescriptor = this.availableColumns.find((c) => c.identifier === column.identifier);

        return {
            dataPropertyDescriptor: propertyDescriptor,
            icon: isIdentifiersPathExpression(column.identifier) ? propertyDescriptor?.icon ?? 'query' : 'custom',
            label: this.getDisplayLabelValue(column, propertyDescriptor),
            exists: !!column?.heading || propertyDescriptor != null,
            descriptor: column,
        };
    }

    private syncLabels() {
        for (const col of this.cols) {
            col.label = this.getDisplayLabelValue(col.descriptor, col.dataPropertyDescriptor);
        }
    }

    private getDisplayLabelValue(columnDescriptor: ColumnDescriptor, propertyDescriptor?: DataPropertyDescriptor): string {

        const isCustom = !isIdentifiersPathExpression(columnDescriptor.identifier);

        if (isCustom) {
            return columnDescriptor.heading ?? 'Custom';
        }

        if (columnDescriptor.heading) {
            return `${columnDescriptor.heading} (${columnDescriptor.identifier})`;
        }

        return propertyDescriptor?.display ?? columnDescriptor.identifier;
    }

}
