import { Injectable, inject } from '@angular/core';
import { DATE_TIME_DATA_FORMAT } from '@unifii/sdk';
import { add, format } from 'date-fns';

import { Config, ConsoleOptions } from 'app-config';
import { TenantSettings, UcClient } from 'client';

import { ContextService } from './context.service';

/**
 * Tenant settings gets reused at multiple times during user access, it is important that
 * tenant settings are refreshed on app start and only after cache has expired
 */
@Injectable({ providedIn: 'root' })
export class TenantSettingsService {

    private client = inject(UcClient);
    private config = inject<ConsoleOptions>(Config);
    private context = inject(ContextService);

    // Amount of minutes before settings should be updated
    private cacheLifetime = ['dev', 'localhost'].includes(this.config.env) ? { minutes: 2 } : { minutes: 10 };
    // set on successfully update
    private cacheExpiry: string | null;

    async sync(): Promise<TenantSettings> {
        if (!this.requiresSync) {
            return this.context.tenantSettings as TenantSettings;
        }

        this.context.tenantSettings = await this.client.getSettings();
        this.cacheExpiry = this.getDatetime(this.cacheLifetime);
        // Update config from environment with feature flags loaded from the backend.
        Object.assign(this.config.flags, this.context.tenantSettings.features);

        return this.context.tenantSettings;
    }

    private get requiresSync(): boolean {
        if (this.context.tenantSettings == null || this.cacheExpiry == null) {
            return true;
        }

        return this.cacheExpiry < this.getDatetime();
    }

    private getDatetime(offset?: { minutes: number }): string {

        if (offset != null) {
            return format(add(new Date(), offset), DATE_TIME_DATA_FORMAT);
        }

        return format(new Date(), DATE_TIME_DATA_FORMAT);
    }

}
