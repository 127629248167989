import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { ToastService, UfControl, UfControlArray, UfControlGroup, durationToSeconds, secondsToDuration } from '@unifii/library/common';
import { UfError, ensureUfError } from '@unifii/sdk';
import { Duration } from 'date-fns';
import { Subscription } from 'rxjs';

import { EmailInfo, EmailTemplate, UcSmtp } from 'client';
import { EditData } from 'components';
import { BuilderHeaderService } from 'components/common/builder-header/builder-header.service';
import { TitleService } from 'services/title.service';

import { EmailSettingsControlKey, EmailTemplateControlKey, SystemEmailSettingsController } from './system-email-settings-controller';
import { TenantSettingsComponent } from './tenant-settings.component';

@Component({
    templateUrl: './system-email-settings.html',
    standalone: false,
})
export class SystemEmailSettingsComponent implements OnInit, OnDestroy, EditData {

    protected readonly controlKey = EmailSettingsControlKey;
    protected readonly templateControlKey = EmailTemplateControlKey;
    protected readonly defaultTemplates: Record<'invite' | 'resetPassword', EmailTemplate> = {
        invite: {
            title: '',
            subject: 'Invitation to join {{tenantName}}',
            introMessage: '### You have been invited to join {{tenantName}}',
            bodyMessage: 'If you are having difficulties please contact your administrator:\n  \n**Name:** {{contactName}}  \n**Email:** {{contactEmail}}  \n**Phone:** {{contactPhone}}  \n\nBy completing this registration you will be agreeing to our [privacy policy]({{privacyPolicy}})',
            buttonLabel: 'Complete registration online',
            baseUrl: null,
            conditions: [],
        },
        resetPassword: {
            title: '',
            subject: 'Password reset for {{tenantName}}',
            introMessage: '### You have requested to reset your password for {{tenantName}}',
            bodyMessage: 'If you are having difficulties please contact your administrator:\n  \n**Name:** {{contactName}}  \n**Email:** {{contactEmail}}  \n**Phone:** {{contactPhone}}  \n\nBy completing this registration you will be agreeing to our [privacy policy]({{privacyPolicy}})',
            buttonLabel: 'Reset your password',
            baseUrl: null,
            conditions: [],
        },
    };

    protected error: UfError | null;
    protected form: UfControlGroup;
    protected emailTokenDuration: Duration;
    protected valueChangesSubscription?: Subscription;

    private subscriptions = new Subscription();
    private emailInfo: EmailInfo;

    private ucSmtp = inject(UcSmtp);
    private toast = inject(ToastService);
    private formController = inject(SystemEmailSettingsController);
    private parent = inject(TenantSettingsComponent);
    private builderHeaderService = inject(BuilderHeaderService);
    private titleService = inject(TitleService);

    set edited(v: boolean) {
        this.builderHeaderService.config.edited = v;
    }

    get edited() {
        return this.builderHeaderService.config.edited ?? false;
    }

    get replyToControl(): UfControl {
        return this.form.get(EmailSettingsControlKey.ReplyTo) as UfControl;
    }

    get inviteEmailExpiryControl(): UfControl {
        return this.form.get(EmailSettingsControlKey.InviteEmailExpiry) as UfControl;
    }

    get inviteEmailDefaultTemplateControl(): UfControlGroup {
        return this.form.get(EmailSettingsControlKey.InviteEmailDefaultTemplate) as UfControlGroup;
    }

    get resetPasswordEmailDefaultTemplateControl(): UfControlGroup {
        return this.form.get(EmailSettingsControlKey.ResetPasswordEmailDefaultTemplate) as UfControlGroup;
    }

    get inviteEmailTemplatesControl(): UfControlArray {
        return this.form.get(EmailSettingsControlKey.InviteEmailTemplates) as UfControlArray;
    }

    get resetPasswordEmailTemplatesControl(): UfControlArray {
        return this.form.get(EmailSettingsControlKey.ResetPasswordEmailTemplates) as UfControlArray;
    }

    get emailColourControl(): UfControl {
        return this.form.get(EmailSettingsControlKey.EmailColour) as UfControl;
    }

    async ngOnInit() {
        this.error = null;
        this.edited = false;
        this.titleService.updateTitle('Email');

        try {
            this.emailInfo = await this.ucSmtp.getEmail();

            this.form = await this.formController.buildRoot(this.emailInfo);

            this.buildHeaderConfig();

            this.emailTokenDuration = secondsToDuration(this.emailInfo.inviteEmailExpiry);

        } catch (e) {
            this.error = ensureUfError(e);
            this.toast.error('Loading error');
            console.error(e);
        }

        this.valueChangesSubscription = this.form.valueChanges.subscribe(() => { this.edited = true; });

        this.subscriptions.add(this.builderHeaderService.saveClicked.subscribe(() => void this.save()));
        this.subscriptions.add(this.form.valueChanges.subscribe(() => {
            this.edited = true;
        }));
    }

    ngOnDestroy(): void {
        this.valueChangesSubscription?.unsubscribe();
        this.parent.edited = false;
        this.subscriptions.unsubscribe();
    }

    async save() {
        this.error = null;
        this.form.setSubmitted();

        if (!this.form.valid) {
            this.toast.error('There are errors on this page');

            return;
        }

        const data = this.formController.toDataModel(this.form.getRawValue());

        try {
            this.emailInfo = await this.ucSmtp.saveEmail(data);
            this.toast.success('Saved successfully');
            this.edited = false;

            this.buildHeaderConfig();
        } catch (e) {
            this.error = ensureUfError(e);
            this.toast.error('Error saving');
            console.error(e);
        }
    }

    inviteEmailExpirationChange(value: number, field: keyof Duration) {
        this.emailTokenDuration[field] = value;
        this.form.controls.inviteEmailExpiry?.setValue(durationToSeconds(this.emailTokenDuration));
    }

    private buildHeaderConfig() {
        this.builderHeaderService.updateConfig({
            lastModifiedAt: this.emailInfo.lastModifiedAt,
            lastModifiedBy: this.emailInfo.lastModifiedBy,
            hideSaveButton: false,
            cancelRoute: ['..'],
        });
    }

}
