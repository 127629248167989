import { Component, Input } from '@angular/core'; 
import { UfError } from '@unifii/sdk';

@Component({
    selector: 'uc-error-message',
    templateUrl: './error.html',
    standalone: false
})

export class ErrorComponent {

  @Input() error?: UfError; 

}
