import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TableContainerManager } from '@unifii/components';

 import { DataTransactionsTableManager } from './data-transaction-table-manager';

@Component({
    selector: 'uc-data-transactions-table',
    template: `
        <div ufMasterDetail>
            <uf-table-container (addItem)="addItem()"  class="accent list-md pad-none" />
        </div>
        <router-outlet />
    `,
    providers: [
        { provide: TableContainerManager, useClass: DataTransactionsTableManager },
    ],
    standalone: false
})
export class DataTransactionsTableComponent {

    private router = inject(Router);
    private route = inject(ActivatedRoute);

    protected addItem() {
        this.router.navigate(['./new'], { relativeTo: this.route });
    }

}
