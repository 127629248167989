import { ChangeDetectorRef, Component, Input, NgZone, OnInit, inject } from '@angular/core';
import { CommonTranslationKey, ModalService, ToastService, UfControl, UfControlArray, UfControlGroup } from '@unifii/library/common';
import { OAuthWithMfaDevice, OAuthWithMfaDeviceSetup, OAuthWithVirtualMfa, TenantClient, arrayBufferToBase64Url, ensureUfRequestError } from '@unifii/sdk';
import { DeviceMfaNameModalComponent, UserKeys, UserMfaInfoFormControl, UserMfaInfoKeys, UserSetupDeviceMfaModalComponent, UserSetupRecoveryCodesModalComponent, UserSetupSmsModalComponent, UserSetupVirtualMfaModalComponent, isAuthenticatorAssertionResponse, isAuthenticatorAttestationResponse } from '@unifii/user-provisioning';

import { Config, ConsoleOptions } from 'app-config';
import { UcClient } from 'client';
import { AuthenticationService } from 'services/authentication.service';
import { ContextService } from 'services/context.service';

@Component({
    selector: 'uc-user-mfa',
    templateUrl: 'user-mfa.html',
    standalone: false
})
export class UserMfaComponent implements OnInit {

    @Input({ required: true }) form: UfControlGroup;
    @Input() selfSetup = false;
    @Input() cssClasses: string | string[] | undefined | null;

    protected readonly commonTK = CommonTranslationKey;
    protected readonly userMfaInfoKeys = UserMfaInfoKeys;
    protected isMfaOptionalControl?: UfControl;
    protected mfaControl?: UfControlGroup;

    private cdr = inject(ChangeDetectorRef);
    private zone = inject(NgZone);
    private modalService = inject(ModalService);
    private toastService = inject(ToastService);
    private context = inject(ContextService);
    private client = inject(UcClient);
    private tenantClient = inject(TenantClient);
    private userMfaInfoController = inject(UserMfaInfoFormControl);
    private config = inject<ConsoleOptions>(Config);
    private authService = inject(AuthenticationService);
    private deviceMfaChallengeKey: string | undefined;

    ngOnInit() {
        this.isMfaOptionalControl = this.form.get(UserKeys.IsMfaOptional) as UfControl | undefined;
        this.mfaControl = this.form.get(UserKeys.Mfa) as UfControlGroup | undefined;

        if (!this.context.tenantSettings?.isDeviceMfaEnabled) {
            this.mfaControl?.removeControl(UserMfaInfoKeys.Devices);
        }

    }

    protected async setupVirtualMfa(): Promise<void> {
        await this.zone.run(async() => {

            const tenantName = (await this.tenantClient.getSettings()).name;

            if (!this.context.account?.username) {
                throw new Error('No username');
            }

            const label = `(${tenantName}) ${this.context.account.username}`;

            const result = await this.modalService.openMedium(UserSetupVirtualMfaModalComponent, { label });

            if (!result) {
                return;
            }

        try {
            const response = await this.client.setVirtualMfaCode(result.secret);

            await this.authService.login({ mfa_token: result.token } satisfies OAuthWithVirtualMfa);
            (this.mfaControl?.get(UserMfaInfoKeys.VirtualCode) as UfControl | undefined)?.setValue(response.secret);
        } catch (e) {
            this.toastService.error(ensureUfRequestError(e).message);
        }

        });
	}

	protected async setupRecoverCodes(): Promise<void> {
        this.refresh();
		const recoveryCodes = await this.modalService.openMedium(UserSetupRecoveryCodesModalComponent);

        if (!recoveryCodes) {
            return;
        }

        try {
            await this.client.setRecoveryCodes(recoveryCodes);
        } catch (e) {
            this.toastService.error(ensureUfRequestError(e).message);
        }

        (this.mfaControl?.get(UserMfaInfoKeys.HasRecoveryCodes) as UfControl | undefined)?.setValue(true);
	}

    protected async setupSms(): Promise<void> {
        this.refresh();
        const oAuthWithMfaSms = await this.modalService.openMedium(UserSetupSmsModalComponent, { smsChallenges: () => this.client.getSmsChallenges() });

        if (!oAuthWithMfaSms) {
            return;
        }

        try {
            await this.authService.login(oAuthWithMfaSms);
            await this.client.setSmsMfaEnabled();
            (this.mfaControl?.get(UserMfaInfoKeys.IsSmsEnabled) as UfControl | undefined)?.setValue(true);
        } catch (e) {
            this.toastService.error(ensureUfRequestError(e).message);
        }

	}

    protected setupDevice() {
        void this.modalService.openMedium(UserSetupDeviceMfaModalComponent, {
            setupCredential: (credential) => this.setupCredential(credential),
            getSetupChallenge: () => this.getSetupChallenge(),
            getVerifyChallenge: () => this.getVerifyChallenge(),
            verifyCredential: (credential: PublicKeyCredential) => this.verifyCredential(credential),
        });
	}

    protected async setupCredential(credential: PublicKeyCredential) {

        if (!this.deviceMfaChallengeKey || !isAuthenticatorAttestationResponse(credential.response)) {
            return;
        }

        const params: OAuthWithMfaDeviceSetup = {
            id: credential.id,
            raw_id: arrayBufferToBase64Url(credential.rawId),
            type: credential.type,
            challenge_key: this.deviceMfaChallengeKey,
            client_data_json: arrayBufferToBase64Url(credential.response.clientDataJSON),
            attestation_object: arrayBufferToBase64Url(credential.response.attestationObject),
        };

        try {
            await this.authService.login( params );
            const name = await this.modalService.openMedium(DeviceMfaNameModalComponent) ?? '';

            await this.client.completeDeviceMfaSetup(this.deviceMfaChallengeKey, name);
            const deviceControl = this.userMfaInfoController.buildDeviceControlGroup({ name, id: credential.id });

            (this.mfaControl?.get(UserMfaInfoKeys.Devices) as UfControlArray | undefined)?.push(deviceControl);
        } catch (e) {
            this.toastService.error(ensureUfRequestError(e).message);
        }
    }

    protected async getSetupChallenge(): Promise<CredentialCreationOptions> {
        const { publicKey, challengeKey } = await this.client.setupDeviceMfa(this.config.baseUrl);

        this.deviceMfaChallengeKey = challengeKey;

        return { publicKey };
    }

    // TODO - currently not used in functionality but required by component, consider making optional
    protected getVerifyChallenge(): Promise<CredentialRequestOptions> {
        return this.client.getDeviceMfaChallenge(this.config.baseUrl);
    }

    // TODO - currently not used in functionality but required by component, consider making optional
    protected async verifyCredential(credential: PublicKeyCredential) {
        if (!isAuthenticatorAssertionResponse(credential.response)) {
            return;
        }

        const params: OAuthWithMfaDevice = {
            id: credential.id,
            raw_id: arrayBufferToBase64Url(credential.rawId),
            type: credential.type,
            client_data_json: arrayBufferToBase64Url(credential.response.clientDataJSON),
            authenticator_data: arrayBufferToBase64Url(credential.response.authenticatorData),
            signature: arrayBufferToBase64Url(credential.response.signature),
        };

        try {
            await this.authService.login( params );
        } catch (e) {
            this.toastService.error(ensureUfRequestError(e).message);
        }
    }

    protected removeDevice(deviceControl: UfControlGroup) {
		const devicesControlArray = this.mfaControl?.get(UserMfaInfoKeys.Devices) as UfControlArray | undefined;

        if (!devicesControlArray) {
            return;
        }

		const index = devicesControlArray.controls.findIndex((control) => control === deviceControl);

		devicesControlArray.removeAt(index);
	}

    protected clearMfa() {
        this.mfaControl?.reset();
    }

    // value change occurs after async call
    protected refresh() {
        this.cdr.detectChanges();
    }

}
