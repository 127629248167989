import { Component, HostBinding, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Breadcrumb } from '@unifii/library/common';

import { UcDefinition, UcProject } from 'client';
import { CollectionBuilderComponent } from 'pages/content/collections/collection-builder.component';
import { CollectionService } from 'pages/content/collections/collection-service';
import { BreadcrumbService } from 'services/breadcrumb.service';
import { ContextService } from 'services/context.service';

/** Route non-reusable component, see ConsoleRouteReuseStrategy */
@Component({
    selector: 'uc-collection',
    templateUrl: 'collection.html',
    providers: [CollectionService],
    standalone: false
})
export class CollectionComponent {

    @HostBinding('class.stretch-component') class = true;

    @ViewChild(CollectionBuilderComponent, { static: true }) definitionBuilder: CollectionBuilderComponent;

    breadcrumbs: Breadcrumb[];

    constructor(
        private route: ActivatedRoute,
        private ucProject: UcProject,
        private service: CollectionService,
        public context: ContextService,
        private breadcrumbService: BreadcrumbService,
    ) {
        this.service.ucCollection = this.ucProject.collection(this.route.snapshot.params.id);
        void this.service.ucCollection.getDefinition().then((d) => { this.service.definition = d; });
        this.breadcrumbs = this.breadcrumbService.getBreadcrumbs(this.route, [this.definition.label]);
    }

    get definition(): UcDefinition {
        return this.service.definition;
    }

    get edited(): boolean {
        return this.service.edited;
    }

}
