import { Component, Input, inject } from '@angular/core';
import { ClipboardService, DataDisplayListItem, UfControlGroup } from '@unifii/library/common';
import { StructureNodeType, StructureNodeVariation, isString } from '@unifii/sdk';

import { ContextService } from 'services/context.service';

import { TablesContainerTemplateOptions } from '../structure-constants';
import { StructureNodeControlKeys } from '../structure-control-keys';
import { StructureEditorService } from '../structure-editor.service';
import { canCopyStructureNode, isStructureNodeRoot } from '../structure-functions';
import { StructureEditorNodeOrRoot, isStructureEditorNodeOrRoot } from '../structure-model';

@Component({
    selector: 'uc-node-settings',
    templateUrl: './node-settings.html',
    standalone: false
})
export class NodeSettingsComponent {

    protected readonly formKeys = StructureNodeControlKeys;
    protected readonly templateOptions = TablesContainerTemplateOptions;
    protected showTemplate = false;
    protected ready = false;
    protected appUrl: DataDisplayListItem | null;
    protected canCopy = false;
    protected canHide = false;
    protected showTags = false;
    protected showDefinitionIdentifier = false;
    protected showURL = false;
    protected tagOptions: string[] = [];

    private _control: UfControlGroup;
    private context = inject(ContextService);
    private clipboard = inject(ClipboardService);
    private service = inject(StructureEditorService);

    @Input({ required: true }) set control(v: UfControlGroup) {

        this._control = v;

        // Reset
        this.ready = false;

        this.canCopy = !v.disabled && isStructureEditorNodeOrRoot(this.node) && canCopyStructureNode(this.node) && [StructureNodeType.IFrame].includes(this.node.type);
        this.canHide = !v.disabled && isStructureEditorNodeOrRoot(this.node) && !isStructureNodeRoot(this.node);
        this.showTags = [StructureNodeType.View, StructureNodeType.Empty, StructureNodeType.Custom].includes(this.node.type);
        this.showDefinitionIdentifier = this.node.type === StructureNodeType.Custom;
        this.showURL = [StructureNodeType.IFrame, StructureNodeType.Link].includes(this.node.type);
        this.showTemplate = this.node.type === StructureNodeType.Dashboard;

        const appUrl = this.getNodeUrlParts(this.node)?.join('/');

        if (appUrl) {
            this.appUrl = { term: 'App URL', data: appUrl };
        }

        this.ready = true;
    }

    get control() {
        return this._control;
    }

    protected get node(): StructureEditorNodeOrRoot | StructureNodeVariation {
        return this.control.getRawValue() as StructureEditorNodeOrRoot | StructureNodeVariation;
    }

    protected get isHidden(): boolean {
        return isStructureEditorNodeOrRoot(this.node) && !!this.node.isHidden;
    }

    protected get isInvalid(): boolean {
        const invalidName = this.control.get(StructureNodeControlKeys.Name)?.invalid ?? false;
        const invalidUrl = this.showURL && (this.control.get(StructureNodeControlKeys.Url)?.invalid ?? false);
        const invalidIdentifier = this.showDefinitionIdentifier && (this.control.get(StructureNodeControlKeys.DefinitionIdentifier)?.invalid ?? false);

        return invalidName || invalidUrl || invalidIdentifier;
    }

    protected copy() {
        if (!isStructureEditorNodeOrRoot(this.node)) {
            return;
        }

        this.service.copyAndInsert(this.node, this.control);
    }

    protected copyAppURL() {
        if (this.appUrl?.data && isString(this.appUrl.data)) {
            void this.clipboard.setText(this.appUrl.data);
        }
    }

    protected toggleHidden(event: Event) {
        event.stopPropagation();
        this.control.patchValue({ isHidden: !this.isHidden });
    }

    protected searchTags() {
        // TODO - include other tags added in structure
        this.tagOptions = this.context.project?.tags ?? [];
    }

    private getNodeUrlParts(node: StructureEditorNodeOrRoot | StructureNodeVariation): string[] | undefined {

        if (!isStructureEditorNodeOrRoot(node)) {
            return;
        }

        if (!node.nodeId || isStructureNodeRoot(node) || [StructureNodeType.Empty, StructureNodeType.Link].includes(node.type)) {
            return;
        }

        const prefix = ['/n', node.nodeId];

        switch (node.type) {
            case StructureNodeType.Page:
            case StructureNodeType.View:
            case StructureNodeType.Collection:
            case StructureNodeType.Form:
            case StructureNodeType.FormBucket:
                return node.definitionIdentifier ? [...prefix, node.definitionIdentifier] : undefined;
            case StructureNodeType.CollectionItem:
                return node.definitionIdentifier && node.id != null ? [...prefix, node.definitionIdentifier, `${node.id}`] : undefined;
            case StructureNodeType.Dashboard:
                return [...prefix, 'dashboard'];
            case StructureNodeType.Custom:
                return node.definitionIdentifier ? [...prefix, 'custom', node.definitionIdentifier] : undefined;
            case StructureNodeType.IFrame:
                return [...prefix, 'iframe'];
            case StructureNodeType.PdfViewer:
                return node.id ? [...prefix, 'pdf-viewer', `${node.id}`] : undefined;
            default:
                return [...prefix];
        }
    }

}
